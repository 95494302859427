import React from 'react';
import "../View/ContactList/Contactlist.css";
import whatsapp from "../Images/whatsapp.png";
import call from "../Images/Call.png";
import share from "../Images/Share.png";
import delbtn from "../Images/Delete.png";

const ContactlistModal = () => {
  return (
    <>
    <section className='ContactlistMod'>
        <div className="conListModInn">
            <div className="conOptnDiv">
                <figure className="ConOptnFig">
                    <img src={call} alt="img" />
                </figure>
            </div>

            <div className="conOptnDiv">
                <figure className="ConOptnFig">
                    <img src={whatsapp} alt="img" />
                </figure>
            </div>

            <div className="conOptnDiv">
                <figure className="ConOptnFig">
                    <img src={share} alt="img" />
                </figure>
            </div>

            <div className="conOptnDiv">
                <figure className="ConOptnFig delfig">
                    <img src={delbtn} alt="img" />
                </figure>
            </div>
        </div>
    </section>
    </>
  )
}

export default ContactlistModal