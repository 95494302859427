import React from 'react'
import { Link } from 'react-router-dom'

const ReferAndEarnTermsConditions = () => {
    return (
        <>
            <section className='ReferAndEarnTermsConditions_sec'>
                <div className='cust_container'>
                    <h4 className='listhead'>Refer and Earn Terms & Conditions</h4>
                    <ol className='list'>
                        <li>This referral is for GCC and Indian members only.</li>
                        <li>The referral reward will be credited to your Neoo Search Wallet upon completion of each referee's business pack registrations and purchases. After 7 days, you can redeem the cash. </li>
                        <li>As the price of products varies in each country, the amount of referral rewards will vary.</li>
                        <li>We reserve the right to end or modify the referral program at our discretion without prior notice.</li>
                    </ol>

                    <Link to="#" className='readmore_btn'>Read More</Link>
                </div>
            </section>
        </>
    )
}

export default ReferAndEarnTermsConditions