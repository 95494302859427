import React, { useState } from "react";
import icon from "../Images/wallet.png";
import add from "../Images/addwallet.png";
import HttpClient from "../utils/HttpClient";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { congratsOfferCodeModalView } from "../Redux/Slice/Offer";

function AddvoucherWalletModal(props) {
  const { closemodal1, OfferSingleIDforPurchase } = props;
  const dispatch = useDispatch();
  console.log("xf53153", OfferSingleIDforPurchase)
  const handleClick = async () => {

    const data = {
      offerId: OfferSingleIDforPurchase?._id,
    };
    const res = await HttpClient.requestData("offer-purchase", "POST", data);
    if (res && res?.status) {
      console.log(res?.data, "wewewewewewe");

      toast.success(res?.message);
      props.onclick();
      dispatch(congratsOfferCodeModalView(res?.data))
    } else {
      toast.error(res?.message);
    }
  };

  // console.log(OfferSingleIDforPurchase, "OfferSingleIDforPurchase");
  return (
    <>
      <section className="addvoucherWallet_Modal">
        <div className="addvoucherWallet_body">
          <div className="close_btn">
            <button className="btn" onClick={() => closemodal1(false)}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>

          <div className="icon">
            {/* <img src={OfferSingleIDforPurchase?.offerCoverImage} className="img-fluid" alt="icon" /> */}
            <img src={icon} className="img-fluid" alt="icon" />
          </div>

          <p className="text">Add voucher to your wallet.</p>

          <div className="free_box">
            {/* <h6>Free</h6> */}
            <p style={{ fontWeight: "bold" }}>{OfferSingleIDforPurchase?.offerType}</p>
          </div>

          {/* <ul className="list">
            <li>
              Redeemed voucher would be valid for 48 hours since redemption.
            </li>
            <li>
              Reward voucher can be redeemed using our partners store only
            </li>
            <li>
              Redeemed voucher would be valid for 48 hours since redemption.
            </li>
            <li>
              Reward voucher can be redeemed using our partners store only
            </li>
            <li>
              Reward voucher can be redeemed using our partners store only
            </li>
          </ul> */}
          <div className="list mt-3 mb-4">
            <p>{OfferSingleIDforPurchase?.description}</p>
          </div>
          <div className="bottom_area">
            <button className="cancel_btn" onClick={() => closemodal1(false)}>Cancel</button>
            <button className="addtowallet_btn" onClick={() => handleClick()}>
              <img src={add} className="img-fluid" alt=".." />
              Add to wallet
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddvoucherWalletModal;
