import React from 'react'
import "../../View/OthersPages/OthersPages.css"

function LetsgetinTouch() {
    return (
        <>
            <section className='letsgetinTouch_sec'>
                <div className='cust_container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <h4 className='heading'>Lets Get in <span>Touch!</span></h4>
                            <p className='text'>Have a question or need assistance? Reach out to us via email, </p>
                            <p className='text'>phone, or the contact form below. We're eager to assist you.</p>

                            <h6 className='colortext'>Nice hearing from you!</h6>

                            <div>
                                <h3 className='subheading'>Head Office:</h3>
                                <ul>
                                    <li className="text"><i className="fa-solid fa-phone"></i> <span>+971 562027229</span></li>
                                    <li className="text"><i className="fa-solid fa-envelope"></i><span>info@neoosearch.com</span></li>
                                    <li className="text"><i className="fa-solid fa-location-dot"></i><span>Business Center,Sharjah Publishing City Free ZoneSharjah, United Arab Emirates</span></li>
                                </ul>
                            </div>

                            <div>
                                <h3 className='subheading'>Branch Office:</h3>
                                <ul>
                                    <li className="text"><i className="fa-solid fa-phone"></i> <span>+971 562027229</span></li>
                                    <li className="text"><i className="fa-solid fa-envelope"></i><span>info@neoosearch.com</span></li>
                                    <li className="text"><i className="fa-solid fa-location-dot"></i><span>Business Center,Sharjah Publishing City Free ZoneSharjah, United Arab Emirates</span></li>
                                </ul>
                            </div>

                        </div>
                        <div className='col-lg-6 col-md-6 col-12 mt-4'>
                            <div className='letsgetinTouch_form'>
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="">Full Name:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder=""
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="">Company name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id=""
                                            placeholder=""
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="">Contact number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id=""
                                            placeholder=""
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="">Email:</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id=""
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Message:</label>
                                        <textarea
                                            className="form-control"
                                            id=""
                                            rows={4}
                                            defaultValue={""}
                                        />
                                    </div>

                                    <div className='Submit_btn'>
                                        <button type="submit" className="btn">Submit</button>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section >

        </>
    )
}

export default LetsgetinTouch