import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpClient from "../../utils/HttpClient";

// Our partner/All offer
export const OurPartNerAllOffer = createAsyncThunk('Data/OurPartnerAllOffer', async () => {

    const response = await HttpClient.requestData("our-partner", "GET", {})
    console.log(response, "OurPartNerAllOffer")
    return response?.data;
})
// Single our partner/all offer
export const SingleOurPartNerAllOffer = createAsyncThunk('Data/SingleOurPartNerAllOffer', async (id) => {

    const response = await HttpClient.requestData("single-offer/" + id, "GET", {})
    console.log(response, "SingleOurPartNerAllOffer")
    return response?.data;
})
// partner's Own offer
export const PartnersOwnOffer = createAsyncThunk('Data/PartnersOwnOffer', async () => {

    const response = await HttpClient.requestData("my-offers", "GET", {})
    console.log(response, "my_offers")
    return response?.data;
})
// Own Wallet details
export const OwnWalletDetails = createAsyncThunk('Data/OwnWalletDetails', async () => {

    const response = await HttpClient.requestData("my-purchase-histories", "GET", {})
    console.log(response, "my_offers")
    return response?.data;
})
// Own Wallet details
export const RewardHistoryDetails = createAsyncThunk('Data/RewardHistoryDetails', async () => {

    const response = await HttpClient.requestData("my-rewards-histories", "GET", {})
    console.log(response, "my_offers")
    return response?.data;
})
// Offer categories
export const OfferCategories = createAsyncThunk('Data/OfferCategories', async () => {

    const response = await HttpClient.requestData("get-offer-categories", "GET", {})
    console.log(response, "my_offers")
    return response?.data;
})
// Offer categories Wise details
export const OfferCategoriesWiseDetails = createAsyncThunk('Data/OfferCategoriesWiseDetails', async (id) => {

    const response = await HttpClient.requestData("cat-wise-offer/" + id, "GET", {})
    console.log(response, "my_offers")
    return response?.data;
})

// Offer voucher issued of partner but not redeedmed from partner store
export const VoucherIssuedList = createAsyncThunk('Data/VoucherIssuedList', async () => {
    const response = await HttpClient.requestData('voucher-issued', "GET", {});
    console.log(response, "voucher-issued");
    return response?.data;
})
// Offer voucher issued redeemed by buyer
export const ReddemVoucherList = createAsyncThunk('Data/ReddemVoucherListdata', async () => {
    const response = await HttpClient.requestData('voucher-redeemed', "GET", {});
    console.log(response, "voucher-redeemed");
    return response?.data;
})
// Offer View details data
export const OfferViewDetails = createAsyncThunk('Data/OfferViewDetails', async () => {
    const response = await HttpClient.requestData('view-offers', "GET", {});
    console.log(response, "view-offers");
    return response?.data;
})
// ..Redeemed stats current week

export const RedeemStatsWeekly = createAsyncThunk('Data/RedeemStatsWeekly', async () => {
    let response = await HttpClient.requestData('stat-weekly', 'GET', {});
    console.log(response, "stat-weekly");
    return response
})
// ..Redeemed stats monthly week

export const RedeemStatsMonthly = createAsyncThunk('Data/RedeemStatsMonthly', async () => {
    let response = await HttpClient.requestData('stat-monthly', 'GET', {});
    console.log(response, "stat-weekly");
    return response
})
// ..Redeemed stats monthly week

export const progressbar = createAsyncThunk('Data/progressbar', async () => {
    let response = await HttpClient.requestData('state-wise-redeem', 'GET', {});
    console.log(response, "stat-weekly");
    return response?.data
})


const Offer = createSlice({
    name: "OfferData",
    initialState: {
        status: '',
        error: '',
        congratsModalStatus: false,
        offerCodeModal: [],
        OurPartNerData: [],
        SingleOurPartNerData: [],
        PartnersOwnOfferData: [],
        WalletData: [],
        RewardHistoryDetailsData: [],
        OfferCategoriesData: [],
        OfferCategoryWiseData: [],
        VoucherIssuesDataButNotRedeemed: [],
        VoucherIsRedeemed: [],
        OfferViewData: [],
        offerStatsTABtype: "",
        RedeemStatsWeeklyData: [],
        RedeemStatsMonthlyData: [],
        progressbarData: []
    },
    reducers: {
        congratsModalView: (state, action) => {
            console.log(action)
            state.congratsModalStatus = action.payload

        },
        congratsOfferCodeModalView: (state, action) => {
            console.log(action)
            state.offerCodeModal = action.payload

        },
        offerStatsTAB: (state, action) => {
            state.offerStatsTABtype = action?.payload
        }
    },
    extraReducers: (builder) => {
        builder

            // Our partner/All offer 
            .addCase(OurPartNerAllOffer.pending, (state, action) => {
                state.status = 'loading';
                state.error = "false"
            })
            .addCase(OurPartNerAllOffer.fulfilled, (state, action) => {
                state.status = 'Succeded';
                state.error = "false"
                state.OurPartNerData = action.payload
            })
            .addCase(OurPartNerAllOffer.rejected, (state, action) => {
                state.status = 'failed';
                state.error = "false"
            })
            // Our partner/Single offer 
            .addCase(SingleOurPartNerAllOffer.pending, (state, action) => {
                state.status = 'loading';
                state.error = "false"
            })
            .addCase(SingleOurPartNerAllOffer.fulfilled, (state, action) => {
                state.status = 'Succeded';
                state.error = "false"
                state.SingleOurPartNerData = action.payload
            })
            .addCase(SingleOurPartNerAllOffer.rejected, (state, action) => {
                state.status = 'failed';
                state.error = "false"
            })
            // Our partner own offer 
            .addCase(PartnersOwnOffer.pending, (state, action) => {
                state.status = 'loading';
                state.error = "false"
            })
            .addCase(PartnersOwnOffer.fulfilled, (state, action) => {
                state.status = 'Succeded';
                state.error = "false"
                state.PartnersOwnOfferData = action.payload
            })
            .addCase(PartnersOwnOffer.rejected, (state, action) => {
                state.status = 'failed';
                state.error = "false"
            })
            // Own wallet data 
            .addCase(OwnWalletDetails.pending, (state, action) => {
                state.status = 'loading';
                state.error = "false"
            })
            .addCase(OwnWalletDetails.fulfilled, (state, action) => {
                state.status = 'Succeded';
                state.error = "false"
                state.WalletData = action.payload
            })
            .addCase(OwnWalletDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = "false"
            })
            // Own reward history data 
            .addCase(RewardHistoryDetails.pending, (state, action) => {
                state.status = 'loading';
                state.error = "false"
            })
            .addCase(RewardHistoryDetails.fulfilled, (state, action) => {
                state.status = 'Succeded';
                state.error = "false"
                state.RewardHistoryDetailsData = action.payload
            })
            .addCase(RewardHistoryDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = "false"
            })
            // Offers Categories 
            .addCase(OfferCategories.pending, (state, action) => {
                state.status = 'loading';
                state.error = "false"
            })
            .addCase(OfferCategories.fulfilled, (state, action) => {
                state.status = 'Succeded';
                state.error = "false"
                state.OfferCategoriesData = action.payload
            })
            .addCase(OfferCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = "false"
            })
            // Offers Categories wise data
            .addCase(OfferCategoriesWiseDetails.pending, (state, action) => {
                state.status = 'loading';
                state.error = "false"
            })
            .addCase(OfferCategoriesWiseDetails.fulfilled, (state, action) => {
                state.status = 'Succeded';
                state.error = "false"
                state.OfferCategoryWiseData = action.payload
            })
            .addCase(OfferCategoriesWiseDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = "false"
            })
            // Voucher issued data but not redeemed  yet
            .addCase(VoucherIssuedList.pending, (state, action) => {
                state.status = 'loading';
                state.error = "false"
            })
            .addCase(VoucherIssuedList.fulfilled, (state, action) => {
                state.status = 'Succeded';
                state.error = "false"
                state.VoucherIssuesDataButNotRedeemed = action.payload
            })
            .addCase(VoucherIssuedList.rejected, (state, action) => {
                state.status = 'failed';
                state.error = "false"
            })
            // Voucher redeemed data
            .addCase(ReddemVoucherList.pending, (state, action) => {
                state.status = 'loading';
                state.error = "false"
            })
            .addCase(ReddemVoucherList.fulfilled, (state, action) => {
                state.status = 'Succeded';
                state.error = "false"
                state.VoucherIsRedeemed = action.payload
            })
            .addCase(ReddemVoucherList.rejected, (state, action) => {
                state.status = 'failed';
                state.error = "false"
            })
            // Voucher View only  data
            .addCase(OfferViewDetails.pending, (state, action) => {
                state.status = 'loading';
                state.error = "false"
            })
            .addCase(OfferViewDetails.fulfilled, (state, action) => {
                state.status = 'Succeded';
                state.error = "false"
                state.OfferViewData = action.payload
            })
            .addCase(OfferViewDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = "false"
            })
            // Redeem stats wekkly data
            .addCase(RedeemStatsWeekly.pending, (state, action) => {
                state.status = 'loading';
                state.error = "false"
            })
            .addCase(RedeemStatsWeekly.fulfilled, (state, action) => {
                state.status = 'Succeded';
                state.error = "false"
                state.RedeemStatsWeeklyData = action.payload
            })
            .addCase(RedeemStatsWeekly.rejected, (state, action) => {
                state.status = 'failed';
                state.error = "false"
            })
            // Redeem stats monthhly data
            .addCase(RedeemStatsMonthly.pending, (state, action) => {
                state.status = 'loading';
                state.error = "false"
            })
            .addCase(RedeemStatsMonthly.fulfilled, (state, action) => {
                state.status = 'Succeded';
                state.error = "false"
                state.RedeemStatsMonthlyData = action.payload
            })
            .addCase(RedeemStatsMonthly.rejected, (state, action) => {
                state.status = 'failed';
                state.error = "false"
            })
            // Redeem stats progressbar
            .addCase(progressbar.pending, (state, action) => {
                state.status = 'loading';
                state.error = "false"
            })
            .addCase(progressbar.fulfilled, (state, action) => {
                state.status = 'Succeded';
                state.error = "false"
                state.progressbarData = action.payload
            })
            .addCase(progressbar.rejected, (state, action) => {
                state.status = 'failed';
                state.error = "false"
            })

    }

})

export const { congratsModalView, congratsOfferCodeModalView, offerStatsTAB } = Offer.actions
export default Offer.reducer