import React, { useEffect } from "react";
import pro1 from "../../Images/profle.png";
import { useDispatch, useSelector } from "react-redux";
import {
  OfferViewDetails,
  ReddemVoucherList,
  VoucherIssuedList,
  
} from "../../Redux/Slice/Offer";
import moment from "moment/moment";

function OfferViewData() {
  const candate = [
    {
      id: 1,
      srl: "1",
      src: pro1,
      name: "Jashim noor",
      post: "Marketing manager",
      cuntry: "Dubai",
      address: "United arab emirates",
      datetext: "Date",
      date: "12/3/2024",
      Viewtext: "View",
      Profile: "Profile",
    },
    {
      id: 2,
      srl: "2",
      src: pro1,
      name: "Jashim noor",
      post: "Marketing manager",
      cuntry: "Ajman",
      address: "United arab emirates",
      datetext: "Date",
      date: "12/3/2024",
      Viewtext: "View",
      Profile: "Profile",
    },
    {
      id: 3,
      srl: "3",
      src: pro1,
      name: "Jashim noor",
      post: "Marketing manager",
      cuntry: "Sharjah",
      address: "United arab emirates",
      datetext: "Date",
      date: "12/3/2024",
      Viewtext: "View",
      Profile: "Profile",
    },
    {
      id: 4,
      srl: "4",
      src: pro1,
      name: "Jashim noor",
      post: "Marketing manager",
      cuntry: "Ajman",
      address: "United arab emirates",
      datetext: "Date",
      date: "12/3/2024",
      Viewtext: "View",
      Profile: "Profile",
    },
  ];
  const dispatch = useDispatch();
  const { VoucherIssuesDataButNotRedeemed, VoucherIsRedeemed, OfferViewData } =
    useSelector((state) => state.offer);

  console.log(OfferViewData, "OfferViewData");


  const handNavigate = (link) => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    dispatch(VoucherIssuedList());
    dispatch(ReddemVoucherList());
    dispatch(OfferViewDetails());
  }, []);

 
  
  return (
    <>
      <section className="offerVoucherissued_Redeemed">
        <div className="heading_area">
          <h4 className="head">Offer Views</h4>
          <select className="custom-select" id="">
            <option selected="">All (100)</option>
            <option value={1}>One</option>
            <option value={2}>Two</option>
            <option value={3}>Three</option>
          </select>

          <button className="eyebtn_box">
            <i className="fa-regular fa-eye"></i>
            {OfferViewData?.length}
          </button>
        </div>

        <div className="candidate table-responsive">
          <table class="table">
            {/* <tbody>
              {candate.map((item, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{item.srl}</th>
                    <td>
                      <div className="profile_wrap">
                        <div className="profile_pic">
                          <img src={item.src} className="img-fluid" alt=".." />
                        </div>
                        <div className="profile_details">
                          <h4 className="name">{item.name}</h4>
                          <h6 className="post">{item.post}</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="address_wrap">
                        <h4 className="cuntry">{item.cuntry}</h4>
                        <h6 className="address">{item.address}</h6>
                      </div>
                    </td>
                    <td>
                      <div className="date_wrap">
                        <h4 className="datetext">{item.datetext}</h4>
                        <h6 className="date">{item.date}</h6>
                      </div>
                    </td>
                    <td>
                      <div className="ViewProfile_wrap">
                        <div className="mr-3">
                          <h4 className="Viewtext">{item.Viewtext}</h4>
                          <h6 className="Profile">{item.Profile}</h6>
                        </div>
                        <button className="morebtn">
                          <i className="fa-solid fa-angle-right"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody> */}
            <tbody>
              {OfferViewData?.map((item, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>
                      <div className="profile_wrap">
                        <div className="profile_pic">
                          <img
                            src={item?.userData?.profilePhoto}
                            className="img-fluid"
                            alt=".."
                          />
                        </div>
                        <div className="profile_details">
                          <h4 className="name">{item?.userData?.personName}</h4>
                          <h6 className="post">
                            {item?.userData?.designation}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="address_wrap">
                        <h4 className="cuntry">{item?.userData?.country}</h4>
                        <h6 className="address">{item?.userData?.emirate}</h6>
                      </div>
                    </td>
                    <td>
                      <div className="date_wrap">
                        <h4 className="datetext">Date</h4>
                        <h6 className="date">
                          {moment(item?.voucherRedeemDate).format("DD-MM-YYYY")}
                        </h6>
                      </div>
                    </td>
                    <td>
                      <div className="ViewProfile_wrap">
                        <div className="mr-3">
                          <h4 className="Viewtext">View</h4>
                          <h6 className="Profile">Profile</h6>
                        </div>
                        <button
                          className="morebtn"
                          onClick={() => handNavigate(item?.userData?.userLink)}
                        >
                          <i className="fa-solid fa-angle-right"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}

export default OfferViewData;
