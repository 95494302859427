import React from 'react'
import { Link } from 'react-router-dom'
import processicon from "../../Images/Refer and Earn/Under process.png"
import processicon2 from "../../Images/Refer and Earn/Success.png"

const TransactionhistoryHome = () => {
    const historylist =[
       {id: 1, date: "10.05.2024", amount: "$100.30", status: "Under process", icon: processicon, },
       {id: 2, date: "10.04.2024", amount: "$50.30", status: "Success", icon: processicon2, },
       {id: 3, date: "10.03.2024", amount: "$100.30", status: "Success", icon: processicon2, },
       {id: 4, date: "10.02.2024", amount: "$50.30", status: "Success", icon: processicon2, },
    ];
    return (
        <>
            <section className='Transactionhistory_sec'>
                <div className='Bankaccount_wrap'>
                    <h4 className='head'>Bank account</h4>
                    <div className='box'>
                        <p className='text'>Hamsas creative</p>
                        <p className='text'>*******57869</p>
                        <p className='text'>HSBC</p>
                        <p className='text'>United Arab Emirates</p>
                    </div>
                    <div className='botton_wrap'>
                        <button className='btn'>Remove </button>
                        <Link to="/bank-account-update" className='btn'>Add new account</Link>
                    </div>
                </div>

                <div className='Transactionhistory'>
                    <h4 className='head'>Transaction history</h4>

                    <ul className='history_list'>
                        {historylist.map((items, index) => (
                            <li key={index}>
                                <p className='date'>{items.date}</p>
                                <p className='amount'>{items.amount}</p>
                                <p className='status'>
                                    <span>{items.status}</span>
                                    <img src={items.icon} className='img-fluid' alt='icon' />
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
        </>
    )
}

export default TransactionhistoryHome