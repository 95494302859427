import React from 'react'

const Editaboutbmlmodal = ({ closemodal }) => {
    return (
        <div className='edittamodalboixshadow'>
            <div className='edittamodalboixsbg'>
                <div className='crossdivicon' onClick={() => closemodal()}>
                    <i className="fa-solid fa-xmark"></i>
                </div>
                <div className='edittappduudivnhhb'>
                    <h4 className='upldbannertrtxtpp'>Edit About</h4>
                </div>
                <div className='abattstdovvstttschgg'>
                    <textarea rows={4} cols={4} className='abattstdovvstttschggttstxt' />
                    <p className='ttsrrhhshwoolumiuitstxtt'>100-250 words</p>

                    <div className='svavtatvvbtnnmpaggdijbtn'>
                        <button className='svavtatvvbtnnm'>
                            Save
                        </button>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Editaboutbmlmodal
