import React, { useState } from "react";
import Frame from "../../Images/Frame.png";
import framelike from "../../Images/framelike.png";
import profileimg from "../../Images/profileimg.png";
import whatsapp from "../../Images/whatsapp.png";
import "./Memberownprofile.css";
import { Link, useLocation } from "react-router-dom";
import Rectangle from "../../Images/Rectangle.png";
import prdct from "../../Images/prdct.png";
import pdf from "../../Images/pdf.png";
import coverpic from "../../Images/cover img.jpg";
import ProductModal from "../../Modal/ProductModal";
import QRshareModal from "../../Modal/QRshareModal";
import ProfileShare from "../../Modal/ProfileShare";
import HttpClient from "../../utils/HttpClient";
import { useEffect } from "react";
import CustomLoader from "../../CustomComponents/loader/CustomLoader";
import ReactPlayer from "react-player";
import Savecontactpopup from "../../Component/Savecontactpopup";
import { useDispatch, useSelector } from "react-redux";
import { getOwnProfile } from "../../Redux/Slice/PostSlice";
import verified from "../../Images/Verify responsive.png";
import StarRatings from "react-star-ratings";

const MemberOtherProfile = () => {
  const [UserMemberData, setUserMemberData] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [contactdetail, setContactdetail] = useState(true);
  const [aboutdetail, setAboutdetail] = useState(true);
  const [servicedetail, setServicedetail] = useState(true);
  const [productdetail, setProductdetail] = useState(true);
  const [servicepdfdetail, setServicepdfdetail] = useState(true);
  const [modala, setModala] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  const [sharemodal, setSharemodal] = useState(false);
  const [ProductItem, setProductItem] = useState("");
  const [savedContacts, setsavedContacts] = useState([]);

  const location = useLocation();

  console.log(location?.state, "locationstate");
  console.log(savedContacts, "savedContacts");

  const handledetailClick = () => {
    setContactdetail(!contactdetail);
  };
  const handleaboutClick = () => {
    setAboutdetail(!aboutdetail);
  };
  const handleserviceClick = () => {
    setServicedetail(!servicedetail);
  };
  const handleproductClick = () => {
    setProductdetail(!productdetail);
  };
  const handleservicepdfClick = () => {
    setServicepdfdetail(!servicepdfdetail);
  };
  const handlemodalClick = (item) => {
    setModala(!modala);
    setProductItem(item);
  };
  const modhandleclick = () => {
    setShowmodal(!showmodal);
  };
  const shareClick = () => {
    setSharemodal(!sharemodal);
  };

  const getUserProfile = async () => {
    setisLoading(true);
    const res = await HttpClient.requestData("get-profile", "GET", {});
    console.log(res, "user profile");
    if (res && res?.status) {
      setisLoading(false);
      setUserMemberData(res?.data);
    } else {
      setisLoading(false);
    }
  };
  //Saved contactlist
  const savedContactList = async () => {
    const res = await HttpClient.requestData("member-contacts", "GET", {});
    console.log(res, "resData");
    if (res && res?.status) {
      setsavedContacts(res?.data);
    }
  };
  useEffect(() => {
    savedContactList();
    getUserProfile();
  }, []);

  useEffect(() => {
    const flag = savedContacts?.filter(
      (item, i) => item?.nonMemberData?._id === location?.state?._id
    );
    console.log(flag, "flaghjhj");
  }, [savedContacts]);

  return (
    <>
      <div className="memberownprfl">
        <CustomLoader loading={isLoading} />

        <div className="cust_container">
          <Savecontactpopup id={location?.state?._id} data={location} />

          <div className="bg-wht">
            <div className="bggradient">
              <img src={location?.state?.banner} alt="img" />
            </div>
            <div className="persondetailsdiv">
              <div className="img_profileflx">
                <div className="imgbrdr">
                  <img src={location?.state?.profilePhoto} alt="..." />
                </div>
                <div className="dtlsprflnmmph">
                  <div className="left">
                    <p className="profiledtlstxt">
                      {/* {location?.state?.gender} */}
                      {location?.state?.personName}
                    </p>
                    <p className="prodtlmarkettxt">
                      {location?.state?.designation}
                    </p>
                    <div className="likeCount_all">
                      <p className="text">
                        <strong>{location?.state?.totalLike}</strong>
                        <span>Likes</span>
                      </p>
                      <p className="text">
                        <strong>{location?.state?.totalDirectory}</strong>
                        <span>Connections</span>
                      </p>
                    </div>
                  </div>
                  <div className="Verified">
                    <img src={verified} className="img-fluid" alt="icon" />
                  </div>
                </div>
              </div>
              <div className="likespinerdivflx mx-3">
                {/* <Link className="shredivflx" onClick={shareClick}>
                  <span className="">Share</span>
                  <i className="fa-solid fa-share-from-square"></i>
                </Link> */}
                {/* <Link className="likespiner">
                  <img src={framelike} alt="..." />
                  <span className="">100 Liked</span>
                </Link> */}

                {/* <Link to="/Owncontactlist" className="likespiner">
                  <img src={Frame} alt="..." />
                  <span className="">{savedContacts?.length}</span>
                </Link> */}

                {/* <Link to={"/"} className="whatsppimg">
                  <img src={whatsapp} alt="..." />
                </Link> */}
              </div>
            </div>
            <div className="infolabbrdr">
              <div className="infoflx justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="rctdivimg" onClick={modhandleclick}>
                    <img src={location?.state?.qrCode} alt="QR" />
                  </div>
                  <div className="groupdiv">
                    <div>
                      <h4 className="ocentxt">
                        {location?.state?.companyName}
                      </h4>
                      <p className="pcndgtxt">
                        {location?.state?.TypeOfCompany}
                      </p>
                      <div className=" d-flex">
                        <p className="dbytxtp mr-1">
                          {location?.state?.emirate} ,
                        </p>
                        <p className="dbytxtp">
                          {location?.state?.countryName}
                        </p>
                      </div>
                    </div>
                    <div className="rating_box">
                      <StarRatings
                        rating={location?.state?.avgRating}
                        starRatedColor="#F3660B"
                        starEmptyColor="grey"
                        numberOfStars={5}
                        name="UserProfileRating" // Set to a generic name for product ratings
                        starDimension="20px"
                        starSpacing="5px"
                        className="fa-regular fa-star"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="d-flex" style={{ gap: '12px' }}>
                                    <Link to='/Invitation' className='invitebtn'>Invite Member</Link>
                                    <Link to='/Memberinvitation' className='invitebtn'>Invite Non-member</Link>
                                </div> */}
              </div>
            </div>

            <div className="socialdivflx">
              <a
                href={location?.state?.facebookLink}
                target="_blank"
                className="bgsocial"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a
                href={location?.state?.instaLink}
                target="_blank"
                className="bgsocial"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href={location?.state?.linkedInLink}
                target="_blank"
                className="bgsocial"
              >
                <i className="fa-brands fa-linkedin-in"></i>
              </a>

              <a
                href={location?.state?.youtubeLink}
                target="_blank"
                className="bgsocial"
              >
                <i className="fa-brands fa-youtube"></i>
              </a>
              {/* <a
                href={location?.state?.dribbbleLink}
                target="_blank"
                className="bgsocial"
              >
                <i className="fa-solid fa-basketball"></i>
              </a> */}
              <a href="#" target="_blank" className="bgsocial">
                <i class="fa-brands fa-x-twitter"></i>
              </a>
              <a href="#" target="_blank" className="bgsocial">
                <i class="fa-solid fa-globe"></i>
              </a>
            </div>

            <div className="cntntbrdr">
              <div className="flxcntnt">
                <p className="cntncttxt">Contact Details</p>
                <div className="ArrwiconDiv" onClick={handledetailClick}>
                  <span
                    className={
                      contactdetail
                        ? "fa-solid fa-chevron-up"
                        : "fa-solid fa-chevron-down"
                    }
                  ></span>
                </div>
              </div>
              {contactdetail && (
                <div className="">
                  <div className="divcnctflx">
                    <div className="brdrdivdtls">
                      <span className="txtdtlmn">
                        <i className="fa-solid fa-phone"></i>0
                        {location?.state?.telephoneNo}
                      </span>
                    </div>
                    <div className="brdrdivdtls">
                      <span className="txtdtlmn">
                        <i className="fa-solid fa-mobile-screen-button"></i>0
                        {location?.state?.mobileNo}
                      </span>
                    </div>
                    <div className="brdrdivdtls">
                      <span className="txtdtlmn">
                        <i className="fa-brands fa-whatsapp"></i>
                        {location?.state?.whatsappNo}
                      </span>
                    </div>
                  </div>
                  <div className="dibmailflx">
                    <div className="envelopdiv">
                      <span className="txtdtlmn">
                        <i className="fa-solid fa-envelope"></i>
                        {location?.state?.emailId}
                      </span>
                    </div>
                    <div className="envelopdiv">
                      <span className="txtdtlmn">
                        <i className="fa-solid fa-globe"></i>
                        {location?.state?.websiteLink}
                      </span>
                    </div>
                  </div>
                  <div className="divloctin">
                    <span className="txtdtlmn">
                      <i className="fa-solid fa-location-dot"></i>
                      {location?.state?.address}
                    </span>
                  </div>

                  {/* <div className='mapwidth'>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14728.727405628732!2d88.33364660000001!3d22.64700745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1706076991023!5m2!1sen!2sin"
                                        // width={900}
                                        heigbwFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    />

                                </div> */}

                  <div className="getdrctnBtnDiv d-flex justify-content-between ">
                    <p>
                      {location?.state?.emirate} ,{" "}
                      {location?.state?.countryName}
                    </p>
                    <a
                      href={location?.state?.locationLink}
                      className="getdirectionbtn"
                      target="_blank"
                    >
                      Get Direction
                      <i class="fa-solid fa-diamond-turn-right"></i>
                    </a>
                  </div>
                </div>
              )}
            </div>

            <div className="abtbrsdr">
              <div className="flxcntnt">
                <p className="cntncttxt">About Us</p>
                <div className="ArrwiconDiv" onClick={handleaboutClick}>
                  <span
                    className={
                      aboutdetail
                        ? "fa-solid fa-chevron-up"
                        : "fa-solid fa-chevron-down"
                    }
                  ></span>
                </div>
              </div>
              {aboutdetail && (
                <div className="">
                  <p className="txtabtmain">
                    {location?.state?.companyDescription}
                  </p>
                </div>
              )}
            </div>

            <div className="servcbrdr">
              <div className="flxcntnt">
                <p className="cntncttxt">Services</p>
                <div className="ArrwiconDiv" onClick={handleserviceClick}>
                  <span
                    className={
                      servicedetail
                        ? "fa-solid fa-chevron-up"
                        : "fa-solid fa-chevron-down"
                    }
                  ></span>
                </div>
              </div>
              {servicedetail && (
                <div className="srvcboxbrdr">
                  {location?.state?.service?.map((item, i) => {
                    return (
                      <>
                        <div className="brdrmaindesign" key={i}>
                          {" "}
                          {item}
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="prdrtbrdr">
              <div className="flxcntnt">
                <p className="cntncttxt">Products</p>
                <div className="ArrwiconDiv" onClick={handleproductClick}>
                  <span
                    className={
                      productdetail
                        ? "fa-solid fa-chevron-up"
                        : "fa-solid fa-chevron-down"
                    }
                  ></span>
                </div>
              </div>
              {productdetail && (
                <div className="">
                  <div className="prdrtflxdivmain">
                    {location?.state?.productData?.map((item, index) => {
                      return (
                        <div
                          className="brdrprdctdiv"
                          key={index}
                          onClick={() => handlemodalClick(item)}
                        >
                          <div className="prdcgimg">
                            <img src={item?.image} alt="..." />
                          </div>
                          <div className="textparamain">
                            <p className="txtdiv">{item?.name}</p>
                            <p className="txtpara">{item?.description}</p>
                          </div>
                        </div>
                      );
                    })}

                  </div>
                  {/* <div className='vdoighh'>
                                    <iframe
                                        width={560}
                                        height={315}
                                        src="https://www.youtube-nocookie.com/embed/My0jYiN5JDU"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    />
                                </div> */}
                </div>
              )}
            </div>

            <div className="brdrsrvc">
              <div className="flxcntnt">
                <p className="cntncttxt">Profile</p>
                <div className="ArrwiconDiv" onClick={handleservicepdfClick}>
                  <span
                    className={
                      servicepdfdetail
                        ? "fa-solid fa-chevron-up"
                        : "fa-solid fa-chevron-down"
                    }
                  ></span>
                </div>
              </div>
              {servicepdfdetail && (
                <div>
                  <div className="vdoighh">
                    {/* <iframe
                      width={560}
                      height={315}
                      src="https://www.youtube.com/embed/WAyzEOeeBZw"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    /> */}
                    <ReactPlayer
                      // width={}
                      // height={415}
                      // width={100}
                      // height={415}
                      title="YouTube video player"
                      className="ReactPlayerdiv"
                      url={location?.state?.youtubeLink}
                    />
                  </div>
                  <div className="pdrmainflx">
                    {location?.state?.doc?.map((item, i) => {
                      return (
                        <div className="pdfflxDiv">
                          <div className="pdfimg">
                            <img src={pdf} alt="..." />
                            <div className="inpuduv">
                              {/* <input type="file" id="myfile" name="myfile" /> */}
                              <a href={item?.doc}></a>
                            </div>
                          </div>
                          <div className="prflmainflx">
                            <p className="prfltxtprdct">Document{i + 1}</p>
                            <a
                              href={item?.pdf}
                              target="_blank"
                              rel="noopener noreferrer"
                              download=""
                              className=" text-primary mt-2"
                            >
                              Open PDF <i class="fa-solid fa-download"></i>
                            </a>
                            <p className="prfldtlsprdct">
                              Lorem Ipsum has been the industry's standard
                            </p>
                          </div>
                        </div>
                      );
                    })}
                    {/* <div className="pdfflxDiv">
                      <div className="pdfimg">
                        <img src={pdf} alt="..." />
                        <div className="inpuduv">
                          <input type="file" id="myfile" name="myfile" />
                        </div>
                      </div>
                      <div className="prflmainflx">
                        <p className="prfltxtprdct">Profile Name</p>
                        <p className="prfldtlsprdct">
                          Lorem Ipsum has been the industry's standard
                        </p>
                      </div>
                    </div>
                    <div className="pdfflxDiv">
                      <div className="pdfimg">
                        <img src={pdf} alt="..." />
                        <div className="inpuduv">
                          <input type="file" id="myfile" name="myfile" />
                        </div>
                      </div>
                      <div className="prflmainflx">
                        <p className="prfltxtprdct">Profile Name</p>
                        <p className="prfldtlsprdct">
                          Lorem Ipsum has been the industry's standard
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {modala && (
          <ProductModal closemodal={setModala} ProductItem={ProductItem} />
        )}
        {showmodal && (
          <QRshareModal
            closeModal={setShowmodal}
            Qrimage={location?.state?.qrCode}
          />
        )}
        {sharemodal && (
          <ProfileShare
            whatsApp={location?.state?.whatsappNo}
            linkedInLink={location?.state?.linkedInLink}
            facebookLink={location?.state?.facebookLink}
            userLink={location?.state?.userLink}
            instaLink={location?.state?.instaLink}
            emailId={location?.state?.emailId}
            closeModal={setSharemodal}
          />
        )}
      </div>
    </>
  );
};

export default MemberOtherProfile;
