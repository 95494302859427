import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import icn from "../../Images/gift-icon.png";
import img1 from "../../Images/boxcard9.png";
import img2 from "../../Images/boxcard9.png";
import img3 from "../../Images/boxcard9.png";
import img4 from "../../Images/boxcard9.png";
import AddvoucherWalletModal from "../../Modal/AddvoucherWalletModal";
import CongratsModal from "../../Modal/CongratsModal";
import { useDispatch, useSelector } from "react-redux";
import {
  OurPartNerAllOffer,
  OwnWalletDetails,
  congratsModalView,
} from "../../Redux/Slice/Offer";
import { getOwnProfile } from "../../Redux/Slice/PostSlice";
import moment from "moment";

function RedeemyourPoints() {
  const dispatch = useDispatch();
  const [modalD, setModalD] = useState(false);
  const [modalF, setModalF] = useState(false);
  const [OfferSingleIDforPurchase, setOfferSingleIDforPurchase] = useState("");
  const { UserProfileID } = useSelector((state) => state.app);
  const { OurPartNerData, congratsModalStatus, WalletData } = useSelector(
    (state) => state.offer
  );
  const nonPurchaseWalletOffer = WalletData?.filter(
    (item) => !item?.voucherReedem
  );
  console.log(UserProfileID, "User123");
  console.log(OurPartNerData, "OurPartsdNerData");

  const handleClick = (id) => {
    setOfferSingleIDforPurchase(id);
    setModalD(!modalD);
  };
  const onclick2 = () => {
    setModalD(false);

    dispatch(congratsModalView(true));
  };

  const activiti = [
    {
      id: 1,
      name: "Kids Activities",
      bgColor: "#D9940C",
      src: img1,
      free: "Free",
      ticket: "2 Entry ticket",
      Points: "150 Points",
      offervalid: "Offer valid till 13th  Jan 2024",
    },
    {
      id: 2,
      name: "Financial services",
      bgColor: "#0CD99C",
      src: img2,
      free: "Free",
      ticket: "2 Entry ticket",
      Points: "150 Points",
      offervalid: "Offer valid till 13th  Jan 2024",
    },
    {
      id: 3,
      name: "Kids Activities",
      bgColor: "#D90C94",
      src: img3,
      free: "Free",
      ticket: "2 Entry ticket",
      Points: "150 Points",
      offervalid: "Offer valid till 13th  Jan 2024",
    },
    {
      id: 4,
      name: "Financial services",
      bgColor: "#0CB4D9",
      src: img4,
      free: "Free",
      ticket: "2 Entry ticket",
      Points: "150 Points",
      offervalid: "Offer has ended.",
    },
  ];

  useEffect(() => {
    dispatch(OurPartNerAllOffer());
    dispatch(getOwnProfile());
  }, []);
  useEffect(() => {
    dispatch(OwnWalletDetails());
  }, []);

  return (
    <>
      <section className="redeemyourPoints_page">
        <div className="cust_container">
          <div className="top_area">
            <Link to="/reward" className="backbtn">
              <i className="fa-solid fa-arrow-left-long"></i>
            </Link>
            <h4 className="heading">
              {" "}
              <span>
                <i className="fa-solid fa-star"></i>
              </span>
              Rewards
            </h4>

            <Link to="/my-wallet">
              <div className="icon">
                <img src={icn} className="img-fluid" alt="....." />
                {nonPurchaseWalletOffer?.length > 0 && (
                  <span className="notification_count">
                    {nonPurchaseWalletOffer?.length}
                  </span>
                )}
              </div>
            </Link>
          </div>

          <div className="Pointsbalance">
            <p className="text">Your Points balance</p>
            <h4 className="amount">{UserProfileID?.walletPoints?.points}</h4>
          </div>

          <button className="Howitwork_btn">
            How it work
            <i className="fa-solid fa-angles-right"></i>
          </button>
          {/* <div className="row my-5">
            {activiti.map((item, index) => (
              <div className="col-lg-4 col-md-6 col-12" key={index}>
                <div className="activities_card">
                  <div className="top_atra">
                    <p className="card_name">{item.name}</p>
                    <Link to="#" className="ViewProfile_btn">
                      View Profile
                    </Link>
                  </div>
                  <div
                    className="image_area"
                    style={{ backgroundColor: item.bgColor }}
                  >
                    <img src={item.src} className="img-fluid" alt="image" />
                  </div>

                  <div className="entry_ticket">
                    <h6>{item.free}</h6>
                    <p>{item.ticket}</p>
                  </div>

                  <div className="bottom_area">
                    <p className="points">{item.Points}</p>
                    <button className="Getreward_btn" onClick={handleClick}>
                      Get reward{" "}
                      <span>
                        <i className="fa-solid fa-angle-right"></i>
                      </span>
                    </button>
                  </div>

                  <div className="offervalid_text">
                    <div className="fa-solid fa-check"></div>
                    <div className="offdate">{item.offervalid}</div>
                  </div>
                </div>
              </div>
            ))}
          </div> */}

          <div className="row my-5">
            {OurPartNerData?.filter(
              (item) =>
                item?.reddemingPoints <= UserProfileID?.walletPoints?.points
            )?.map((item, index) => (
              <div className="col-lg-4 col-md-6 col-12" key={index}>
                <div className="activities_card">
                  <div className="top_atra">
                    <p className="card_name">{item?.offerCategory}</p>
                    <a
                      href={item?.profileLink}
                      target="_blank"
                      className="ViewProfile_btn"
                    >
                      View Profile
                    </a>
                  </div>
                  <div
                    className="image_area"
                    style={{ backgroundColor: item.bgColor }}
                  >
                    <img
                      src={item?.offerCoverImage}
                      className="img-fluid"
                      alt="image"
                    />
                  </div>

                  <div className="entry_ticket">
                    {/* <h6>{item.free}</h6> */}
                    <p>{item.offerType}</p>
                  </div>

                  <div className="bottom_area">
                    <p className="points">{item?.reddemingPoints} Points</p>
                    {item?.purchasedStatus ? (
                      <button
                        className="Getreward_btn   "
                        style={{
                          backgroundColor: "#808080",
                          cursor: "default",
                        }}
                      >
                        Redeemed{" "}
                      </button>
                    ) : item?.voucherCount >= item?.voucherNo ? (
                      <button
                        className="Getreward_btn"
                        style={{ background: "#708090" }}
                      >
                        Get reward{" "}
                        <span>
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                      </button>
                    ) : !item?.purchasedStatus ? (
                      <button
                        className="Getreward_btn"
                        onClick={() => handleClick(item)}
                      >
                        Get reward{" "}
                        <span>
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                      </button>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="offervalid_text">
                    {item?.voucherCount >= item?.voucherNo &&
                    !item?.purchasedStatus ? (
                      <>
                        <div className="fa-solid fa-xmark"></div>
                        <div className="offdate">Offer has ended</div>
                      </>
                    ) : (
                      <>
                        <div className="fa-solid fa-check"></div>
                        <div className="offdate">
                          Offer valid till{" "}
                          {moment(item?.offerExpiryDate).format("Do MMM YYYY")}
                        </div>{" "}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {modalD && (
        <AddvoucherWalletModal
          OfferSingleIDforPurchase={OfferSingleIDforPurchase}
          closemodal1={setModalD}
          onclick={() => {
            onclick2();
          }}
        />
      )}
      {congratsModalStatus && <CongratsModal closemodal2={setModalF} />}
    </>
  );
}

export default RedeemyourPoints;
