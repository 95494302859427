import React, { useState } from 'react'
import vectorarrow from "../../src/Images/vectorarrow.png"
const Ownpagesearchkeywordmodal = ({closemodal}) => {

    const [arrowmodalclcik, setarrowmodalclcik] = useState(false)
    const handlearrpwclick = () => {
        setarrowmodalclcik(!arrowmodalclcik)
    }
    return (
        <div className='uploabannermodalbgshadow '>
            <div className='owenoagedjeywordbgmain'>
                <div className='crossdivmainmnm' onClick={()=>closemodal()}>
                    <i className="fa-solid fa-xmark"></i>
                </div>


                <div className='keywirdrserchttxtdibgflxmain'>
                    <div className='keywirdrserchttxtdibgflx'>
                        <div className='keywirdrserchdibvnain'>
                            <p className='keywirdrserchdibvnainttxpp'>KeyWord</p>
                        </div>
                        <div className='keywirdrserchdibinpiuytxtdiv'>
                            <input type='text' className='keywprodsecrtinputmain' placeholder='Product or Service name' />
                        </div>
                    </div>

                    <div className='keywirdrserchttxtdibgflx'>
                        <div className='keywirdrserchdibvnain'>
                            <p className='keywirdrserchdibvnainttxpp'>Category</p>
                        </div>
                        <div className='keywirdrserchdibinpiuytxtdiv'>
                            <div className='keywirdrserchdibderselect'>
                                <p className='selrcttcatreffgtxtpp'>
                                    Select category
                                </p>
                                <div className="vectorimgamindiv" onClick={handlearrpwclick}>
                                    <img src={vectorarrow} alt="..." />
                                </div>
                                {arrowmodalclcik && <div className="vectoraarowdivomgulmai">
                                    <ul className="vectoraarowdivomgulmaiul">
                                        <li className="vectoraarowdivomgulmaili">
                                            Select category</li>
                                        <li className="vectoraarowdivomgulmaili">
                                            Select category</li>
                                        <li className="vectoraarowdivomgulmaili">
                                            Select category</li>
                                        <li className="vectoraarowdivomgulmaili">
                                            Select category</li>
                                        <li className="vectoraarowdivomgulmaili">
                                            Select category</li>
                                    </ul>
                                </div>}


                            </div>
                        </div>
                    </div>

                    <div className='keywirdrserchttxtdibgflx'>
                        <div className='keywirdrserchdibvnain'>
                            <p className='keywirdrserchdibvnainttxpp'>Page</p>
                        </div>
                        <div className='keywirdrserchdibinpiuytxtdiv'>
                            <div className=''>
                                <div className='chekowcutbnemttxndivflx'>
                                    <input type="checkbox" name="" defaultValue="" className='chekowcutomedesign' />
                                    <label htmlFor="" className='labbnemttxptt'> All</label>
                                </div>

                                <div className='chekowcutbnemttxndivflx'>
                                    <input type="checkbox" name="" defaultValue="" className='chekowcutomedesign' />
                                    <label htmlFor="" className='labbnemttxptt'>Premium Members</label>
                                </div>

                                <div className='chekowcutbnemttxndivflx'>
                                    <input type="checkbox" name="" defaultValue="" className='chekowcutomedesign' />
                                    <label htmlFor="" className='labbnemttxptt'>Verified list</label>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='keywirdrserchttxtdibgflx'>
                        <div className='keywirdrserchdibvnain'>
                            <p className='keywirdrserchdibvnainttxpp'>Location</p>
                        </div>
                        <div className='keywirdrserchdibinpiuytxtdiv'>
                            <input type='text' className='keywprodsecrtinputmain' placeholder='Product or Service name' />
                        </div>
                    </div>

                    <div className='keywirdrserchttxtdibgflx'>
                        <div className='keywirdrserchdibvnain'>
                            <p className='keywirdrserchdibvnainttxpp'>State</p>
                        </div>
                        <div className='keywirdrserchdibinpiuytxtdiv'>
                            <div className='keywirdrserchdibderselect'>
                                <p className='selrcttcatreffgtxtpp'>
                                    Select category
                                </p>
                                <div className="vectorimgamindiv" onClick={handlearrpwclick}>
                                    <img src={vectorarrow} alt="..." />
                                </div>
                                {arrowmodalclcik && <div className="vectoraarowdivomgulmai">
                                    <ul className="vectoraarowdivomgulmaiul">
                                        <li className="vectoraarowdivomgulmaili">
                                            Select category</li>
                                        <li className="vectoraarowdivomgulmaili">
                                            Select category</li>
                                        <li className="vectoraarowdivomgulmaili">
                                            Select category</li>
                                        <li className="vectoraarowdivomgulmaili">
                                            Select category</li>
                                        <li className="vectoraarowdivomgulmaili">
                                            Select category</li>
                                    </ul>
                                </div>}


                            </div>
                        </div>
                    </div>

                    <div className='keywirdrserchttxtdibgflx'>
                        <div className='keywirdrserchdibvnain'>
                            <p className='keywirdrserchdibvnainttxpp'>Country</p>
                        </div>
                        <div className='keywirdrserchdibinpiuytxtdiv'>
                            <div className='keywirdrserchdibderselect'>
                                <p className='selrcttcatreffgtxtpp'>
                                    Select category
                                </p>
                                <div className="vectorimgamindiv" onClick={handlearrpwclick}>
                                    <img src={vectorarrow} alt="..." />
                                </div>
                                {arrowmodalclcik && <div className="vectoraarowdivomgulmai">
                                    <ul className="vectoraarowdivomgulmaiul">
                                        <li className="vectoraarowdivomgulmaili">
                                            Select category</li>
                                        <li className="vectoraarowdivomgulmaili">
                                            Select category</li>
                                        <li className="vectoraarowdivomgulmaili">
                                            Select category</li>
                                        <li className="vectoraarowdivomgulmaili">
                                            Select category</li>
                                        <li className="vectoraarowdivomgulmaili">
                                            Select category</li>
                                    </ul>
                                </div>}


                            </div>
                        </div>
                    </div>

                    <div className='cancelbscearchbtntndiv'>
                        <button className='cancelbtn'>Cancel</button>
                        <button className='scearchbtn'>Search</button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Ownpagesearchkeywordmodal
