// postsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import HttpClient from '../../utils/HttpClient';

// fetching Data
export const getPosts = createAsyncThunk('Data/getPosts', async () => {
    const data2 = {
        key: "all",
    };
    const response = await HttpClient.requestData("get-userby-key", "POST", data2);
    return response.data;
});
//get Users Profile
export const getOwnProfile = createAsyncThunk('Data/getUserID', async () => {
    const response = await HttpClient.requestData("get-profile", "GET", {});
    console.log("profff", response)
    return response.data;
});

//getsingle user Profile
export const getSingleUserProfile = createAsyncThunk('Data/SingleUser', async (id) => {
    const response = await HttpClient.requestData(`single-user/${id}`, "GET", {});
    console.log(response, "wwww")
    return response.data;

})

//  for hitting Like
export const HitLike = createAsyncThunk('Data/hitLike', async (postData) => {
    const response = await HttpClient.requestData("give-like", "POST", postData);
    return response.data;
});

// updating a data
export const editPost = createAsyncThunk('Data/updatePost', async ({ id, updatedData }) => {
    const response = await axios.put(`https://jsonplaceholder.typicode.com/posts/${id}`, updatedData);
    return response.data;
});

// deleting a post
export const deletePost = createAsyncThunk('Data/deletePost', async (postId) => {
    await axios.delete(`https://jsonplaceholder.typicode.com/posts/${postId}`);
    return postId;
});

// Create the Data slice
const postsSlice = createSlice({
    name: 'Data',
    initialState: {
        UserProfileID: {},
        inputSearch: "",
        SearchBar: false,
        responSiveSearchBar: false,
        ProfileSubmenuDropDownToggle: false,
        ShareModalOpenClose: false,
        ProfileOwnContactCount: 0,
        nonMemberDirectoryCountDataLength: 0,
        UserLocationLink: {},
        SingleUserData: [],
        isTilted: false,
        status: '',
        error: null,
        pwaData: false
    },
    reducers: {
        // UserProfileIDget: (state, action) => {
        //     state.UserProfileID = action.payload;
        // },
        addSearch: (state, action) => {
            console.log(action)
            state.inputSearch = action.payload

        },
        SearchModal: (state, action) => {
            console.log(action)
            state.SearchBar = action.payload

        },
        ShareModalonOff: (state, action) => {
            console.log(action)
            state.ShareModalOpenClose = action.payload

        },
        responsiveSearch: (state, action) => {
            state.responSiveSearchBar = action.payload
        },
        ProfileSubmenuDropDown: (state, action) => {
            state.ProfileSubmenuDropDownToggle = action.payload
        },
        UserLocation: (state, action) => {
            state.UserLocationLink = action.payload
        },
        nonMemberDirectoryCountData: (state, action) => {
            state.nonMemberDirectoryCountDataLength = action.payload
        },
        pwaDataSet: (state, action) => {
            state.pwaData = action.payload
        }

    },
    extraReducers: (builder) => {
        builder
            // fetching Data
            .addCase(getPosts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPosts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.ProfileOwnContactCount = action.payload;
            })
            .addCase(getPosts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // fetching own profile Data
            .addCase(getOwnProfile.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getOwnProfile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.UserProfileID = action.payload;
            })
            .addCase(getOwnProfile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })


            // fetching single user profile Data
            .addCase(getSingleUserProfile.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSingleUserProfile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.SingleUserData = action.payload;
            })
            .addCase(getSingleUserProfile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })


            // Hit likes
            .addCase(HitLike.pending, (state, action) => {
                state.status = 'loading';
                state.isTilted = true
            })
            .addCase(HitLike.fulfilled, (state, action) => {
                // state.Data.push(action.payload);
                state.status = 'succeeded';
                // Update like count in SingleUserData
                state.SingleUserData[0].likeCount = action.payload.likeCount;
                state.isTilted = false;
            })
            .addCase(HitLike.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.isTilted = false;
            })
            // updating a post
            .addCase(editPost.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(editPost.fulfilled, (state, action) => {
                const updatedPost = action.payload;
                const index = state.Data.findIndex((post) => post.id === updatedPost.id);
                if (index !== -1) {
                    state.Data[index] = updatedPost;
                }
            })
            .addCase(editPost.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // deleting a post
            .addCase(deletePost.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(deletePost.fulfilled, (state, action) => {
                const postId = action.payload;
                state.Data = state.Data.filter((post) => post.id !== postId);
            })
            .addCase(deletePost.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    },
});

// export default postsSlice.reducer;

export const { pwaDataSet, addSearch, SearchModal, responsiveSearch, ProfileSubmenuDropDown, UserLocation, UserProfileIDget, nonMemberDirectoryCountData, ShareModalonOff } = postsSlice.actions
export default postsSlice.reducer

// Export the asynchronous thunks for use in components or other parts of your application