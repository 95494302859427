import React from 'react'

const WelcomeToworld = () => {
    return (
        <>
            <section className='WelcomeToworld_sec'>
                <div className='content_wrap'>
                    <h4 className='heading'>Welcome to a world of fascinating and unique member advantages!</h4>
                    <p className='text'>Our Business reward program loyalty program provides members with a
                        variety of exclusive rewards, incentives, and privileges designed to help your business
                        grow. It is the doorway to a world of special incentives and privileges, thoroughly designed
                        to improve your experience as a valued Neoo search member. </p>

                    <p className='text'>Meet or invite new people, and exchange contacts through Neoo search
                        and earn points.  – the more you spend, the greater the reward. Points can also be earned
                        on a range of Neoo search members profile activities, including Likes, rating, profile download,
                        products views... and as your points tally grows, so does your membership status – through Silver,
                        Gold and Platinum tiers.</p>

                    <p className='text'>Each stage unlocks an ever-expanding suite of benefits and privileges meant to move
                        your organization ahead, eventually increasing the value of your membership.</p>
                </div>
            </section>
        </>
    )
}

export default WelcomeToworld