import React, { useContext, useEffect, useState } from 'react'
import { Colorcontext } from '../../Component/Colorcontext/ColorContext';
import searchicon from "../../Images/searchicon.png";
import searchresilutfilter from "../../Images/searchresilutfilter.png";
import logoownblm from "../../Images/logoownblm.png";
import logootherblm from "../../Images/logootherblm.png";
import logiiaihsui from "../../Images/logiiaihsui.png";
import Neoologoasjji from "../../Images/Neoologoasjji.png";
import lofogoeyyy from "../../Images/lofogoeyyy.png";
import paiisiidjuudug from "../../Images/paiisiidjuudug.png";
import askokdjjlobogo from "../../Images/askokdjjlobogo.png";
import imahggsercggdpremiy from "../../Images/imahggsercggdpremiy.png";
import prijjdhjjjjkj from "../../Images/prijjdhjjjjkj.png"
import { Link } from 'react-router-dom';
import facebook_icon from "../../Images/facebook_icon.png";
import insta_icon from "../../Images/insta_icon.png";
import linkedin_icon from "../../Images/linkedin_icon.png"
import Ownpagesearchkeywordmodal from '../../Modal/Ownpagesearchkeywordmodal';
import { useNavigate } from 'react-router-dom';
const Blmemberlist = () => {

    const colordata = useContext(Colorcontext);
    const [ownpagesearchkeay, setownpagesearchkeay] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {

        colordata.setcolor(true);
        return () => {
            colordata.setcolor(false);
        };
    }, []);

    const searchresult = [
        {
            imaage: logoownblm,
            headepara: "Eagle star furniture - Advertising & Marketing",
            headsubpara: "Digital marketing l Advertising l Content production l Digital bu...",
            headsupsubpara: "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,"

        },
        {
            imaage: Neoologoasjji,
            headepara: "Neoo Search FZE - Advertising & Marketing",
            headsubpara: "Digital marketing l Advertising l Content production l Digital bu...",
            headsupsubpara: "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,"

        },
        {
            imaage: logootherblm,
            headepara: "Planet kids gallery - Publishing ",
            headsubpara: "Digital marketing l Advertising l Content production l Digital bu...",
            headsupsubpara: "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,"

        },
        {
            imaage: logiiaihsui,
            headepara: "Eagle star furniture - Advertising & Marketing",
            headsubpara: "Digital marketing l Advertising l Content production l Digital bu...",
            headsupsubpara: "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,"

        },
        {
            imaage: lofogoeyyy,
            headepara: "Eagle star furniture - Advertising & Marketing",
            headsubpara: "Digital marketing l Advertising l Content production l Digital bu...",
            headsupsubpara: "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,"

        },
        {
            imaage: paiisiidjuudug,
            headepara: "Planet kids gallery - Publishing",
            headsubpara: "Digital marketing l Advertising l Content production l Digital bu...",
            headsupsubpara: "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,"

        },
        {
            imaage: askokdjjlobogo,
            headepara: "Ocean infolab - Digital Marketing",
            headsubpara: "Digital marketing l Advertising l Content production l Digital bu...",
            headsupsubpara: "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,"

        },
        {
            imaage: logoownblm,
            headepara: "Eagle star furniture - Advertising & Marketing",
            headsubpara: "Digital marketing l Advertising l Content production l Digital bu...",
            headsupsubpara: "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,"

        },
        {
            imaage: Neoologoasjji,
            headepara: "Neoo Search FZE - Advertising & Marketing",
            headsubpara: "Digital marketing l Advertising l Content production l Digital bu...",
            headsupsubpara: "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,"

        },
        {
            imaage: logootherblm,
            headepara: "Planet kids gallery - Publishing ",
            headsubpara: "Digital marketing l Advertising l Content production l Digital bu...",
            headsupsubpara: "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,"

        },
        {
            imaage: logiiaihsui,
            headepara: "Eagle star furniture - Advertising & Marketing",
            headsubpara: "Digital marketing l Advertising l Content production l Digital bu...",
            headsupsubpara: "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,"

        },


    ]
    const ownpagesearchkeyclcik = () => {
        setownpagesearchkeay(!ownpagesearchkeay)
    }
    const handleownsecrh = () => {

        navigate("/ownsearchresult")

    }
    return (
        <>
            <section className='ownseacrhresultpagemain'>
                <div className='cust_container'>

                    <div className='searchhresultandfilterdivflx'>
                        <div className='searfchresultleftdiv'>
                            <input type='text' className='searfchresultleftdivmain' placeholder='' />
                            <div className='sectgresulticon' >
                                <img src={searchicon} alt='...' />
                            </div>
                        </div>
                        <div className='searchfilterdiv'>
                            <div className='searchfilterdivmain' onClick={ownpagesearchkeyclcik}>
                                <div className='sercghghfilyerimg'>
                                    <img src={searchresilutfilter} alt='...' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='ownseacrgresultpagedivbg'>
                        <div className='ownseacrgresultpagedivflx'>
                            <div className='ownseacrgresultpageleft'>
                                <p className='avoiaresrrstrtxppp'>
                                    About 1,070 results
                                </p>
                                {searchresult.map((item, index) => {

                                    return (

                                        <div className='serchresutllogodetaildivflx' key={index} onClick={handleownsecrh}>
                                            <div className='serachresultlogoidiv'>
                                                <img src={item.imaage} alt="..." />
                                            </div>
                                            <div className='serachresultdetailsdiv'>
                                                <h4 className='serctedheadrparatxt'> {item.headepara}</h4>
                                                <p className='serchrelutparatxtmm'>{item.headsubpara}</p>
                                                <p className='serchrelusubtparatxtmm'>{item.headsupsubpara}</p>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>

                            <div className='ownseacrgresultpageright'>
                                <p className='prPremiumofiletxtp'>
                                    Sponsor Ad
                                </p>
                                {/* <div className='ownseacrgresultpagerightbrdr'>
                                    <div className='ownseacrgresultpagerightbrdrtop'>
                                        <div className='ownseacrgresultpagerreslppimg'>
                                            <img src={imahggsercggdpremiy} alt='...' />

                                        </div>

                                        <div className='logostrdetyskksldovkkdpo'>
                                            <div className='logostretdijjkkkljujuo'>
                                                <img src={Neoologoasjji} alt='...' />
                                            </div>
                                            <div className='allttehdiioskjetyapasedoib'>
                                                <p className='neosertsttfzrrtty'>Neoo Search FZE</p>
                                                <p className='neosertsttfzrrttyadfd'>Digital Marketing</p>
                                                <div className='dofleoodiistartdivflx'>
                                                    <p className='neosertsttfzrrttyadfdfff'>41 Followers</p>

                                                    <div className='statrrdibvggshhghuju'>
                                                        <i class="fa-regular fa-star"></i>
                                                        <i class="fa-regular fa-star"></i>
                                                        <i class="fa-regular fa-star"></i>
                                                        <i class="fa-regular fa-star"></i>
                                                        <i class="fa-regular fa-star"></i>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='ownseacrgresultpagerightbrdrtopbottom'>
                                        <div className=''>
                                            <p className='tttxtyaplattsyggjjkllkkk'>Levels 41, Emirates Towers, University Road</p>
                                            <p className='tttxtyaplattsyggjjkllkkk'>Sharjah Emirate, United Arab Emirates</p>

                                            <p className='phokennsjjsnyunnrbtk'>Phone: +971 553670071</p>

                                            <Link className="serttxtghsytxoppjkjo" to="/">www.neoosearch.com</Link>


                                            <p className='opperpoodshheyxgbpppn'>Products/Services</p>
                                            <p className='diisjadfebnclkmdgragjksopjkl'>Digital marketing | Advertising | Content production
                                                Digital business card | Graphic designing</p>

                                            <div className='viewprofileimgaedivvhjjnflx'>
                                                <div className='prijjdhjjjjkjaaimg'>
                                                    <img src={prijjdhjjjjkj} alt='...' />
                                                </div>
                                                <Link className='bvhhjjapsidhbfbbrkjk' to="/otherblmprofile">View profile</Link>
                                            </div>

                                            <div className=''>
                                                <p className='sclloidjjdttxppphjo'>Social links</p>
                                                <div className='socijjjsjkjijnndnkdobvbbhflx'>
                                                    <Link className='socijjjsjkjijnndnk'>
                                                        <img src={facebook_icon} alt='...' />
                                                    </Link>
                                                    <Link className='socijjjsjkjijnndnk'>
                                                        <img src={insta_icon} alt='...' />
                                                    </Link>
                                                    <Link className='socijjjsjkjijnndnk'>
                                                        <img src={linkedin_icon} alt='...' />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className='boxosimaggedisahdowdiv'>
                                    <p className='tsttsggsiijxbhnsimag'>
                                        300x250
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {ownpagesearchkeay && <Ownpagesearchkeywordmodal closemodal={setownpagesearchkeay} />}
        </>
    )
}

export default Blmemberlist
