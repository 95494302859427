import React from 'react'

const JoinNowSuccessModal = ({ closepopup2 }) => {
    return (
        <>
            <section className='JoinNowSuccess_Modal'>
                <div className='JoinNowSuccessModal_body'>

                    <div className='content'>
                        <div className='icon_wrap'>
                            <i className="fa-solid fa-check"></i>
                        </div>

                        <h4 className='heading'>Success !</h4>

                        <p className='text'>Thank You! Your application has been summited We will contact you shortly…</p>
                    </div>

                    <button className='done_btn' onClick={() => closepopup2(false)}>Done</button>
                </div>
            </section>
        </>
    )
}

export default JoinNowSuccessModal