import React from 'react'
import Delete from "../../src/Images/Delete.png";
import crosssimgiocn from "../../src/Images/crosssimgiocn.png";
import cameralogo from "../../src/Images/cameralogo.png";
import logoownblm from "../../src/Images/logoownblm.png"
const Editlogogetmodal = ({ closemodal }) => {
    return (
        <div className='uploabannermodalbgshadow'>
            <div className='editlofgogaemodalbg'>
                <div className='editabannerideletcrrsdivhjdsj'>
                    <div className='diesdrddeytecroosdivffzl'>
                        <div className='dettetstyyiocnnhui'>
                            <img src={Delete} alt='...' />
                        </div>

                        <div className='dettetstyyiocnnhui' onClick={() => closemodal()}>
                            <img src={crosssimgiocn} alt='...' />
                        </div>
                    </div>

                    <div className=''>
                        <p className='edittsbannerttxtppp'>Edit Profile Picture</p>
                    </div>
                </div>
                <div className="editprofile">
                    <div className="cameralogodivimgabslt">
                        <div className="cameralogodivimg">
                            <img
                                src={cameralogo}
                                alt="..."
                            />
                        </div>
                        <p className="uplsdttxttppshji">Upload Logo</p>
                        <p className="pixelsttxttppio">(Pixel Size : 000 x 000 PNG/JPEG )</p>
                    </div>
                    <div className="profileimhaggemaindib">
                        <img src={logoownblm} alt="..." />
                    </div>
                </div>
                <div className='browoefilesabbddivforprofikeflx'>
                    <button className='brwdffioevbtgbjhbtnmewlogpro'>
                        <div className='inkspottsrrdivdiv'>
                            <input type='file' className='inkspottsrrdiv' />
                        </div>
                        Browse File

                    </button>
                    <button className='svavtatvvbtnnm'>Save</button>
                </div>
            </div>
        </div>
    )
}

export default Editlogogetmodal
