import React, { useEffect, useState } from "react";
import heand from "../Images/emojiwavinghandsign.png";
import HttpClient from "../utils/HttpClient";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getOwnProfile } from "../Redux/Slice/PostSlice";
import { useNavigate } from "react-router-dom";
import ExchangedYourContactModal from "../Modal/ExchangedYourContactModal";

function Savecontactpopup({ id, data }) {
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const [savedContacts, setsavedContacts] = useState([]);
  const [saveContactsButton, setsaveContactsButton] = useState(false);
  const { UserProfileID } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(saveContactsButton, "saveContactsButton");
  console.log(data?.state?._id == id, "datadata");
  console.log(data?.state, "datadatadatadata");
  console.log(savedContacts, "savedContacts");
  console.log(UserProfileID, id, "UserProfileIDsdfs");
  console.log(id, ":", savedContacts, "locationID", saveContactsButton);
  console.log(data, "sddededsdsdw");

  // for member
  const handleSaveAccount = async () => {
    const data = {
      nonMemberId: id,
    };
    console.log("6584fsg651", data)
    // return
    const res = await HttpClient.requestData(
      "save-member-contacts",
      "POST",
      data
    );
    console.log(res, "contactSave");
    if (res && res?.status) {
      setShowPopup(!showPopup);
      toast.success("Contact Saved successfully");
      navigate('/OwnContactlist')
    } else {
      toast.error("Contact Saving failed");
    }
  };

  // for nonmember
  const handleSaveAccountNonmember = async () => {
    const data = {
      nonMemberId: id,
    };
    const res = await HttpClient.requestData(
      "save-nonmember-contacts",
      "POST",
      data
    );
    console.log(res, "contactSave");
    if (res && res?.status) {
      setShowPopup(!showPopup);
      toast.success("Contact Saved successfully");
    } else {
      toast.error("Contact Saving failed");
    }
  };

  //Saved contactlist
  const savedContactList = async () => {
    const res = await HttpClient.requestData("member-contacts", "GET", {});
    console.log(res, "resDataSavedContacts", id);
    if (res && res?.status) {
      setsavedContacts(res?.data);
      const flag = res?.data.filter((ele) => ele?.nonMemberData?._id === id);
      console.log("flag", flag?.length !== 0);
      setsaveContactsButton(flag?.length !== 0);
    }
  };

  useEffect(() => {
    if (UserProfileID?._id !== id) {
      savedContactList();
    } else {
      navigate("/");
    }
  }, [id, UserProfileID]);

  useEffect(() => {
    dispatch(getOwnProfile());
  }, [dispatch]);

  useEffect(() => {
    if (saveContactsButton == true) {
      navigate("/MemberOtherSavedProfile/" + data?.state?.userName?.trim(), {
        state: data?.state,
      });
    }
  }, [saveContactsButton]);

  return (
    <>
      <section className="Savecontact_sec">
        <div className="content">
          <div className="hand_icon">
            <img src={heand} className="img-fluid" alt="..." />
          </div>
          <div className="">
            <h4 className="head">Dear sir,</h4>

            <p className="txtdear">
              Welcome to Neoo Search Business
              Network, Nice to see you today. I invite
              you to my business community.
              <br />
              <span>
                {" "}
                Please fill out the below form and exchange
                our contact information.
              </span>
            </p>
          </div>
        </div>
        <div className="btn_area">
          {/* {UserProfileID?._id !== id && (
            <button className="exchangecont_btn">Exchange Contact</button>
          )} */}

          {!saveContactsButton && UserProfileID?.memberStatus && (
            <button
              type="button"
              className="savecontact_btn"
              accordion
              onClick={() => handleSaveAccount()}
            >
              <i className="fa-solid fa-user-plus"></i>
              Save Contact
            </button>
          )}
          {!saveContactsButton && !UserProfileID?.memberStatus && (
            <button
              type="button"
              className="savecontact_btn"
              accordion
              onClick={() => handleSaveAccountNonmember()}
            >
              <i className="fa-solid fa-user-plus"></i>
              Save Contact
            </button>
          )}

          {/* <button
            type="button"
            className="savecontact_btn"
             onClick={togglePopup}
          >
            <i className="fa-solid fa-user-plus"></i>
            Save Contact
          </button> */}
        </div>
      </section>

      {showPopup && <ExchangedYourContactModal onClose={togglePopup} UserProfileID={UserProfileID} />}
    </>
  );
}

export default Savecontactpopup;
