import React, { useContext, useEffect, useState } from 'react'
import { Colorcontext } from '../../Component/Colorcontext/ColorContext';
import EditProfileblm from './EditProfileblm';
import Blmpostadd from './Blmpostadd';
import Viewersengagement from './Viewersengagement';
import Upgradeprofile from './Upgradeprofile';
import Contactdetaiilsprofile from './Contactdetaiilsprofile';
import Allpostownpage from './Allpostownpage';
const Ownpostpage = () => {
    const colordata = useContext(Colorcontext);
    const [tabpostall, setTabpostall] = useState(1)
    useEffect(() => {

        colordata.setcolor(true);
        return () => {
            colordata.setcolor(false);
        };
    }, []);

    const postdicclickbtn = (tabnumber) => {
        setTabpostall(tabnumber)
    }
    return (
        <section className='ownblmprofiledivmaoin '>
            <div className='cust_container'>
                <div className='ownblmprofiledivflx'>
                    <div className='ownblmprofiledivleft'>
                        <div className='repsonsivedivcls'>
                            {/* For mobile responsive code start  */}
                            <div className=' oenblmsnfidivmainleft'>
                                <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                    <EditProfileblm />
                                </div>

                                <div className='oenblmsnfdrpbapbbtm responsivdedivmain '>
                                    <Upgradeprofile />
                                </div>
                               

                                
                            </div>


                            <div className='oenblmsnfidivmainright'>
                            <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                    <Contactdetaiilsprofile />
                                </div>
                                <div className='oenblmsnfdrpbapbbtm responsivdedivmain '>
                                    <Blmpostadd />
                                </div>
                              

                            </div>
                            {/* For mobile responsive code  end */}
                        </div>


                        <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                            <EditProfileblm />
                        </div>

                        <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                            <Blmpostadd />
                        </div>

                        <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                            <Contactdetaiilsprofile />
                        </div>

                        <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                            <Upgradeprofile />
                        </div>

                    </div>
                    <div className='ownblmprofiledivright'>
                        <div className='allpostmyapooskducvsdic'>
                            <div className='allpostmyapooskducvsdictop'>
                                <div className='postamtydposdivflx'>
                                    <button className={tabpostall === 1 ? "poisttsdpostdibbtn actv " : "poisttsdpostdibbtn"} onClick={() => postdicclickbtn(1)}>All Post</button>
                                    <button className={tabpostall === 2 ? "poisttsdpostdibbtn actv " : "poisttsdpostdibbtn"} onClick={() => postdicclickbtn(2)}>My Post</button>
                                </div>
                            </div>

                            <div className='allpostmyapooskducvsdicbottom'>
                                {tabpostall === 1 && (
                                    <>
                                        <Allpostownpage />
                                    </>
                                )}
                                {tabpostall === 2 && (
                                    <>

                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Ownpostpage
