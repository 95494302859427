import React, { useState } from "react";
import LoginHeader from "./LoginHeader";
import { Outlet } from "react-router-dom";
import Loader from "../Component/Loader";
import Header from "./Header";
import HomeHeader from "./HomeHeader";
import Footer from "./Footer";

const LoginLayout = () => {
  const [load, setLoad] = useState(true);
  setTimeout(() => {
    setLoad(false);
  }, 2000);
  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <div>
          <HomeHeader />
          <Outlet />
          <Footer />
        </div>
      )}
    </>
  );
};

export default LoginLayout;
