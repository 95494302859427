import React from 'react'

function PrivacyPolicy() {
  return (
    <>
    <section className='aboutneoosearch_sec'>
                <div className='cust_container'>
                    <div className='toparea'>
                        <h4 className='headline'>Privacy policy</h4>
                    </div>

                    <div className='textarea'>
                        <p className='text'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</p>
                       
                        <h4 className='headline'>Table of Contents</h4>
                        <ul className='list'>
                            <li>Data We Collect</li>
                            <li>How We Use Your Information</li>
                            <li>Your Choices and Obligations</li>
                            <li>Other Important Information</li>
                            <li>How do we share information with our partners</li>
                            <li>How do the Neoo Search Companies work together?</li>
                            <li>What are our rights to deactivate your profile?</li>
                        </ul>
                    </div>

                </div>
            </section>
    </>
  )
}

export default PrivacyPolicy