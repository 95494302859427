import React, { useEffect } from 'react'
import mainlogo from "../Images/Neoo search logo purple.png"
import { Link, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getOwnProfile } from '../Redux/Slice/PostSlice'

const Sidebar = () => {
    const dispatch=useDispatch()
    const { UserProfileID } = useSelector((state) => state.app);
console.log(UserProfileID,"UserProfileID")
    const sidebarclose = () => {
        document.querySelector(".sidebardiv").style.cssText += "right: -350px";
    }

    useEffect(() => {
      dispatch(getOwnProfile())
    }, [])
    
    return (
        <>
            <section className="sidebardiv">
                <div className='sidetop'>

                    <div className='frtsalonlogo'>
                        <img src={mainlogo} alt="..." />
                    </div>

                    <div className='crosssideiocn' onClick={sidebarclose}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                </div>
                <div className='sidebottom'>
                    <ul className='headermenulistul'>
                        <li className="ftrmenulistli">
                            <NavLink to="/about-neoo-search" className="listlnktxt">
                                About Us
                            </NavLink>
                        </li>
                        <li className="ftrmenulistli">
                            <NavLink to="/explore" className="listlnktxt">
                                Explore
                            </NavLink>
                        </li>
                        <li className="ftrmenulistli">
                            <NavLink to="/products-and-services" className="listlnktxt">
                                Products & services
                            </NavLink>
                        </li>

                        <li className="ftrmenulistli">
                            <NavLink to="/businessdirectory" className="listlnktxt">
                            About Local directory
                            </NavLink>
                        </li>

                        { <li className="ftrmenulistli">
                            <NavLink to="/reward-home-page" className="listlnktxt">
                                Rewards
                            </NavLink>
                        </li>}

                        {<li className="ftrmenulistli">
                            <NavLink to="/refer-and-earn" className="listlnktxt">
                                Refer & Earn
                            </NavLink>
                        </li>}

                        <li className="ftrmenulistli">
                            <NavLink to="/people-accelerator-program" className="listlnktxt">
                                PA Program
                            </NavLink>
                        </li>
                        <li className="ftrmenulistli">
                            <NavLink to="/terms-of-services" className="listlnktxt">
                                Terms of services
                            </NavLink>
                        </li>
                        <li className="ftrmenulistli">
                            <NavLink to="/terms-of-services" className="listlnktxt">
                                User Agreement
                            </NavLink>
                        </li>
                        <li className="ftrmenulistli">
                            <NavLink to="/privacy-policy" className="listlnktxt">
                                Privacy policy
                            </NavLink>
                        </li>

                    </ul>
                </div>

            </section>
        </>
    )
}

export default Sidebar