import React from 'react'
import giftcard from "../../Images/gift-icon.png"
import bannerimg from "../../Images/earning_rewards_banner.png"
import { Link } from 'react-router-dom'
import icon1 from "../../Images/earning_icon1.png"
import icon2 from "../../Images/earning_icon2.png"
import icon3 from "../../Images/earning_icon3.png"
import icon4 from "../../Images/earning_icon4.png"
import icon5 from "../../Images/earning_icon5.png"

const HowEarningRewards = () => {
    return (
        <>
            <section className='howEarningRewards_page'>
                <div className='cust_container'>
                    <div className="top_area">
                        <a className="backbtn" href="/reward">
                            <i className="fa-solid fa-arrow-left-long" />
                        </a>
                        <h4 className="heading">How Earning Rewards</h4>
                        <Link to="/my-wallet" className="icon">
                            <img
                                src={giftcard}
                                className="img-fluid"
                                alt="....."
                            />
                        </Link>
                    </div>

                    <div className='conetnt'>
                        <div className="earningRewards_banner">
                            <img src={bannerimg} className='img-fluid' alt='banner' />
                        </div>

                        <div className='earninghowareyou_wrap'>
                            <h4 className='head'>How are you earning Neoo search rewards?</h4>

                            <div className='member_box'>
                                <div className='icon'>
                                    <img src={icon1} className='img-fluid' alt='..' />
                                </div>
                                <div className='ditails'>
                                    <h6 className='name'>You must be a member</h6>
                                    <p className='text'>Get 1500 points</p>
                                </div>
                            </div>

                            <div className='member_box'>
                                <div className='icon'>
                                    <img src={icon2} className='img-fluid' alt='..' />
                                </div>
                                <div className='ditails'>
                                    <h6 className='name'>Add to Members </h6>
                                    <p className='text'>Get 500 points for each member</p>
                                </div>
                            </div>

                            <div className='member_box'>
                                <div className='icon'>
                                    <img src={icon3} className='img-fluid' alt='..' />
                                </div>
                                <div className='ditails'>
                                    <h6 className='name'>Add to Non-member</h6>
                                    <p className='text'>Get 250 points for each member </p>
                                </div>
                            </div>

                            <div className='member_box'>
                                <div className='icon'>
                                    <img src={icon4} className='img-fluid' alt='..' />
                                </div>
                                <div className='ditails'>
                                    <h6 className='name'>Profile activities</h6>
                                    <p className='text'>Below are the rewards you can earn by
                                        visiting the profiles of Neoo search members.</p>

                                    <ol>
                                        <li>Earn 200 points for each like.</li>
                                        <li>Each call through the profile earns 50 points.</li>
                                        <li>Each WhatsApp contact gets 50 points.</li>
                                        <li>Check the website link for 200 points.</li>
                                        <li>Visit social media links; for each link, earn 100 points.</li>
                                        <li>View products; for each hit, earn 100 points.</li>
                                        <li>Download the PDF profile and earn 200 points.</li>
                                        <li>Rating stars and get rewards points
                                            <ol>
                                                <li>Start 50 points</li>
                                                <li>Starts 100 points</li>
                                                <li>Stats 150 points</li>
                                                <li>Stars 200 points</li>
                                                <li>Starts 250 points</li>
                                            </ol>
                                        </li>
                                    </ol>
                                </div>
                            </div>

                            <div className='member_box'>
                                <div className='icon'>
                                    <img src={icon5} className='img-fluid' alt='..' />
                                </div>
                                <div className='ditails'>
                                    <h6 className='name'>Redeem offers</h6>
                                    <p className='text'>Get 300 points back when you redeem each offer.</p>
                                </div>
                            </div>

                            <div className='Summary_btn'>
                                <button className='btn'>Summary</button>
                            </div>

                        </div>


                        <div className='OfferTerms_Conditions'>
                            <h4>Offer Terms Terms & Conditions</h4>
                            <span><i className="fa-solid fa-angle-right"></i></span>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default HowEarningRewards