import React from 'react'
import cameralogo from "../../Images/cameralogo.png";
import otherblmbannerimg from "../../Images/otherblmbannerimg.png"
const Otheruploadbannerblm = () => {
    return (
        <div className='uploadbannerblmdiv '>
            <div className='uploadbannerdivbg'>
             

                <div className='cameralogodivimgabslt'>
                 
                    <div className='cameralogodivimg'>
                        <img src={cameralogo} alt='...' />
                    </div>
                    <p className='uplsdttxttppshji'>Upload Banner</p>
                    <p className='pixelsttxttppio'>(Pixel Size : 000 x 000 PNG/JPEG )</p>


                </div>

                <div className="upoloafgsbabberfibommjge">
                    <img src={otherblmbannerimg} alt='...' />
                </div>
            </div>
        </div>
    )
}

export default Otheruploadbannerblm
