import React from "react";
import dasicon from "../../Images/vender/dashboardicon.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { PartnersOwnOffer } from "../../Redux/Slice/Offer";

function DashboardStatus() {
  const dispatch = useDispatch();
  const { PartnersOwnOfferData } = useSelector((state) => state.offer);
  console.log(PartnersOwnOfferData, "PartnersOwnOfferData");

  useEffect(() => {
    dispatch(PartnersOwnOffer());
  }, []);

  return (
    <>
      <section className="DashboardStatus">
        <ul className="allwrap">
          <li className="box">
            <p className="name">
              <img src={dasicon} className="img-fluid" alt="..." /> Dashboard
            </p>
          </li>

          <li className="box">
            <h6 className="amount">
              {PartnersOwnOfferData?.[0]?.reddemingPoints}
            </h6>
            <p className="text">Redeeming points</p>
          </li>

          <li className="box">
            <h6 className="amount">
              {" "}
              {PartnersOwnOfferData?.[0]?.offerValidity} days
            </h6>
            <p className="text">Offer validity</p>
          </li>

          <li className="box">
            <h6 className="amount">
              {PartnersOwnOfferData?.[0]?.voucherNo}{" "}
            </h6>
            <p className="text">Offer target</p>
          </li>
        </ul>

        <div className="resp_allwrap">
          <div className="toppart">
            <Link to="#" className="back_btn">
              <i className="fa-solid fa-arrow-left-long"></i>
            </Link>
            <div className="name">
              <img src={dasicon} className="img-fluid" alt="..." /> Dashboard
            </div>
            <div></div>
          </div>
          <ul className="bottompart">
            <li className="box">
              <h6 className="amount">
                {" "}
                {PartnersOwnOfferData?.[0]?.reddemingPoints}
              </h6>
              <p className="text">Redeeming points</p>
            </li>

            <li className="box">
              <h6 className="amount">
                {" "}
                {PartnersOwnOfferData?.[0]?.voucherValidity} days
              </h6>
              <p className="text">Offer validity</p>
            </li>

            <li className="box">
              <h6 className="amount">
                {PartnersOwnOfferData?.[0]?.voucherValidity}
              </h6>
              <p className="text">Offer target</p>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default DashboardStatus;
