import React from 'react'
import "./Withdraw.css"
import WithdrawTopHeading from './WithdrawTopHeading'
import { Link } from 'react-router-dom'

const BankAccountUpdate = () => {
  return (
    <>
      <div className='bankaccountUpdate_page'>
        <div className='cust_container'>
          <WithdrawTopHeading />

          <div className='bankaccountadd_form'>
            <form>

              {/****first part***/}
              <h4 className='formheading'>Add your bank account</h4>

              <div className="form-group">
                <select className="form-control" id="">
                  <option selected>Select country</option>
                  <option>United arab emirates</option>
                  <option>United arab emirates</option>
                  <option>United arab emirates</option>
                  <option>United arab emirates</option>
                </select>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  aria-describedby="ibanHelp"
                  placeholder="Enter IBAN"
                />
                <small id="ibanHelp" className="form-text text-muted">
                  Enter 23 digits
                </small>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Account number"
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Bank name"
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Emirate"
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Account name"
                />
              </div>

              <div className='nextButton'>
                <button className='btn'>Next</button>
              </div>



              {/***Next part****/}
              <h4 className='formheading'>Proof of residency</h4>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="United arab emirates"
                />
              </div>


              <h4 className='formheading mt-5'>Verification method</h4>

              <div className="form-group">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    defaultValue="option1"
                    defaultChecked=""
                  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    Emirates ID
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios2"
                    defaultValue="option2"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    SPassport
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios3"
                    defaultValue="option3"
                    disabled=""
                  />
                  <label className="form-check-label" htmlFor="exampleRadios3">
                    Driving license
                  </label>
                </div>

                <div className="uploadfile_check">
                  <label>Upload file <span>(JPEG, PNG, PDF)</span></label>
                  <div className='attach_icon'>
                    <i className="fa-solid fa-paperclip"></i>
                    <input className="" type="file" />
                  </div>
                </div>

              </div>



              <div className='nextButton'>
                <Link to="/success" className='btn'>Submit</Link>
              </div>

            </form>
          </div>

        </div>
      </div>
    </>
  )
}

export default BankAccountUpdate