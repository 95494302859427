import React from 'react'
import facebook_icon from "../../src/Images/facebook_icon.png";
import linkedin_icon from "../../src/Images/linkedin_icon.png";
import insta_icon from "../../src/Images/insta_icon.png"
const Editsocilamediamodal = ({closemodal}) => {
    return (
        <div className='uploabannermodalbgshadow'>
            <div className='editsonbscimedialsodlonkbg'>
                <div className="crossdivicon" onClick={()=>closemodal()}>
                    <i className="fa-solid fa-xmark" />
                </div>
                <div className="upldbantrtupldbannerdivmaindiv">
                    <h4 className="upldbannertrtxtpp">Edit social link</h4>

                </div>

                <div className='sosvciidlidnbedittdivmainflxmaindiv'>

                    <div className='sosvciidlidnbedittdivmainflx'>
                        <div className='sosvciidlidnbedittdiv'>
                            <img src={facebook_icon} alt='...' />
                        </div>
                        <div className='inppoiustsllonsocikk'>
                            <input type='text' className='inppoiustsllonsocikkinpy' />
                        </div>
                    </div>

                    <div className='sosvciidlidnbedittdivmainflx'>
                        <div className='sosvciidlidnbedittdiv'>
                            <img src={linkedin_icon} alt='...' />
                        </div>
                        <div className='inppoiustsllonsocikk'>
                            <input type='text' className='inppoiustsllonsocikkinpy' />
                        </div>
                    </div>

                    <div className='sosvciidlidnbedittdivmainflx'>
                        <div className='sosvciidlidnbedittdiv'>
                            <img src={insta_icon} alt='...' />
                        </div>
                        <div className='inppoiustsllonsocikk'>
                            <input type='text' className='inppoiustsllonsocikkinpy' />
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Editsocilamediamodal
