import React from 'react'
import editicon from "../../Images/editicon.png";
import mobile_icon from "../../Images/mobile_icon.png";
import phone_icon from "../../Images/phone_icon.png";
import whatsapp_icon from "../../Images/whatsapp_icon.png";
import mail_icon from "../../Images/mail_icon.png";
import website_icon from "../../Images/website_icon.png";
import location from "../../Images/location.png";
import arrowdirections from "../../Images/arrowdirections.png"
import Companyinfoeditmodal from '../../Modal/Companyinfoeditmodal'
const OtherContactdetaiilsprofile = () => {
    return (
        <div className='contactdetsdiv'>
            <div className='contactdetsdivdivbmain'>
                <div className='consttactdetatlsarroedyrdiv'>
                    <p className='cinnattddttdtsttspo'>Contact Details</p>
                
                </div>
                <div className='indfodetatildivmainui'>
                    <div className='infodetatddivfllsxdlx'>
                        <div className='indfodetatildiv'>
                            <img src={mobile_icon} alt='...' />
                        </div>
                        <p className='ingfddtertrrxrpp'> +971 6 7427316 </p>
                    </div>
                    <div className='infodetatddivfllsxdlx'>
                        <div className='indfodetatildiv'>
                            <img src={phone_icon} alt='...' />
                        </div>
                        <p className='ingfddtertrrxrpp'> +971553670071 </p>
                    </div>
                    <div className='infodetatddivfllsxdlx'>
                        <div className='indfodetatildiv'>
                            <img src={whatsapp_icon} alt='...' />
                        </div>
                        <p className='ingfddtertrrxrpp'> +971553670071 </p>
                    </div>
                    <div className='infodetatddivfllsxdlx'>
                        <div className='indfodetatildiv'>
                            <img src={mail_icon} alt='...' />
                        </div>
                        <p className='ingfddtertrrxrpp'> hamsascreativee@gmail.com </p>
                    </div>
                    <div className='infodetatddivfllsxdlx'>
                        <div className='indfodetatildiv'>
                            <img src={website_icon} alt='...' />
                        </div>
                        <p className='ingfddtertrrxrpp'> https://neoosearch.com/ </p>
                    </div>

                </div>

            </div>

            <div className='contactdetsdivdivbmaindivbtm'>
                <div className='infodetatddivfllsxdlx'>
                    <div className='indfodetatildiv'>
                        <img src={location} alt='...' />
                    </div>
                    <p className='ingfddtertrrxrpp'> Business Center, <br />
                        Sharjah Publishing City Free Zone <br />
                        Dubai. United Arab Emirates

                    </p>
                </div>
                <div className='direxrrcttstbtnmain'>
                    <button className='direxrrcttstbtn'>
                        Get Directions
                        <img src={arrowdirections} alt='..' />
                    </button>
                </div>
            </div>
        </div>

    )
}

export default OtherContactdetaiilsprofile
