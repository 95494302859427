import React, { useState } from 'react'
import vectorarrow from "../../src/Images/vectorarrow.png"
const Companyinfoeditmodal = ({ closemodal }) => {
    const [drowdowsncustom, setDrowdowsncustom] = useState(false)
    const drowdowncustclick = () => {
        setDrowdowsncustom(!drowdowsncustom)
    }
    return (
        <div className='uploabannermodalbgshadow'>
            <div className='compamyinfoeditbgmain'>
                <div className='crossdivicon' onClick={() => closemodal()}>
                    <i className="fa-solid fa-xmark"></i>
                </div>
                <div className='eidutcommhifnnfodivdibvamain'>
                    <div className='eidutcommhifnnfodiv'>
                        <h4 className='upldbannertrtxtpp'>Edit Company information</h4>
                        <div className='compamydinfodivmain'>
                            <div className='compamydinfodivmainbtm'>
                                <p className='conattaindptxtppnewallasa'> Full name <span> *
                                </span>
                                </p>
                                <input type="text" placeholder="john" className="inpustxocntbusuitxtppnewallattyy" />


                            </div>
                            <div className='compamydinfodivmainbtm'>
                                <p className='conattaindptxtppnewallasa'> Current designation <span> *
                                </span>
                                </p>
                                <div className='dropwdowndivfrmbusineenewwlas'>
                                    <p className='vectoraarowdivomgulmaili'>
                                        Advertising and marketing
                                    </p>
                                    <div className='vectoraarowdivomgnenwlal' onClick={drowdowncustclick}>
                                        <img src={vectorarrow} alt='...' />
                                    </div>
                                    {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                        <ul className='vectoraarowdivomgulmaiul'>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Advertising and marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Advertising and marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Advertising and marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Advertising and marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Advertising and marketing
                                            </li>
                                        </ul>
                                    </div>}
                                </div>
                            </div>
                            <div className='compamydinfodivmainbtm'>
                                <p className='conattaindptxtppnewallasa'> Company name <span> *
                                </span>
                                </p>
                                <input type="text" placeholder="john" className="inpustxocntbusuitxtppnewallattyy" />
                            </div>
                            <div className='compamydinfodivmainbtm'>
                                <p className='conattaindptxtppnewallasa'> Type of business <span> *
                                </span>
                                </p>
                                <div className='dropwdowndivfrmbusineenewwlas'>
                                    <p className='vectoraarowdivomgulmaili'>
                                        Managing Director
                                    </p>
                                    <div className='vectoraarowdivomgnenwlal' onClick={drowdowncustclick}>
                                        <img src={vectorarrow} alt='...' />
                                    </div>
                                    {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                        <ul className='vectoraarowdivomgulmaiul'>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Managing Director
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Managing Director
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Managing Director
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Managing Director
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Managing Director
                                            </li>
                                        </ul>
                                    </div>}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='eidutcommhifnnfodiv'>
                        <h4 className='upldbannertrtxtpp'>Edit Contact</h4>
                        <div className='compamydinfodivmain'>
                            <div className='compamydinfodivmainbtm'>
                                <p className='conattaindptxtppnewallasa'> Telephone <span>
                                </span>
                                </p>
                                <input type="number" placeholder="+971 553670071" className="inpustxocntbusuitxtppnewallattyy" />


                            </div>
                            <div className='compamydinfodivmainbtm'>
                                <p className='conattaindptxtppnewallasa'> Mobile number <span> *
                                </span>
                                </p>
                                <input type="number" placeholder="+971 553670071" className="inpustxocntbusuitxtppnewallattyy" />
                            </div>
                            <div className='compamydinfodivmainbtm'>
                                <p className='conattaindptxtppnewallasa'> WhatsApp number<span>
                                </span>
                                </p>
                                <input type="number" placeholder="+971 553670071" className="inpustxocntbusuitxtppnewallattyy" />
                            </div>
                            <div className='compamydinfodivmainbtm'>
                                <p className='conattaindptxtppnewallasa'> Work email <span>
                                </span>
                                </p>
                                <input type="text" placeholder="neoosearch@gmail.com" className="inpustxocntbusuitxtppnewallattyy" />
                            </div>
                            <div className='compamydinfodivmainbtm'>
                                <p className='conattaindptxtppnewallasa'> Website <span>
                                </span>
                                </p>
                                <input type="text" placeholder="www.neoosearch.com" className="inpustxocntbusuitxtppnewallattyy" />
                            </div>

                        </div>
                    </div>

                    <div className='eidutcommhifnnfodiv'>
                        <h4 className='upldbannertrtxtpp'>Edit Address</h4>
                        <div className='compamydinfodivmain'>
                            <div className='compamydinfodivmainbtm'>
                                <p className='conattaindptxtppnewallasa'> Location <span>
                                </span>
                                </p>
                                <input type="text" placeholder="Business Center, " className="inpustxocntbusuitxtppnewallattyy" />


                            </div>
                            <div className='compamydinfodivmainbtm'>
                                <p className='conattaindptxtppnewallasa'> Address <span>
                                </span>
                                </p>
                                <input type="text" placeholder="Sharjah Publishing City Free Zone" className="inpustxocntbusuitxtppnewallattyy" />


                            </div>
                            <div className='compamydinfodivmainbtm'>
                                <p className='conattaindptxtppnewallasa'> State<span>
                                </span>
                                </p>
                                <div className='dropwdowndivfrmbusineenewwlas'>
                                    <p className='vectoraarowdivomgulmaili'>
                                        Sharjah

                                    </p>
                                    <div className='vectoraarowdivomgnenwlal' onClick={drowdowncustclick}>
                                        <img src={vectorarrow} alt='...' />
                                    </div>
                                    {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                        <ul className='vectoraarowdivomgulmaiul'>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Sharjah

                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Sharjah

                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Sharjah

                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Sharjah

                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Sharjah

                                            </li>
                                        </ul>
                                    </div>}
                                </div>
                            </div>

                            <div className='compamydinfodivmainbtm'>
                                <p className='conattaindptxtppnewallasa'> Country <span>
                                </span>
                                </p>
                                <div className='dropwdowndivfrmbusineenewwlas'>
                                    <p className='vectoraarowdivomgulmaili'>
                                        United arab emirate
                                    </p>
                                    <div className='vectoraarowdivomgnenwlal' onClick={drowdowncustclick}>
                                        <img src={vectorarrow} alt='...' />
                                    </div>
                                    {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                        <ul className='vectoraarowdivomgulmaiul'>
                                            <li className='vectoraarowdivomgulmaili'>
                                                United arab emirate
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                United arab emirate
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                United arab emirate
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                United arab emirate
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                United arab emirate
                                            </li>
                                        </ul>
                                    </div>}
                                </div>
                            </div>
                            <div className='compamydinfodivmainbtm'>
                                <p className='conattaindptxtppnewallasa'> Location map <span>
                                </span>
                                </p>
                                <input type="text" placeholder="https://maps.app.goo.gl/Lq5s7ynECLfaLBrB8" className="inpustxocntbusuitxtppnewallattyy" />
                            </div>

                        </div>
                    </div>
                    <div className='eidutcommhifnnfodiv'>
                        <div className='svavtatvvbtnnmddsupscftt'>
                            <button className='svavtatvvbtnnm'>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Companyinfoeditmodal
