import React, { useEffect, useState } from "react";
import giftcard from "../../Images/gift-icon.png";
import img1 from "../../Images/cover img.jpg";
import img2 from "../../Images/cover img.jpg";
import img3 from "../../Images/cover img.jpg";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  OfferCategoriesWiseDetails,
  OwnWalletDetails,
  SingleOurPartNerAllOffer,
  congratsModalView,
} from "../../Redux/Slice/Offer";
import { getOwnProfile } from "../../Redux/Slice/PostSlice";
import AddvoucherWalletModal from "../../Modal/AddvoucherWalletModal";
import CongratsModal from "../../Modal/CongratsModal";

function CategoryWiseAllOffersDetals() {
  const [modalD, setModalD] = useState(false);
  const [modalF, setModalF] = useState(false);
  const [OfferSingleIDforPurchase, setOfferSingleIDforPurchase] = useState("");
  const params = useParams();
  const dispatch = useDispatch();
  const { OfferCategoryWiseData } = useSelector((state) => state.offer);
  const { UserProfileID } = useSelector((state) => state.app);

  //offer view states start

  const { congratsModalStatus, WalletData } = useSelector(
    (state) => state.offer
  );
  const nonPurchaseWalletOffer = WalletData?.filter(
    (item) => !item?.voucherReedem
  );
  console.log(congratsModalStatus, "congratsModalStatus");

  //offer view states end
  console.log(UserProfileID?.walletPoints?.points, "params");
  console.log(OfferCategoryWiseData, "OfferCategoryWiseData");
  console.log(OfferSingleIDforPurchase, "offerid");
  console.log(params, "offerid");
  const cardbox = [
    {
      id: 1,
      src: img1,
      text: "0% Interest Payment Plan for 6 months on a minimum transaction of AED 1,000 when you use your Credit Card at any Dynatrade Auto Service Outlets across UAE.",
    },
    {
      id: 2,
      src: img2,
      text: "0% Interest Payment Plan for 6 months on a minimum transaction of AED 1,000 when you use your Credit Card at any Dynatrade Auto Service Outlets across UAE.",
    },
    {
      id: 3,
      src: img3,
      text: "0% Interest Payment Plan for 6 months on a minimum transaction of AED 1,000 when you use your Credit Card at any Dynatrade Auto Service Outlets across UAE.",
    },
  ];

  const onclick2 = () => {
    setModalD(false);

    dispatch(congratsModalView(true));
  };
  const handleClick = (id) => {
    setOfferSingleIDforPurchase(id);
    setModalD(!modalD);
  };
  useEffect(() => {
    dispatch(OfferCategoriesWiseDetails(params.id));
    dispatch(getOwnProfile());
  }, []);
  useEffect(() => {
    dispatch(OwnWalletDetails());
  }, []);

  return (
    <>
      <section className="OffersCategDetails_page">
        <div className="cust_container">
          <div className="top_area">
            <Link className="backbtn" to="/offers-categories">
              <i className="fa-solid fa-arrow-left-long" />
            </Link>
            <h4 className="heading">{params?.name}</h4>
            <Link to="/my-wallet">
              <div className="icon">
                <img src={giftcard} className="img-fluid" alt="....." />
                {nonPurchaseWalletOffer?.length > 0 && (
                  <span className="notification_count">
                    {nonPurchaseWalletOffer?.length}
                  </span>
                )}
              </div>
            </Link>
          </div>
          {OfferCategoryWiseData?.map((item, index) => (
            <div className="card_wrap" key={index}>
              <div className="img_wrap">
                <img
                  src={item?.offerCoverImage}
                  className="img-fluid"
                  alt=".."
                />
              </div>
              <div className="description">
                <p>{item?.description}</p>
              </div>

              <div className="count_area">
                <p className="textbox">
                  <span>{UserProfileID?.walletPoints?.points}</span> Neoo search
                  points
                </p>

                <div className="Offerdetails_accordion">
                  {/* <Accordion> */}
                  {/* <AccordionItem> */}
                  {/* <AccordionItemHeading> */}
                  {/* <AccordionItemButton> */}
                  <div className="nonAccordion__button">
                    <p className="textbox">
                      <span>{item?.reddemingPoints}</span> Redeeming points
                    </p>
                    <Link
                      to={`/offers-categories-details/${params?.name}/${item?._id}`}
                      className="name"
                    >
                      Offer details
                    </Link>
                  </div>

                  {/* </AccordionItemButton> */}
                  {/* </AccordionItemHeading> */}
                  {/****inner start*/}
                  {/* <AccordionItemPanel> */}
                  {/* <div className="inner_accordion">
                          <Accordion>
                            <AccordionItem>
                              <AccordionItemHeading>
                                <AccordionItemButton>
                                  How can you get this offer
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                <p>{item?.howToGetOffer}</p>
                              </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                              <AccordionItemHeading>
                                <AccordionItemButton>
                                  Terms and Conditions
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                <p>{item?.offerTermsCondition}</p>
                              </AccordionItemPanel>
                            </AccordionItem>
                          </Accordion>
                        </div> */}
                  {/****inner end*/}

                  {/* <div className="bottom_part">
                          <a
                            href={item?.profileLink}
                            target="_blank"
                            className="ViewProfile_btn"
                          >
                            View Profile
                          </a>
                          {item?.purchasedStatus ? (
                            <div className="d-flex align-items-center">
                              <div
                              
                                style={{ cursor: "pointer" }}
                                className="Redeemed_btn bg-success "
                              >
                                Redeemed
                              </div>
                            </div>
                          ) : item?.voucherCount >= item?.voucherNo ? (
                            <div className="d-flex align-items-center">
                              <div
                               
                                style={{ cursor: "pointer" }}
                                className="Redeemed_btn bg-danger "
                              >
                                Expired
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <div
                                onClick={() => handleClick(item?._id)}
                                style={{ cursor: "pointer" }}
                                className="RedeemNow_btn"
                              >
                                Redeem Now
                              </div>
                              <span className="thikbtn">
                                <i className="fa-solid fa-circle-check"></i>
                              </span>
                            </div>
                          )}
                        </div> */}
                  {/* </AccordionItemPanel> */}
                  {/* </AccordionItem> */}
                  {/* </Accordion> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {modalD && (
        <AddvoucherWalletModal
          OfferSingleIDforPurchase={OfferSingleIDforPurchase}
          closemodal1={setModalD}
          onclick={() => {
            onclick2();
          }}
        />
      )}
      {congratsModalStatus && <CongratsModal closemodal2={setModalF} />}
    </>
  );
}

export default CategoryWiseAllOffersDetals;
