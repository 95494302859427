import React from 'react'
import Programimg from "../../Images/explore/Program.png"
import { Link } from 'react-router-dom'

const ReferEarnProgram = () => {
    return (
        <>
            <section className='ReferEarnProgram_sec'>
                <div className='Program_img'>
                    <img src={Programimg} className='img-fluid' alt='..' />
                </div>
                <div className='cust_container'>
                    <div className='content'>
                        <h2 className='head'>Refer & Earn <span>Program</span></h2>
                        <p className='text'>Do you love that your friends and families get free instant money? Here's your chance to get the best of both! With the Neo Search Refer and Earn Program, refer your friends to Neo Search and get them to purchase the Neo Search Business Pack.</p>
                        <p className='text'>When your friends register and purchase, you will not only earn, but your and your friend's businesses will also grow.</p>

                        <div className='OrderNow_btn' style={{ justifyContent: "start", }}>
                            <Link to="/refer-and-earn" className='btn'>
                                <span><i className="fa-solid fa-angle-right"></i></span>
                                Learn more
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReferEarnProgram