import React from 'react'
import "./Withdraw.css"
import WithdrawTopHeading from './WithdrawTopHeading'
import EarnedBalanceTransfer from './EarnedBalanceTransfer'
import EarnBalanceTransferDetails from './EarnBalanceTransferDetails'
import AvailableTransferBalance from './AvailableTransferBalance'

const TransferNow = () => {
    return (
        <div className='cust_container'>
            <WithdrawTopHeading />
            <EarnedBalanceTransfer />
            <EarnBalanceTransferDetails />
            <AvailableTransferBalance />
        </div>
    )
}

export default TransferNow