import React, { useState, useRef, useEffect } from "react";
import EmojiPicker from "emoji-picker-react";
import Imageicon from "../../Images/Imageicon.jpg";
import Videoicon from "../../Images/Videoicon.jpg";
import Documenticon from "../../Images/Documenticon.jpg";

const ChatFooter = ({
  handlePaperclipClick,
  handleTyping,
  sendMessage,
  InputMsg,
  setInputMsg,
  showFileUpload,
  handleFileChange,
  sendFile,
  setSelectedFile,
  whoIs,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const fileUploadRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const handleEmojiClick = (event, emojiObject) => {
    const emoji =
      emojiObject.emoji ||
      emojiObject.native ||
      emojiObject.emojiObject ||
      event.emoji;
    setInputMsg((prevInput) => prevInput + emoji);
    setShowEmojiPicker(false);
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      // setSelectedFile(file);

      handleFileChange(e);
      sendFile(file);
      handlePaperclipClick();
    }
  };

  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false); // Close the emoji picker if clicked outside
    }

    if (
      fileUploadRef.current &&
      !fileUploadRef.current.contains(event.target)
    ) {
      handlePaperclipClick(); // Close the file upload modal if clicked outside
    }
  };

  useEffect(() => {
    if (showEmojiPicker || showFileUpload) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker, showFileUpload]);

  return (
    <>
      {whoIs !== "Admin" && (
        <div className="chatbox">
          <div className="chatbox-input">
            <i
              className="fa-regular fa-face-grin"
              onClick={() => setShowEmojiPicker((prev) => !prev)}
              style={{ cursor: "pointer" }}
            />
            <i
              className="fa-sharp fa-solid fa-paperclip"
              onClick={handlePaperclipClick}
              style={{ cursor: "pointer" }}
            />
            <input
              type="text"
              placeholder="Type a message"
              value={InputMsg}
              onChange={(e) => {
                setInputMsg(e.target.value);
                handleTyping();
              }}
              onKeyDown={handleKeyDown}
            />
            <i
              className="fa-solid fa-paper-plane"
              onClick={sendMessage}
              style={{ cursor: "pointer" }}
            />
          </div>

          {showEmojiPicker && (
            <div className="emoji-picker" ref={emojiPickerRef}>
              <EmojiPicker
                onEmojiClick={handleEmojiClick}
                height={340}
                width={400}
              />
            </div>
          )}

          {showFileUpload && (
            <div className="file-upload-modal" ref={fileUploadRef}>
              <div className="inpuyttyimagdedivflx">
                <div className="inputimagadibvmain">
                  <img src={Imageicon} alt="Image Icon" />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileSelect}
                    className="inputimagadibvmaininputfile"
                  />
                </div>
                <p className="inputimagedfiletxtpp"> Image </p>
              </div>

              <div className="inpuyttyimagdedivflx">
                <div className="inputimagadibvmain">
                  <img src={Videoicon} alt="Video Icon" />
                  <input
                    type="file"
                    accept="video/*"
                    onChange={handleFileSelect}
                    className="inputimagadibvmaininputfile"
                  />
                </div>
                <p className="inputimagedfiletxtpp"> Video </p>
              </div>

              <div className="inpuyttyimagdedivflx">
                <div className="inputimagadibvmain">
                  <img src={Documenticon} alt="Document Icon" />
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx"
                    onChange={handleFileSelect}
                    className="inputimagadibvmaininputfile"
                  />
                </div>
                <p className="inputimagedfiletxtpp"> Document </p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ChatFooter;
