import React, { useEffect, useState } from "react";
import "./Home.css";
import { Link, useNavigate } from "react-router-dom";
import homefig from "../../Images/Home Figure.png";
// import homefig1 from "../../Images/homefig1.png";
// import homefig2 from "../../Images/homefig2.png";
import homefig3 from "../../Images/homefig3.png";
import homefig1 from "../../Images/card.png";
import homefig2 from "../../Images/mobile.png";
import HomeHeader from "../../Layout/HomeHeader";
import Aos from "aos";
import "aos/dist/aos.css";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import toast from "react-hot-toast";
import CustomLoader from "../../CustomComponents/loader/CustomLoader";
import Loader from "../../Component/Loader";

const Home = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(true);
  console.log(email, "email");

  const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  // const passWord = password.length >= 4 && password.length <= 15;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = email.trim() !== "" && password.trim() !== "";
    if (isValid) {
      // toast.success("login successfully")
      if (!email.match(mailFormat)) {
        toast.error("Invalid Email");
      } else {
        const data = {
          emailId: email,
          password: password,
        };
        const res = await HttpClient.requestData("login", "POST", data);
        console.log("resCatsssss", res);
        if (res && res.status) {
          reactLocalStorage.set("userDataNeoCard", res?.data?.token);
          reactLocalStorage.set(
            "userDataNeoCardMemberStatus",
            JSON.stringify({ MemberStatus: res?.data?.memberStatus })
          );
          if (res?.data?.memberStatus == true) {
            toast.success("Login Successfully");
            setEmail("");
            setPassword("");
            // firebase chat set

            navigate("/profile/" + res?.data?.userName?.trim());

            // window.location.reload();
          } else {
            navigate("/NonMemberuserprofile/" + res?.data?.personName?.trim()?.replace(/ /g, "_"));
            toast.success("Login Successfully");
            setEmail("");
            setPassword("");
            // window.location.reload();
          }
        } else {
          toast.error(res?.message);
          // navigate("/login");
        }
      }
      // console.log(first)
    } else {
      toast.error("Please fill all the Inputs..");
    }
  };

  const handleClick = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    Aos.init();
    Aos.refresh();

    setTimeout(() => {
      setShow(false);
    }, 2000);
  }, []);

  return (
    <>
      <section className="homeBtm">
        <div className="cust_container">
          <div className="homeDiv">
            <div className="homeLeft">
              <div className="homeContent">
                <h3 className="homebighead">
                  Welcome To Our Business Community
                </h3>
                <h5 className="homeSmallHead">
                  MEET YOUR CUSTOMERS SMARTLY & BOOST YOUR BUSINESS DIGITALLY
                </h5>
                <h6 className="homepara">
                  We help people connect with opportunities. Join our people
                  accelerator program
                </h6>
              </div>

              <div className="homeForm">
                <h5 className="homeformHead">Login To Your Account</h5>
                <form action="">
                  <div className="form-group">
                    <input
                      type="email"
                      placeholder="Email Id"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group passDiv">
                    <input
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                    />
                    <button
                      type="button"
                      onClick={handleClick}
                      className="passEyebtn"
                    >
                      {showPassword ? (
                        <i class="fa-regular fa-eye"></i>
                      ) : (
                        <i class="fa-regular fa-eye-slash"></i>
                      )}
                    </button>
                  </div>
                  <div className="form-group HomeFormFlex">
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input type="checkbox" />
                      <span>Remember Me</span>
                    </label>
                    <Link to="/forgotPassword" className="HomefrgtPwd">
                      Forgot Password ?
                    </Link>
                  </div>
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="homeloginBtn"
                  >
                    Log In
                  </button>
                </form>
              </div>
            </div>

            {/* <figure className='Homefig' data-aos="fade-right" data-aos-duration="2000">
                            <img src={homefig} alt="img" />
                        </figure> */}

            <div className="homeStruc">
              <div className="homeStrucInn"></div>
              <figure
                className="HomeFigOne homimg"
                data-aos="fade-up-right"
                data-aos-duration="1000"
              >
                <img src={homefig1} alt="img" />
              </figure>
              <figure
                className="HomeFigTwo homimg"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                <img src={homefig2} alt="img" />
              </figure>
              {/* <figure
                  className="HomeFigThree homimg"
                  data-aos="fade-in"
                  data-aos-duration="1500"
                >
                  <img src={homefig3} alt="img" />
                </figure> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
