import React from 'react'
import profpic from "../../Images/profle.png"
import iconpending from "../../Images/Refer and Earn/Under process.png"
import iconsuccess from "../../Images/Refer and Earn/Success.png"

const EarnBalanceTransferDetails = () => {
    const balancetable = [
        {
            id: 1,
            srcimg: profpic,
            name: "Rehmem Khihal",
            position: "Eagle star Metalic furniture",
            desc: "Sharjah, United arab emirates",
            date: "Today 11 AM",
            amount: "$ 27.23",
            status: iconpending,
        },
        {
            id: 2,
            srcimg: profpic,
            name: "Rehmem Khihal",
            position: "Eagle star Metalic furniture",
            desc: "Sharjah, United arab emirates",
            date: "Today 11 AM",
            amount: "$ 27.23",
            status: iconpending,
        },
        {
            id: 3,
            srcimg: profpic,
            name: "Rehmem Khihal",
            position: "Eagle star Metalic furniture",
            desc: "Sharjah, United arab emirates",
            date: "Yesterday 1 PM",
            amount: "$ 27.23",
            status: iconsuccess,
        },
        {
            id: 4,
            srcimg: profpic,
            name: "Rehmem Khihal",
            position: "Eagle star Metalic furniture",
            desc: "Sharjah, United arab emirates",
            date: "Monday 11.05.2024    10 AM",
            amount: "$ 27.23",
            status: iconsuccess,
        },
        {
            id: 5,
            srcimg: profpic,
            name: "Rehmem Khihal",
            position: "Eagle star Metalic furniture",
            desc: "Sharjah, United arab emirates",
            date: "Monday 11.05.2024    10 AM",
            amount: "$ 27.23",
            status: iconsuccess,
        },
        {
            id: 6,
            srcimg: profpic,
            name: "Rehmem Khihal",
            position: "Eagle star Metalic furniture",
            desc: "Sharjah, United arab emirates",
            date: "Monday 11.05.2024    10 AM",
            amount: "$ 27.23",
            status: iconsuccess,
        },
    ];
    return (
        <>
            <section className='EarnBalanceTransfer_Details'>
                <div className='table-responsive'>
                    <table className='table'>
                        {balancetable.map((items, index) => (
                            <tr key={index}>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        <div className='profile_pic'>
                                            <img src={items.srcimg} className='img-fluid' alt='..' />
                                        </div>
                                        <div className='prof_details'>
                                            <h4 className='name'>{items.name}</h4>
                                            <h6 className='position'>{items.position}</h6>
                                            <p className='desc'>{items.desc}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p className='datetime'>{items.date}</p>
                                </td>
                                <td>
                                    <p className='amount'>{items.amount}</p>
                                </td>
                                <td>
                                    <div className='status'>
                                        <img src={items.status} className='img-fluid' alt=".."/>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
            </section>
        </>
    )
}

export default EarnBalanceTransferDetails