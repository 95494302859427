import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginHeader from "../Layout/LoginHeader";
import "./authentication.css";
import image from "../Images/bg.jpg";
import Aos from "aos";
import "aos/dist/aos.css";

import { reactLocalStorage } from "reactjs-localstorage";
import toast from "react-hot-toast";
import HttpClient from "../utils/HttpClient";

const LoginNew = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);

  console.log("email:", email, password);

  const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  // const passWord = password.length >= 4 && password.length <= 15;

  const handleSubmit = async (e) => {
    e.preventDefault();
    // alert("...")

    const isValid = email.trim() !== "" && password.trim() !== "";
    if (isValid) {
      // toast.success("login successfully")
      if (!email.match()) {
        toast.error("Invalid Email");
      } else {
        const data = {
          emailId: email,
          activationCode: password,
        };
        const res = await HttpClient.requestData("activation", "POST", data);
        console.log("resCat", res);
        if (res && res?.status) {
          // reactLocalStorage.set("userDataNeoCard", res?.data?.token);
          toast.success("Activated Successfully");
          navigate("/");
          setEmail("");
          setPassword("");
        } else {
          navigate("/login");
          toast.error("Activation id / code  is incorrect!");
        }
      }
      // console.log(first)
    } else {
      alert("Please enter all fields!");
      toast.error("Please fill all the Inputs..");
    }
  };

  const handleClick = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);
  return (
    <>
      <section className="authenSec">
        <div className="authenSecInn">
          <div className="container">
            <div className="authenSecInnDiv">
              <div className="authenSecForm">
                <div className="authenSecTop">
                  <h4>Hello,</h4>
                  <h4>Welcome!</h4>
                  <p>
                    Enter your Email and activation code to activate your Neoo card
                  </p>
                </div>

                <div className="authenSecBtm">
                  <form action="">
                    <div className="form-group">
                      <input
                        type="email"
                        placeholder="Email Id"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        placeholder="Activation Code"
                        type="text"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <Link className="helpbtn">Help?</Link>
                    </div>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="homeloginBtn"
                    >
                      Activate
                    </button>
                  </form>
                </div>
              </div>
              <div className="authenSecImg">
                <img src={image} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginNew;
