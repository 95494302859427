import React, { useEffect, useState } from "react";
import directory from "../../Images/Frame.png";
import like from "../../Images/framelike.png";
import whatsapp from "../../Images/whatsapp.png";
import call from "../../Images/Call.png";
import { Link } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import CustomLoader from "../../CustomComponents/loader/CustomLoader";
import { useDispatch, useSelector } from "react-redux";
import { getOwnProfile } from "../../Redux/Slice/PostSlice";
import UpgradingModal from "../../Modal/UpgradingModal";
import toast from "react-hot-toast";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const NonMemberuserprofile = () => {
  //update popup start
  const [isPopupOpen, setPopupOpen] = useState(false);

  const [contactdetail, setContactdetail] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [UserMemberData, setUserMemberData] = useState({});
  const [savedContacts, setsavedContacts] = useState([]);

  const dispatch = useDispatch();
  const { UserProfileID, nonMemberDirectoryCountDataLength } = useSelector(
    (state) => state.app
  );

  console.log(
    nonMemberDirectoryCountDataLength,
    "nonMemberDirectoryCountDataLength"
  );
  console.log(UserMemberData, "UserMemberData");
  const handledetailClick = () => {
    setContactdetail(!contactdetail);
  };

  // update popup start
  const openPopup = async () => {
    const res = await HttpClient.requestData("request-upgrade", "GET", {});

    if (res && res?.status) {
      console.log(res, "res");
      toast.success(res?.msg);

      setPopupOpen(!isPopupOpen);
    } else {
      toast.error(res?.msg);
    }
  };
  //update popup end
  const getUserProfile = async () => {
    setisLoading(true);
    const res = await HttpClient.requestData("get-profile", "GET", {});
    console.log(res, "use profiler");
    if (res && res?.status) {
      setisLoading(false);
      setUserMemberData(res?.data);
    } else {
      setisLoading(false);
    }
  };
  //Saved contactlist
  const savedContactList = async () => {
    const res = await HttpClient.requestData("nonmember-contacts", "GET", {});
    console.log(res, "resDataList");
    if (res && res?.status) {
      setsavedContacts(res?.data);
    }
  };
  useEffect(() => {
    savedContactList();
  }, []);
  useEffect(() => {
    getUserProfile();
  }, []);
  useEffect(() => {
    dispatch(getOwnProfile());
  }, []);

  return (
    <>
      <section className="memberuserprofile">
        {/* <CustomLoader loading={isLoading} /> */}
        <SkeletonTheme
          // highlightColor="#67308F"
          duration={1}
        >
          <div className="cust_container">
            {isLoading ? (
              // skeleten start  here
              <div>
                <Skeleton height={200} />
                <div className="col d-flex">
                  <div className="col-2 col-sm-2 ">
                    <Skeleton circle height={100} width={100} />
                  </div>

                  <div className=" col-10 col-sm-10">
                    <Skeleton height={100} style={{ width: "100%" }} />
                  </div>
                </div>
                <Skeleton height={25} count={5} className="col" />
                <Skeleton height={100} />
              </div> // skeleten end  here
            ) : (
              <div>
                <div className="contactbg">
                  <div className="contctbrdr">
                    <div className="cntntflx">
                      <div className="mbrtusrprflflx">
                        <div className="imgbrdrmbrt">
                          <img
                            src={
                              "https://st.depositphotos.com/2934765/53192/v/450/depositphotos_531920820-stock-illustration-photo-available-vector-icon-default.jpg"
                            }
                            className="img-fluid"
                            alt="..."
                          />
                        </div>
                        <div className="jhjmbmrtdiv">
                          <div className="d-flex align-items-center ">
                            <p className="jhjtcfggytxt">
                              {UserMemberData?.gender}{" "}
                              {UserMemberData?.personName}
                            </p>
                            <p className="mghtxtpp">
                              {UserMemberData?.designation}
                            </p>
                          </div>
                          <h5 className="companyname">
                            {UserMemberData?.companyName}
                          </h5>
                          <h6 className="digitalmarketing">
                            {UserMemberData?.TypeOfCompany}
                          </h6>
                          <p className="tel_no">
                          Mobile:{" "}
                            <span>
                              {" "}
                              {UserMemberData?.phone_code}&nbsp;
                              {UserMemberData?.mobileNo}
                            </span>{" "}
                            /{" "}
                            <span>
                              {UserMemberData?.emirate} ,{" "}
                              {UserMemberData?.country}
                            </span>
                          </p>
                          <div className="review_wrap">
                            <p className="like mr-2">
                              Likes: {UserMemberData?.likeCount}
                            </p>
                            <p className="follower mr-2">Followers: 000</p>
                            <div className="star">
                              {/* <i className="fa-solid fa-star active"></i> */}
                              <i className="fa-solid fa-star "></i>
                              <i className="fa-solid fa-star "></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="upbtndivmain">
                        <button className="upbtndiv" onClick={openPopup}>
                          Upgrade
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="contactbg">
                  <div className="drctryDiv">
                    <div className="drctryDivLeft">
                      <div className="d-flex align-items-center">
                        <figure className="drctryDivFig">
                          <img src={like} alt="img" />
                        </figure>
                        <p>{UserMemberData?.likeCount}</p>
                      </div>

                      <Link to="/Owncontactlist">
                        <div className="d-flex align-items-center">
                          <figure className="drctryDivFig">
                            <img src={directory} alt="img" />
                          </figure>
                          <p>{savedContacts.length}</p>
                        </div>
                      </Link>
                    </div>

                    <div className="drctryDivRght">
                      <figure className="drctrCntctIcon">
                        <img src={whatsapp} alt="img" />
                      </figure>

                      <figure className="drctrCntctIcon responsiveshow">
                        <img src={call} alt="img" />
                      </figure>
                    </div>
                  </div>
                </div>

                <div className="contactbg">
                  <div className="cntntbrdr">
                    <div className="flxcntnt">
                      <p className="cntncttxt">Contact Details</p>
                      <div className="ArrwiconDiv" onClick={handledetailClick}>
                        <span
                          className={
                            contactdetail
                              ? "fa-solid fa-chevron-up"
                              : "fa-solid fa-chevron-down"
                          }
                        ></span>
                      </div>
                    </div>
                    {contactdetail && (
                      <div className="">
                        <div className="mt-3">
                          <p className="egltxtdiv">
                            {UserMemberData?.companyName}
                          </p>
                          <p className="mdlfrntr">
                            {UserMemberData?.TypeOfCompany}
                          </p>
                          <p className="ajmtxt">
                            {UserMemberData?.emirate} |{" "}
                            {UserMemberData?.country}
                          </p>
                        </div>
                        <div className="divcnctflx non_member">
                          <div className="brdrdivdtls">
                            <span className="txtdtlmn">
                              <i className="fa-solid fa-phone"></i>
                              +971
                            </span>
                          </div>

                          <div className="brdrdivdtls">
                            <span className="txtdtlmn">
                              <i className="fa-solid fa-mobile-screen-button"></i>
                              {UserMemberData?.phone_code} &nbsp;
                              {UserMemberData?.mobileNo}
                            </span>
                          </div>

                          <div className="brdrdivdtls">
                            <span className="txtdtlmn">
                              <i className="fa-brands fa-whatsapp"></i>
                              {UserMemberData?.whats_code} &nbsp;
                              {UserMemberData?.whatsappNo}
                            </span>
                          </div>

                          <div className="brdrdivdtls">
                            <span className="txtdtlmn">
                              <i className="fa-solid fa-envelope"></i>
                              {UserMemberData?.emailId}
                            </span>
                          </div>

                          <div className="brdrdivdtls">
                            <span className="txtdtlmn">
                              <i className="fa-solid fa-globe"></i>
                              www.demo.com
                            </span>
                          </div>

                          <div className="brdrdivdtls">
                            <span className="txtdtlmn">
                              <i className="fa-solid fa-location-dot"></i>
                              {/* Dubai. United Arab Emirates  */}
                              {UserMemberData?.emirate}.{" "}
                              {UserMemberData?.country}
                            </span>
                          </div>
                        </div>
                        <div className="dibmailflx">
                          {/* <div className="envelopdiv">
                    <span className="txtdtlmn">
                      <i className="fa-solid fa-envelope"></i>
                      {UserMemberData?.emailId}
                    </span>
                  </div> */}
                          {/* <div className="envelopdiv">
                    <span className="txtdtlmn">
                      <i className="fa-solid fa-globe"></i>
                      www.neoocard.com
                    </span>
                  </div> */}
                        </div>
                        {/* <div className="divloctin">
                  <span className="txtdtlmn">
                    <i className="fa-solid fa-location-dot"></i>
                    Business Center, Sharjah Publishing City Free Zone Dubai.
                    United Arab Emirates
                  </span>
                </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </SkeletonTheme>
      </section>

      {/*****UpgradingModal******/}

      {isPopupOpen && (
        <UpgradingModal closePopup={setPopupOpen} isPopupOpen={isPopupOpen} />
      )}
    </>
  );
};

export default NonMemberuserprofile;
