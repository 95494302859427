import React, { useEffect, useState } from "react";
import HomeHeader from "./HomeHeader";
import { Navigate, Outlet } from "react-router-dom";
import Footer from "./Footer";
import Loader from "../Component/Loader";
import HttpClient from "../utils/HttpClient";
import LandingPage from "../View/Homepage/LandingPage";

const HomeLayout = () => {
  const [load, setLoad] = useState(true);
  setTimeout(() => {
    setLoad(false);
  }, 2000);

  const userToken = localStorage.getItem("userDataNeoCard");
  const userMemberStaus = JSON.parse(
    localStorage.getItem("userDataNeoCardMemberStatus")
  );
  const [UserMemberData, setUserMemberData] = useState({});

  console.log(UserMemberData, "UserMemberData");
  console.log(userToken, "userTokenwewewe");
  console.log(userMemberStaus, "userMemberStaussdsdwds");

  const getUserProfile = async () => {
    // setisLoading(true);
    const res = await HttpClient.requestData("get-profile", "GET", {});
    console.log(res, "user profile");
    if (res && res?.status) {
      // setisLoading(false);
      setUserMemberData(res?.data);
    } else {
      // setisLoading(false);
    }
  };
  useEffect(() => {
    getUserProfile();
  }, []);

  //   return (
  //     <>
  //       {load ? (
  //         <Loader />
  //       ) : (
  //         <div>
  //           <HomeHeader />
  //           <Outlet />
  //         </div>
  //       )}
  //     </>
  //   );

  if (load) {
    return <Loader />;
  } else {
    if (userToken && Object.keys(UserMemberData)?.length !== 0) {
      if (userMemberStaus?.MemberStatus) {
        return <Navigate to={`profile/${UserMemberData?.userName?.trim()}`} />;
      } else {
        return (
          <Navigate
            to={`NonMemberuserprofile/${UserMemberData?.personName
              ?.trim()
              .replace(/ /g, "_")}`}
          />
        );
      }
    } else {
      return (
        <div>
          <HomeHeader />
          {/* <LandingPage /> */}
          <Outlet />
          <Footer />
        </div>
      );
    }
  }
};

export default HomeLayout;
