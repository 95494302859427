import React from 'react'
import icon1 from "../../Images/Refer and Earn/get1.png";
import icon2 from "../../Images/Refer and Earn/get2.png";
import icon3 from "../../Images/Refer and Earn/get3.png";
import icon4 from "../../Images/Refer and Earn/get4.png";
import icon5 from "../../Images/Refer and Earn/get5.png";

const HowToGET = () => {
    const HowToGETcard =[
      {id: 1, srclink: icon1, name: 'Share Link to Friends', desc: "Tips：Share to groups and reach your cash rewards faster", },
      {id: 2, srclink: icon2, name: 'The invitee fills in your referral code with details for registration. ', },
      {id: 3, srclink: icon3, name: 'The invitee who purchases the smart business pack claims the new user reward.', },
      {id: 4, srclink: icon4, name: 'Get your cash reward', },
      {id: 5, srclink: icon5, name: 'Get your extra rewards when you add more contacts.', },
    ];
    return (
        <>
            <section className='HowToGET_sec'>
                <h4 className='head'>How to GET</h4>

                <div className='HowToGET_allcard'>
                    {HowToGETcard.map((items, index) => (
                        <div className='HowToGET_card' key={index}>
                            <div className='d-flex align-items-center mb-2'>
                                <div className='icon'>
                                    <img src={items.srclink} className='img-fluid' alt='...' />
                                </div>
                                <span className='progressbar'></span>
                            </div>
                            <p className='name'>{items.name}</p>
                            <p className='desc'>{items.desc}</p>
                        </div>
                    ))}
                </div>

                <h4 className='head mt-5'>Rules</h4>
                <hr/>
                <ul className='list'>
                    <li>1. Each Neoo search earning task is valid for 30 days after which it expires. When the task expires, if you haven't collected your reward by clicking on "Withdraw", your reward amount will be automatically withdrawn and added to your bank  account balance.</li>
                    <li>2. After the task expires, all of your invitees details for that particular task will be cleared out. If your invitees have not signed up yet, you may re-invite them on a new earning task.</li>
                    <li>3. If the status of your invitee on the invitees list is marked as "Reassigned", you will not get the reward for this invitee because the invitee has successfully registered to Neoo search  through another referral feature at the same time as with your cash spree earning link.</li>
                    <li>4. Adding non-members will not get you cash rewards.</li>
                </ul>

            </section>
        </>
    )
}

export default HowToGET