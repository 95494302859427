import React, { useEffect, useState } from "react";
import qrimg from "../Images/Rectangle.png";
import QRprofileShare from "./QRprofileShare";
import { useDispatch, useSelector } from "react-redux";
import { getOwnProfile } from "../Redux/Slice/PostSlice";

const QRshareModal = ({ closeModal, Qrimage }) => {
  const [modal, setModal] = useState(false);
  const { UserProfileID } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  console.log(Qrimage, "onClick={handleDownload}");
  console.log(UserProfileID, "UserProfileID");

  const handleClick = () => {
    setModal(!modal);
  };
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = Qrimage;
    link.download = Qrimage;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    dispatch(getOwnProfile());
  }, []);

  return (
    <>
      <section className="QRsharemodalSec" >
        <div className="qrshareInn">
          <div className="ModClosebtn" onClick={() => closeModal()}>
            <i class="fa-solid fa-xmark"></i>
          </div>
          <figure className="modQRimg">
            <img src={Qrimage} alt="QR" />
          </figure>
          <button className="qrDwnld" onClick={handleDownload}>
            Download
          </button>
          {/* <button className="modshareBtn" onClick={handleClick}>
            Share <i class="fa-regular fa-share-from-square"></i>
          </button> */}
        </div>
      </section>
      {modal && <QRprofileShare closeProfile={setModal} Qrimage={Qrimage}/>}
    </>
  );
};

export default QRshareModal;
