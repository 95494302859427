import React from 'react'

const PeopleAcceleratorBanner = () => {
    return (
        <>
            <section className='PeopleAcceleratorBanner'>
                <div className='cust_container'>
                    <div className='content'></div>
                </div>
            </section>
        </>

    )
}

export default PeopleAcceleratorBanner