import React, { useState, useEffect } from "react";
import logootherblm from "../../src/Images/logootherblm.png";
import {
  ref,
  onValue,
  update,
  push,
  set,
  child,
  increment,
} from "firebase/database";
import { db } from "../Firebase/FirebaseConfig";
import moment from "moment";

const Forwardmessagemodal = ({
  closemodal,
  OwnId,
  forwardMessage,
  setForwardMessage,
}) => {
  const [totalChatListData, setTotalChatListData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [warning, setWarning] = useState("");

  const fetchChatUserFromFirebase = async () => {
    const chatListRef = ref(db, `chatList/${OwnId}`);
    onValue(chatListRef, (snapshot) => {
      if (snapshot.exists()) {
        const chatListData = snapshot.val();
        console.log("ChatList : ", JSON.stringify(chatListData));
        const formattedChatList = Object.entries(chatListData).map(
          ([chatroomID, chatDetails]) => ({
            chatroomID,
            ...chatDetails,
          })
        );
        setTotalChatListData(formattedChatList);
      }
    });
  };

  useEffect(() => {
    fetchChatUserFromFirebase();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedMembers((prev) => [...prev, value]);
    } else {
      setSelectedMembers((prev) => prev.filter((member) => member !== value));
    }
  };

  const handleSend = async () => {
    if (selectedMembers.length === 0) {
      setWarning("Please select at least one user.");
      return;
    }

    try {
      let forwardMsgData = {
        content: forwardMessage.content,
        senderID: OwnId,
        receiverID: "",
        timestamp: moment().format("MMMM DD, YYYY"),
        time: moment().format("HH:mm"),
        dateTime: moment().format(),
        seen: false,
        type: forwardMessage.type,
      };

      if (forwardMessage.type !== "text") {
        forwardMsgData.type = forwardMessage.type;
        if (forwardMessage.fileUrl) {
          forwardMsgData.fileUrl = forwardMessage.fileUrl;
        }
      }

      console.log("ForwardMessage : ", JSON.stringify(forwardMsgData));

      for (const memberId of selectedMembers) {
        const receiverID = memberId
          .replace(OwnId, "")
          .replace(/^-/, "")
          .replace(/^-/, "");

        if (!receiverID) {
          continue;
        }

        const chatroomRef = ref(db, `chatrooms/${memberId}`);
        const messagesRef = child(chatroomRef, "messages");

        forwardMsgData.receiverID = receiverID;

        const newMessageRef = push(messagesRef);
        await set(newMessageRef, forwardMsgData);

        const updates = {};
        updates[`chatList/${OwnId}/${receiverID}/lastMessage`] =
          forwardMsgData.content;
        updates[`chatList/${OwnId}/${receiverID}/lastMessageTimestamp`] =
          forwardMsgData.dateTime;
        updates[`chatList/${OwnId}/${receiverID}/lastMessaged`] = OwnId;
        updates[`chatList/${OwnId}/${receiverID}/seenCount`] = 0;
        updates[`chatList/${OwnId}/${receiverID}/type`] = forwardMsgData.type;

        updates[`chatList/${receiverID}/${OwnId}/lastMessage`] =
          forwardMsgData.content;
        updates[`chatList/${receiverID}/${OwnId}/lastMessageTimestamp`] =
          forwardMsgData.dateTime;
        updates[`chatList/${receiverID}/${OwnId}/lastMessaged`] = OwnId;
        updates[`chatList/${receiverID}/${OwnId}/seenCount`] = increment(1);
        updates[`chatList/${receiverID}/${OwnId}/type`] = forwardMsgData.type;
        await update(ref(db), updates);
      }

      setForwardMessage(null);
      closemodal();
    } catch (error) {
      console.error("Error sending message:", error);
      setWarning("An error occurred while sending the message.");
    }
  };

  const filteredChatList = totalChatListData.filter((chat) =>
    (chat.userName || "").toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getUserNameById = (id) => {
    const chat = totalChatListData.find((chat) => chat.chatroomID === id);
    return chat ? chat.userName : id;
  };

  return (
    <div className="productmodal">
      <div className="productmodalbgmain">
        <div className="productmodalbgmaintopo">
          <div className="creossiodfottcoppdivflx">
            <div className="creossiodofdiv" onClick={() => closemodal()}>
              <i className="fa-solid fa-xmark"></i>
            </div>
            <div className="forrwtrsymmetsttcoppdibv">
              <p className="forrwtrsymmetsttcopp">Forward Message To</p>
            </div>
          </div>
          <div className="serachjforwatdinputddivmain">
            <div className="serachjforwatdinputddiv">
              <input
                type="text"
                className="serachjforwatdinput"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search..."
              />
              <div className="serchjhdfijjfimngiocon">
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="productmodalbgmainbttom">
          <p className="recetbebtchattsttxtpp">RECENT CHATS</p>

          <div className="productmodalbgmainbttommaoin">
            {filteredChatList.map((chat, index) => (
              <div key={index} className="productmodalbgmainbttommaoinflx">
                <div className="inpouytcgeddfijuv">
                  <input
                    type="checkbox"
                    id={chat.chatroomID}
                    name={chat.userName}
                    value={chat.chatroomID}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div className="forwtardprofolipic">
                  <img
                    src={chat.userProfilePicture || logootherblm}
                    alt={chat.userName}
                  />
                </div>
                <div>
                  <p className="memmdmttxonnedttxtpp">{chat.userName}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="productmodalbgmainfooter">
          {warning && <p className="warning-text">{warning}</p>}
          <div className="forwarerdmodalmessgediv">
            <p className="messageforrsrwrdtxtp">
              {forwardMessage ? forwardMessage.content : ""}
            </p>
          </div>

          <div className="foriwdtrpersinedidivijcionflx">
            <div className="foriwdtrpersinedimain">
              <div className="foriwdtrpersinedimaindklckflx">
                {selectedMembers.map((member, index) => (
                  <p key={index} className="foriwdtrpersinedimaintspp">
                    {getUserNameById(member)}
                  </p>
                ))}
              </div>
            </div>
            <div className="fooeowrrdiodnnfiv" onClick={handleSend}>
              <i className="fa-solid fa-paper-plane"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forwardmessagemodal;
