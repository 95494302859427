import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ref, onValue, update, child, get, remove } from "firebase/database";
import { db } from "../../Firebase/FirebaseConfig";
import dot from "../../Images/dot.png";
import logootherblm from "../../Images/logootherblm.png";
import imageIcon from "../../Images/cameraIcon.png";
import videoIcon from "../../Images/videoIcon.png";
import fileIcon from "../../Images/fileIcon.png";
import {
  OnetoOneChatIdname,
  chatList,
  SingleChat,
} from "../../Redux/Slice/Chat";
import {
  getOwnProfile,
  getSingleUserProfile,
} from "../../Redux/Slice/PostSlice";

const TotalChatList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { UserProfileID } = useSelector((state) => state.app);
  const { chatThreads, RecieverID } = useSelector((state) => state.chat);
  const [chatID_set, setchatID_set] = useState("");
  const [TotalChatListData, setTotalChatListData] = useState([]);
  const [isOpenmenu, setIsOpenmenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [userStatuses, setUserStatuses] = useState({});
  const [selectedButton, setSelectedButton] = useState("All");

  const OwnId = UserProfileID?._id;

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredChatList = TotalChatListData.filter((items) => {
    // console.log("ITEM2 : ", JSON.stringify(items));
    const isArchived = items?.isArchived ?? false;
    const isBlocked = items?.isBlocked ?? false;
    const matchesSearchTerm = items?.userName
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());

    if (selectedButton === "All") {
      return !isArchived && !isBlocked && matchesSearchTerm;
    } else if (selectedButton === "Archive") {
      return isArchived && !isBlocked && matchesSearchTerm;
    } else if (selectedButton === "Blocked") {
      return isBlocked && matchesSearchTerm;
    }

    return false;
  }).sort((a, b) => {
    const dateA = new Date(a.lastMessageTimestamp);
    const dateB = new Date(b.lastMessageTimestamp);
    return dateB - dateA;
  });

  const togglesubmenu = () => {
    setIsOpenmenu(!isOpenmenu);
  };

  const handleClick = (items) => {
    console.log("items?.chatroomID : ", items?.chatroomID);
    console.log("items?.remoteId : ", items?.remoteId);
    setchatID_set(items?.chatroomID);
    dispatch(OnetoOneChatIdname(items?.remoteId));
    navigate("/chat");
    if (window.innerWidth < 767) {
      dispatch(chatList(false));
      dispatch(SingleChat(true));
    }
  };

  // Fetch chat users directly from Firebase
  const fetchChatUserFromFirebase = async () => {
    console.log("Fetching data from Firebase...");
    const chatListRef = ref(db, `chatList/${OwnId}`);
    onValue(chatListRef, (snapshot) => {
      if (snapshot.exists()) {
        const chatListData = snapshot.val();
        console.log("Formate  : ", OwnId, JSON.stringify(chatListData));
        const formattedChatList = Object.entries(chatListData).map(
          ([chatroomID, chatDetails]) => ({
            chatroomID,
            ...chatDetails,
          })
        );
        setTotalChatListData(formattedChatList);
        console.log(
          "Fetched chat list data from Firebase:",
          JSON.stringify(formattedChatList)
        );
      } else {
        console.log("No data available in Firebase");
      }
    });
  };

  const archiveChat = (chatId, archiveValue) => {
    if (typeof chatId !== "string") {
      console.error("Invalid chatId:", chatId);
      return;
    }

    const chatRef = ref(db, `chatList/${OwnId}/${chatId}`);

    update(chatRef, { isArchived: archiveValue })
      .then(() => {
        console.log("Chat archived successfully");
        fetchChatUserFromFirebase();
      })
      .catch((error) => {
        console.error("Error archiving chat:", error);
      });
  };

  // const blockedChat = (chatId, block) => {
  //   if (typeof chatId !== "string") {
  //     console.error("Invalid chatId:", chatId);
  //     return;
  //   }

  //   const chatRef = ref(db, `chatList/${OwnId}/${chatId}`);

  //   // Update data based on the block action
  //   const updateData = block
  //     ? { isBlocked: true, whoBlocked: OwnId }
  //     : { isBlocked: false, whoBlocked: null };

  //   update(chatRef, updateData)
  //     .then(() => {
  //       console.log("Chat blocked/unblocked successfully");
  //       fetchChatUserFromFirebase();
  //     })
  //     .catch((error) => {
  //       console.error("Error updating chat:", error);
  //     });
  // };

  const deleteChatForUser = (chatroomID) => {
    const chatroomRef = ref(db, "chatrooms/" + chatroomID);
    const messagesRef = child(chatroomRef, "messages");

    get(messagesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const messages = snapshot.val();
          Object.keys(messages).forEach((key) => {
            const messageRef = child(messagesRef, key);
            update(messageRef, {
              deletedFor: [...(messages[key].deletedFor || []), OwnId],
            });
          });
        }
      })
      .catch((error) => {
        console.error("Error deleting messages: ", error);
      });
  };

  const deleteAllChats = async (chatroomId, remoteId) => {
    try {
      const chatListRef = ref(db, `chatList/${OwnId}/${remoteId}`);

      deleteChatForUser(chatroomId);
      // await update(chatListRef, {
      //   lastMessage: null,
      //   lastMessageTimestamp: null,
      //   lastMessaged: null,
      // });

      await remove(chatListRef);

      console.log(
        `All chats in chatroom ${chatroomId} and last message have been deleted.`
      );
    } catch (error) {
      console.error("Error deleting all chats and last message:", error);
    }
  };

  const deleteChat = async (remoteId) => {
    try {
      const chatroomId = TotalChatListData.find(
        (chat) => chat.remoteId === remoteId
      )?.chatroomID;

      if (chatroomId) {
        await deleteAllChats(chatroomId, remoteId);

        setTotalChatListData((prevData) =>
          prevData.filter((chat) => chat.remoteId !== remoteId)
        );

        dispatch(OnetoOneChatIdname(""));
        navigate("/chat");

        console.log(`Chat with chatroomId ${chatroomId} has been deleted.`);
      } else {
        console.error("Chatroom ID not found for remoteId:", remoteId);
      }
    } catch (error) {
      console.error("Error deleting chat:", error);
    }
  };

  useEffect(() => {
    console.log("UserProfileID:", UserProfileID);
    dispatch(getOwnProfile());
    if (OwnId) {
      fetchChatUserFromFirebase();
    }
  }, [OwnId]);

  useEffect(() => {
    console.log("RecieverID changed:", RecieverID);
    if (RecieverID) {
      dispatch(getSingleUserProfile(RecieverID));
    }
  }, [RecieverID]);

  useEffect(() => {
    console.log(
      "TotalChatListData updated:",
      JSON.stringify(TotalChatListData)
    );
  }, [TotalChatListData]);

  useEffect(() => {
    const statusRef = ref(db, `status`);
    onValue(statusRef, (snapshot) => {
      if (snapshot.exists()) {
        const statusData = snapshot.val();
        const updatedStatuses = {};

        TotalChatListData.forEach((item) => {
          const userId = item.remoteId;
          const userStatus = statusData[userId] || {};
          updatedStatuses[userId] = {
            online: userStatus.state === "online",
            lastSeen: userStatus.last_seen_time,
            lastChanged: userStatus.last_changed,
          };
        });

        setUserStatuses(updatedStatuses);
      } else {
        console.log("No status data available.");
      }
    });
  }, [TotalChatListData]);

  return (
    <div className="left-container">
      <div className="header">
        <div className="archivebtn_wrap">
          <button
            className="btn"
            onClick={() => handleButtonClick("All")}
            style={{
              backgroundColor:
                selectedButton === "All" ? "rgb(103, 48, 143)" : "silver",
              color: selectedButton === "All" ? "#fff" : "#000",
            }}
          >
            All
          </button>
          <button
            className="btn"
            onClick={() => handleButtonClick("Archive")}
            style={{
              backgroundColor:
                selectedButton === "Archive" ? "rgb(103, 48, 143)" : "silver",
              color: selectedButton === "Archive" ? "#fff" : "#000",
            }}
          >
            Archive
          </button>
          <button
            className="btn"
            onClick={() => handleButtonClick("Blocked")}
            style={{
              backgroundColor:
                selectedButton === "Blocked" ? "rgb(103, 48, 143)" : "silver",
              color: selectedButton === "Blocked" ? "#fff" : "#000",
            }}
          >
            Blocked
          </button>
        </div>
        {/* <div className="nav-icons">
          <li onClick={togglesubmenu}>
            <i className="fa-solid fa-ellipsis-vertical" />
            {isOpenmenu && (
              <div className="dropsubmenu_box">
                <ul className="list">
                  <li>
                    <button className="btn">Archive contacts</button>
                  </li>
                  <li>
                    <button className="btn">Blocked contacts</button>
                  </li>
                </ul>
              </div>
            )}
          </li>
        </div> */}
      </div>

      <div className="search-container">
        <i className="fa-solid fa-magnifying-glass" />
        <input
          type="text"
          placeholder="Search or start new chat"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <i className="fa-sharp fa-solid fa-bars-filter" />
      </div>

      <div className="chat-list">
        {filteredChatList.map((items, index) => {
          // console.log("ITEM : ", JSON.stringify(items));
          const unseenMessagesCount = items?.seenCount || 0;
          const isUserMessage = items?.lastMessaged === OwnId;
          const remoteId = items?.remoteId == items?.lastMessaged;

          return (
            <div
              className={
                chatID_set === items?.chatroomID
                  ? "chat-box active"
                  : "chat-box"
              }
              key={index}
              onClick={() => handleClick(items)}
            >
              <div
                className="img-box"
                style={{
                  border: userStatuses[items?.remoteId]?.online
                    ? "2px solid #4aad29" // Border color for online users
                    : "none", // No border for offline users
                }}
              >
                {items?.userName == "Admin" ? (
                  <img
                    className="img-cover"
                    src={require("../../Images/AdminImage/Admin_chat logo.png")}
                    alt={`${items?.userName}'s profile`} // Improved alt text for accessibility
                  />
                ) : (
                  <img
                    className="img-cover"
                    src={items?.userProfilePicture || logootherblm}
                    alt={`${items?.userName}'s profile`} // Improved alt text for accessibility
                  />
                )}
              </div>
              <div className="chat-details">
                <div className="text-head">
                  <h4>{items?.userName ? items?.userName : "NO USER"}</h4>
                  <div className="onhover_btn">
                    <i className="fa-solid fa-ellipsis"></i>
                    <div className="dropsubmenu_box">
                      <ul className="list">
                        <li>
                          <button
                            className="btn"
                            onClick={() =>
                              archiveChat(
                                items?.remoteId,
                                items.isArchived == false ||
                                  items.isArchived == undefined
                                  ? true
                                  : false
                              )
                            }
                          >
                            {items.isArchived
                              ? "Remove from Archive"
                              : "Move to Archive"}
                          </button>
                        </li>
                        {/* <li>
                          <button className="btn">View profile</button>
                        </li> */}
                        {/* <li>
                          {items.isBlocked ? (
                            items.whoBlocked === OwnId ? (
                              <button
                                onClick={() =>
                                  blockedChat(items?.remoteId, false)
                                } // Unblock chat
                                className="btn"
                              >
                                Remove from Block chat
                              </button>
                            ) : null // If blocked by someone else, no button is shown
                          ) : (
                            <button
                              onClick={() => blockedChat(items?.remoteId, true)} // Block chat
                              className="btn"
                            >
                              Block chat
                            </button>
                          )}
                        </li> */}
                        <li>
                          <button
                            className="btn"
                            onClick={() => deleteChat(items?.remoteId)}
                          >
                            Clear chat
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="chat-message-sidebar-section">
                  {items?.type == "image" ? (
                    <div className="chat-message-image">
                      <img src={imageIcon} alt="image" />
                      <p
                        className={`time ${
                          unseenMessagesCount > 0 && !isUserMessage
                            ? "chat-purple-text"
                            : "chat-black-text"
                        } chat-truncated`}
                      >
                        Image
                      </p>
                    </div>
                  ) : items?.type == "video" ? (
                    <div className="chat-message-image">
                      <img src={videoIcon} alt="image" />
                      <p
                        className={`time ${
                          unseenMessagesCount > 0 && !isUserMessage
                            ? "chat-purple-text"
                            : "chat-black-text"
                        } chat-truncated`}
                      >
                        Video
                      </p>
                    </div>
                  ) : items?.type == "file" ? (
                    <div className="chat-message-image">
                      <img src={fileIcon} alt="image" />
                      <p
                        className={`time ${
                          unseenMessagesCount > 0 && !isUserMessage
                            ? "chat-purple-text"
                            : "chat-black-text"
                        } chat-truncated`}
                      >
                        File
                      </p>
                    </div>
                  ) : (
                    <p
                      className={`time ${
                        unseenMessagesCount > 0 && !isUserMessage
                          ? "chat-purple-text"
                          : "chat-black-text"
                      } chat-truncated`}
                    >
                      {items?.lastMessage?.length > 20
                        ? `${items?.lastMessage.substring(0, 20)}...`
                        : items?.lastMessage}
                    </p>
                  )}

                  {unseenMessagesCount > 0 && !isUserMessage && (
                    <div className="chat-unseen-count">
                      {/* <img src={dot} /> */}
                      <p>{unseenMessagesCount}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TotalChatList;
