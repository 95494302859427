import React, { useState } from 'react'
import { Rating } from 'react-simple-star-rating';
import ratingimgg from "../../Images/ratingimgg.png"
const Othertaptorate = () => {
    const [rating, setRating] = useState(0)
    const handleRating = () => {
        setRating()
    }
    return (
        <div className='othertaptoratemain'>
            <div className="starratingbratdivflxsgivebtn">

                <div className='starratingothedifiub'>
                    {/* set initial value */}
                    <Rating onClick={handleRating} initialValue={rating} size={20} />
                </div>
                <button className='ratingffsgivebtn'>
                    <div className='ratingffsgivebtnimgnain'>
                        <img src={ratingimgg} alt='...' />
                    </div>
                    Tap to Rate
                </button>
            </div>
        </div>
    )
}

export default Othertaptorate
