import React, { useContext } from 'react'
import HomeSearchheader from './HomeSearchheader'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import ColorContext, { Colorcontext } from '../Component/Colorcontext/ColorContext'
import FixedFooter from './FixedFooter'

const HomeSearchLayout = () => {
  const colorData = useContext(Colorcontext);
  return (
    <ColorContext>
     <>
         <HomeSearchheader/>
         <Outlet/>
         <Footer/>
         <FixedFooter />
     </>
     </ColorContext>
  )
}

export default HomeSearchLayout
