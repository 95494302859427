import React from 'react'
import { Link } from 'react-router-dom'

const WithdrawTopHeading = () => {
    return (
        <>
            <div className='WithdrawTop_Heading'>
                <div className='d-flex align-items-center'>
                    <Link to="/refer-earn-dashboard" className='back_btn'>
                        <i className="fa-solid fa-arrow-left-long"></i>
                    </Link>
                    <h2 className='title'>Withdraw</h2>
                </div>
            </div>
        </>
    )
}

export default WithdrawTopHeading