import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const FrequentlyAskedQuestions = () => {
    const Frequently = [
        {
            id: 1,
            name: "What is Neoo Search? Why business pack?",
            desc: "Your products and services need to attract attention, so create profiles and share them with current customers and your target audience in the right way. Innovation is advertised as much as new products. For that, and to meet new people and get instant connections, neoo search will be very useful for you.",
            desc2: "The business pack includes our NFC technology products like smart business cards, smart table talkers, and smart directories to help companies grow.",
        },
        {
            id: 2,
            name: "What is included in business pack? How much price?",
            
        },
        {
            id: 3,
            name: "Who can use Neoo Search's Business Pack?",
            desc: "Neoo Search's Business Pack is useful for all business people as it can create a personal and company profile, especially for business owners, entrepreneurs, marketing, sales, digital marketing, media teams, freelancers, influencers, and B2B and B2C marketing. Every business needs promotion.",
        },
        {
            id: 4,
            name: "Who can Refer?",
            desc: "You can refer company owners, entrepreneurs, sales and marketing, digital marketing, business development, and others who want to grow and promote their businesses. If you're a Neoo Search Premium Member, you can refer a friend or family member to help them get the same impact you've had. If you haven't taken the program yourself, you can still refer anyone you think would benefit from it or anyone who wants to grow with this skill. You can refer college students, college freshmen, and working professionals. ",
        },
        {
            id: 5,
            name: "I am not a Neoo search member; can I still refer my friends?",
            desc: "Only Neoo Search members can refer others. Once you purchase the Neoo Search Business Pack as a member, you will receive our training and use your unique referral link to refer anyone you want.",
        },
        {
            id: 6,
            name: "How much can I earn?",
            desc: "You get AED 100 for each member you add, so if you add 30 members in a month, you can get AED 3000; if you add 50 members, you can get AED 5000 + 500 (10%). Extra, if you add 100 members, you can get AED 10000 + 1500 (15%). Get Extra",
        },
        {
            id: 7,
            name: "When will I get paid?",
            desc: "When will I get paid? You will get cash rewards for every successful registration for membership through your referral link or referral code. You can see reward cash in your wallet, and from there, you can send it to your bank account. Payouts can be done after a minimum of three registrations.",
        },
        {
            id: 8,
            name: "How will I track my referrals?",
            desc: "You can easily track all your referrals from the dashboard. When a person whom you referred signs up or purchases on the Neoo search business pack, you will be able to see the same on your Neoo search dashboard. You can see an accurate count of total sign-ups and registrations from your referrals, as well as your rewards redemption history.",
        },
        {
            id: 9,
            name: "When can I expect to receive my referral reward?",
            desc: "You will receive your referral rewards within 7–10 working days after your friend registers for the purchase using your unique referral link.",
        },
    ];
    return (
        <>
            <section className='FrequentlyAskedQuestions_sec'>
                <div className='cust_container'>
                    <h2 className='heading'>Frequently asked questions</h2>
                    <p className='text'>We have answered some of the frequently asked questions for you!</p>



                    {/***Accordion start*/}
                    <Accordion>
                        {Frequently.map((items, index) => (
                            <AccordionItem key={index}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <p>{items.name}</p>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>{items.desc}</p>
                                    <p>{items.desc2}</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>




                </div>
            </section>
        </>
    )
}

export default FrequentlyAskedQuestions