import React, { useEffect, useState } from "react";
import "./Modal.css";
import HttpClient from "../utils/HttpClient";
import toast from "react-hot-toast";
// import prdctimg from "../../src/Images/prdctimg.png";
// import HttpClient from "../utils/HttpClient";

const ProductModal = ({ closemodal, ProductItem }) => {
  console.log(ProductItem, "ProductItem");

  // const productPoint = async () => {
  //   if (modala) {
  //     const res = await HttpClient.requestData(
  //       "getting-rewards",
  //       "POST",
  //       productViewPoint
  //     );
  //     if (res && res?.status) {
  //       toast.success("Viewing Product...");
  //     } else {
  //       console.log("Cant add points");
  //     }
  //   }
  // };
  // useEffect(() => {
  //   productPoint()
  // }, [modala])

  return (
    <>
      <div className="productmodal">
        {ProductItem && (
          <div className="productmodalcontent">
            <div className="icklohjj" onClick={() => closemodal()}>
              <i
                className="fa-solid fa-xmark"
                style={{ cursor: "pointer" }}
              ></i>
            </div>
            <div className="">
              <div className="prcdgimgj">
                <img src={ProductItem?.image} alt="..." />
              </div>
              <div className="divtstxmodal">
                <h4 className="prdcttctx">{ProductItem?.name}</h4>
                <p className="unjktxtt">{ProductItem?.description}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductModal;
