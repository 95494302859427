import React from 'react'
import sharepic from "../../Images/Share Your Link.png";

const ShareYourLink = () => {
    return (
        <>
            <section className='shareyourlink_sec'>
                <div className='sharepic'>
                    <img src={sharepic} class="img-fluid" alt='...' />
                </div>
                <div className='socialmedia_all'>
                    <button className='icon'><i className="fa-brands fa-facebook"></i></button>
                    <button className='icon'><i className="fa-brands fa-whatsapp"></i></button>
                    <button className='icon'><i className="fa-brands fa-twitter"></i></button>
                    <button className='icon'><i className="fa-solid fa-envelope"></i></button>
                    <button className='icon'><i className="fa-brands fa-linkedin-in"></i></button>
                    <button className='icon'><i className="fa-solid fa-paper-plane"></i></button>
                </div>
                <div className='refcode_wrap'>
                    <div className='refcode_box'>
                        <p className='code'>ref123458690</p>
                    </div>
                </div>
                <div className='referallink_wrap'>
                    <div className='referal_box'>
                        <p className='link'>https://neoosearch.com/referal-link</p>
                    </div>
                    <div className='buttonall_wrap'>
                        <button className='Copybutton_btn'>Copy Link</button>
                        <button className='Sharebutton_btn'>Share</button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ShareYourLink