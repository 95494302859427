// import React from 'react'
import { configureStore } from "@reduxjs/toolkit";
import PostSlice from '../Slice/PostSlice';
import Offer from "../Slice/Offer";
import Chat from "../Slice/Chat";

export const store = configureStore({
    reducer: {
        app: PostSlice,
        offer: Offer,
        chat: Chat
    },

});