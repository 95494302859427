import React, { useContext, useEffect, useState } from 'react'
import { Colorcontext } from '../../Component/Colorcontext/ColorContext';
import vectorarrow from "../../Images/vectorarrow.png"
import { Link } from 'react-router-dom';
const Listbusinessform = () => {
    const colordata = useContext(Colorcontext);
    const [drowdowsncustom, setDrowdowsncustom] = useState(false)
    useEffect(() => {

        colordata.setcolor(true);
        return () => {
            colordata.setcolor(false);
        };
    }, []);
    const drowdowncustclick = () => {
        setDrowdowsncustom(!drowdowsncustom)
    }
    return (
        <>
            <section className='listbusinessformdivmain'>
                <div className='cust_container'>
                    <div className='listbusinessdivflx'>
                        <div className='listbusinessdivleft'>
                            <div className='listbusinessdivleftbg'>
                                {/* <h4 className='listbusinesstxtpp'>Free list your company and <br />
                                    grow your business with Neoo Search. </h4> */}

                                <div className='contatcindofdiinpur'>
                                    <p className='contatcindofdiinpurtxtp'> Selected plan  <span> *</span></p>
                                    <div className='dropwdowndivfrmbusinee'>
                                        <p className='vectoraarowdivomgulmaili'>
                                            Basic free plan
                                        </p>
                                        <div className='vectoraarowdivomg' onClick={drowdowncustclick}>
                                            <img src={vectorarrow} alt='...' />
                                        </div>
                                        {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                            <ul className='vectoraarowdivomgulmaiul'>
                                                <li className='vectoraarowdivomgulmaili'>
                                                    Basic free plan
                                                </li>
                                                <li className='vectoraarowdivomgulmaili'>
                                                    Silver plan
                                                </li>
                                                <li className='vectoraarowdivomgulmaili'>
                                                   Glod Plan
                                                </li>
                                               
                                            </ul>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <div className='listbusinessdivleftbg'>
                                <p className='conattaindptxtpp'>Contact information</p>

                                <div className='contatcindofdicfplx'>
                                    <div className='contatcindofdiinpur'>
                                        <p className='contatcindofdiinpurtxtp'>Full name<span> *</span></p>
                                        <input type='text' placeholder='john' className='inpustxocntbusuitxtpp' />
                                    </div>
                                    <div className='contatcindofdiinpur'>
                                        <p className='contatcindofdiinpurtxtp'> Designation <span> *</span></p>
                                        <div className='dropwdowndivfrmbusinee'>
                                            <p className='vectoraarowdivomgulmaili'>
                                                Managing Director
                                            </p>
                                            <div className='vectoraarowdivomg' onClick={drowdowncustclick}>
                                                <img src={vectorarrow} alt='...' />
                                            </div>
                                            {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                                <ul className='vectoraarowdivomgulmaiul'>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Managing Director
                                                    </li>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Managing Director
                                                    </li>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Managing Director
                                                    </li>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Managing Director
                                                    </li>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Managing Director
                                                    </li>
                                                </ul>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className='contatcindofdiinpur'>
                                        <p className='contatcindofdiinpurtxtp'>Company name <span> *</span></p>
                                        <input type='text' placeholder='john' className='inpustxocntbusuitxtpp' />
                                    </div>
                                    <div className='contatcindofdiinpur'>
                                        <p className='contatcindofdiinpurtxtp'> Type of business  <span> *</span></p>
                                        <div className='dropwdowndivfrmbusinee'>
                                            <p className='vectoraarowdivomgulmaili'>
                                                Select type of business
                                            </p>
                                            <div className='vectoraarowdivomg' onClick={drowdowncustclick}>
                                                <img src={vectorarrow} alt='...' />
                                            </div>
                                            {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                                <ul className='vectoraarowdivomgulmaiul'>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Select type of business
                                                    </li>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Select type of business
                                                    </li>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Select type of business
                                                    </li>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Select type of business
                                                    </li>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Select type of business
                                                    </li>
                                                </ul>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className='contatcindofdiinpur'>
                                        <p className='contatcindofdiinpurtxtp'>Company  number</p>
                                        <input type='number' placeholder='john' className='inpustxocntbusuitxtpp' />
                                    </div>
                                    <div className='contatcindofdiinpur'>
                                        <p className='contatcindofdiinpurtxtp'>Mobile  number<span> *</span></p>
                                        <input type='number' placeholder='john' className='inpustxocntbusuitxtpp' />
                                    </div>
                                    <div className='contatcindofdiinpur'>
                                        <p className='contatcindofdiinpurtxtp'>Work email <span> *</span></p>
                                        <input type='text' placeholder='info@companyname.com' className='inpustxocntbusuitxtpp' />
                                    </div>
                                    <div className='contatcindofdiinpur'>
                                        <p className='contatcindofdiinpurtxtp'>Web address <span> </span></p>
                                        <input type='text' placeholder='www.webname.com' className='inpustxocntbusuitxtpp' />
                                    </div>
                                    <div className='contatcindofdiinpur'>
                                        <p className='contatcindofdiinpurtxtp'>Location <span> </span></p>
                                        <input type='text' placeholder='Palm Jumeirah' className='inpustxocntbusuitxtpp' />
                                    </div>
                                    <div className='contatcindofdiinpur'>
                                        <p className='contatcindofdiinpurtxtp'>Address <span> *</span></p>
                                        <input type='text' placeholder='Jumeirah tower #123, D block' className='inpustxocntbusuitxtpp' />
                                    </div>
                                    <div className='contatcindofdiinpur'>
                                        <p className='contatcindofdiinpurtxtp'> State <span> *</span></p>
                                        <div className='dropwdowndivfrmbusinee'>
                                            <p className='vectoraarowdivomgulmaili'>
                                                Dubai
                                            </p>
                                            <div className='vectoraarowdivomg' onClick={drowdowncustclick}>
                                                <img src={vectorarrow} alt='...' />
                                            </div>
                                            {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                                <ul className='vectoraarowdivomgulmaiul'>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Dubai
                                                    </li>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Dubai
                                                    </li>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Dubai
                                                    </li>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Dubai
                                                    </li>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Dubai
                                                    </li>
                                                </ul>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className='contatcindofdiinpur'>
                                        <p className='contatcindofdiinpurtxtp'>Country <span> *</span></p>
                                        <input type='text' placeholder='United arab emirate' className='inpustxocntbusuitxtpp' />
                                    </div>
                                    <div className='contatcindofdiinpurnewalasda'>
                                        <p className='contatcindofdiinpurtxtp'>Location map <span> </span></p>
                                        <input type='text' placeholder='United arab emirate' className='inpustxocntbusuitxtpp' />
                                    </div>
                                </div>

                                <div className='btnnexttdivbtndivmain'>
                                    <Link className='btnnexttdivbtn' to="/businesinfoform">Next</Link>
                                </div>
                            </div>
                        </div>
                        <div className='listbusinessdivrightt'>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Listbusinessform
