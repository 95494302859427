import React from 'react'
import businesscardimg from "../../Images/explore/reward_cardall.png"

const PromoteMembersBusinesses = () => {
    return (
        <>
            <section className='PromoteMembers_Businesses'>
                <div className='cust_container'>
                    <h2 className='heading'>Neoo Search reward cards</h2>
                    <div className='row'>
                        <div className='col-lg-5 col-md-6 col-12'>
                            <div className='Businesses_card'>
                                <img src={businesscardimg} className='img-fluid' alt='..' />
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-6 col-12'>
                            <div className='content'>
                                <h4 className='subhead'>Your business grows when you promote other members' businesses.</h4>
                                <h6 className='text'>Digitizes traditional, Prepare to engage and incentivize in the smartest way.</h6>
                                <p className='desc'>Customers today want digital reward card solutions that are convenient, modern, and contactless. Neoo search is an environmentally smart approach to reward programs, allowing you to eliminate paper rewards cards and save waste.</p>

                                <div className='OrderNow_btn' style={{ justifyContent: "start" }}>
                                    <button className='btn'>
                                        <span><i className="fa-solid fa-angle-right"></i></span>
                                        Learn more
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PromoteMembersBusinesses