import React, { useState } from 'react';
import "./Contactlist.css";
import cntnctimg from "../../Images/cntnctimg.png";
import cntnctimg1 from "../../Images/cntnctimg1.png";
import cntnctimg2 from "../../Images/cntnctimg2.png";
import cntnctimg3 from "../../Images/cntnctimg3.png";
import whatsapp from "../../Images/whatsapp.png";
import verified from "../../Images/verified.png";
import peopleadd from "../../Images/peopleadd.png";
import emojiwavinghandsign from "../../Images/emojiwavinghandsign.png";
import verifiedres from "../../Images/Verify responsive.png";
import { Link } from 'react-router-dom';
import ContactlistModal from '../../Component/ContactlistModal';


const Contactlist = () => {

    const [showmodal, setShowmodal] = useState(false);

    const handClick = () => {
        setShowmodal(!showmodal)
    }

    const conactdiv = [
        {
            image: cntnctimg
        },
        {
            image: cntnctimg1
        },
        {
            image: cntnctimg2
        },
        {
            image: cntnctimg3
        }
    ]
    return (
        <>
            <div className='contactlistmainpage'>
                <div className='cust_container'>
                    {/* <div className='bg-wht'>
                        <section className='mainbgsavecontact'>
                            <div className='mainsavecontact'>
                                <div className='deartxtmain'>
                                    <div className='emojuwavetxt'>
                                        <img src={emojiwavinghandsign} alt="..." />
                                        <span className='jsmrtxt'>Dear Jasim Noor,</span>
                                    </div>
                                    <div className='txtdearmain'>
                                        <p className='txtdear'>Welcome to Neoo Card Business Directory, you have new contact in your directory</p>
                                    </div>
                                </div>
                                <div className='savebtn'>
                                    <button className='savecontactbtn'>
                                        Save contact
                                        <span className='cntntimg'><img src={peopleadd} alt="..." /></span>
                                    </button>
                                </div>
                            </div>
                        </section>
                    </div> */}
                    <div className='contactbg'>
                        <div className='contctbrdr'>
                            <div className='cntntflx'>
                                <Link to='/Memberuserprofile'>
                                    <div className='cntcnmdtlsmai'>
                                        <div className='imgbrdrdiv'>
                                            {/* <img src={} alt="..." /> */}
                                        </div>
                                        <div className='dtghhnjkd'>
                                            <span className='prdltxtflx'>
                                                <p className='jhntxtlws'>John lewis</p>
                                                <p className='mrttxtt'>Marketing Executive</p>
                                            </span>
                                            <span className='prdltxtflx'>
                                                <p className='cmpntyxt'>Company Name</p>
                                                <p className='dghttxt'>Digital marketing</p>
                                            </span>
                                            <span className='prdltxtflx'>
                                                <p className='mbltxtop'><span>Mobile:</span> 0553670071</p>
                                                <p className='mbbjlisown'>Ajman - UAE</p>
                                            </span>
                                            <div className='lksflwresrt'>
                                                <div className='txtljklppp'>
                                                    <span>Likes:</span> 151
                                                </div>
                                                <div className='txtljklppp'>
                                                    <span>Followers:</span> 123
                                                </div>
                                                <div className=''>
                                                    <i className="fa-regular fa-star"></i>
                                                    <i className="fa-regular fa-star"></i>
                                                    <i className="fa-regular fa-star"></i>
                                                    <i className="fa-regular fa-star"></i>
                                                    <i className="fa-regular fa-star"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <div className='upbtndivmain'>
                                    <button className='upbtndiv'>Upgrade</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='contactbg'>
                        {conactdiv.map((item, index) => {
                            return (
                                <div className='brdrblckdiv' key={index} >
                                    <div className='cntntflx'>
                                        <Link to='/Memberinvitation'>
                                            <div className='cntcnmdtlsmai'>
                                                {/* <div className='prflimgbrdrdiv'> */}
                                                <div className='imgvrtdiv'>
                                                    <img src={item.image} alt="..." />
                                                </div>
                                                {/* </div> */}
                                                <div className='dtghhnjkd'>
                                                    <span className='prdltxtflx'>
                                                        <p className='jhntxtlws'>John lewis</p>
                                                        <p className='mrttxtt'>Marketing Executive</p>
                                                    </span>
                                                    <span className='prdltxtflx'>
                                                        <p className='cmpntyxt'>Company Name</p>
                                                        <p className='dghttxt'>Digital marketing</p>
                                                    </span>
                                                    <span className='prdltxtflx'>
                                                        <p className='mbltxtop'><span>Mobile:</span> 0553670071</p>
                                                        <p className='mbbjlisown'>Ajman - UAE</p>
                                                    </span>
                                                    <div className='lksflwresrt'>
                                                        <div className='txtljklppp'>
                                                            <span>Likes:</span> 151
                                                        </div>
                                                        <div className='txtljklppp'>
                                                            <span>Followers:</span> 123
                                                        </div>
                                                        <div className=''>
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-regular fa-star"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className='upbtndivmainlop'>
                                            <div className='iommdot' onClick={handClick}>
                                                <i className="fa-solid fa-ellipsis"></i>
                                            </div>
                                            <div className='whtsappimg'>
                                                <img src={whatsapp} alt="..." />
                                            </div>
                                            <div className='vrdfbrdr'>
                                                <span className=''>Verified</span>
                                                <img src={verified} alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                    <figure className='contResFig'>
                                        <img src={verifiedres} alt="img" />
                                    </figure>
                                    {showmodal && <ContactlistModal />}
                                </div>
                            )
                        })}
                    </div>

                    <div className='contactbg'>
                        {conactdiv.map((item, index) => {
                            return (
                                <div className='brdrblckdiv' key={index} >
                                    <div className='cntntflx'>
                                        <Link to='/NonMemberuserprofile'>
                                            <div className='cntcnmdtlsmai'>
                                                {/* <div className='prflimgbrdrdiv'> */}
                                                <div className='imgbgdrk'>
                                                    {/* <img src={item.image} alt="..." /> */}
                                                </div>
                                                {/* </div> */}
                                                <div className='dtghhnjkd'>
                                                    <span className='prdltxtflx'>
                                                        <p className='jhntxtlws'>John lewis</p>
                                                        <p className='mrttxtt'>Marketing Executive</p>
                                                    </span>
                                                    <span className='prdltxtflx'>
                                                        <p className='cmpntyxt'>Company Name</p>
                                                        <p className='dghttxt'>Digital marketing</p>
                                                    </span>
                                                    <span className='prdltxtflx'>
                                                        <p className='mbltxtop'><span>Mobile:</span> 0553670071</p>
                                                        <p className='mbbjlisown'>Ajman - UAE</p>
                                                    </span>
                                                    <div className='lksflwresrt'>
                                                        <div className='txtljklppp'>
                                                            <span>Likes:</span> 151
                                                        </div>
                                                        <div className='txtljklppp'>
                                                            <span>Followers:</span> 123
                                                        </div>
                                                        <div className=''>
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-regular fa-star"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className='upbtndivmainlop'>
                                            <div className='iommdot' onClick={handClick}>
                                                <i className="fa-solid fa-ellipsis"></i>
                                            </div>
                                            <div className='whtsappimg'>
                                                <img src={whatsapp} alt="..." />
                                            </div>
                                            <div className='vrdfbrdr'>
                                                <span className=''>Unverified</span>
                                                {/* <img src ={verified} alt="..." /> */}
                                            </div>
                                        </div>
                                    </div>
                                    {showmodal && <ContactlistModal />}
                                </div>
                            )
                        })}
                        <div className='loadmoreBtnDiv'>
                            <button className='loadmoreBtn'>Load More...</button>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Contactlist
