import React from 'react'
import logo from "../Images/Neoo search logo purple.png"
import ReCAPTCHA from "react-google-recaptcha";

const JoinNowModal = (props) => {

    const handleClick = () => {
        props.onclick()
    }
    const { closepopup, } = props;
    

    function onChange(value) {
        console.log("Captcha value:", value);
    }


    return (
        <>
            <section className='JoinNowModal_wrap'>
                <div className='JoinNowModal_body'>
                    <button className='close_btn' onClick={() => closepopup(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>

                    <div className='logo'>
                        <img src={logo} className='img-fluid' alt='...' />
                    </div>

                    <p className='text'>I am interested for neoo search membership/referal program</p>

                    <form>
                        <div className="form-group">
                            <label htmlFor="">Your name</label>
                            <input
                                type="text"
                                className="form-control"
                                id=""
                                placeholder=""
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Designation</label>
                            <select className="form-control" id="">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Company name</label>
                            <input
                                type="text"
                                className="form-control"
                                id=""
                                placeholder=""
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Type of company</label>
                            <select className="form-control" id="">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="">Contact number</label>
                            <div style={{ position: 'relative' }}>
                                <input
                                    type="number"
                                    className="form-control paddingleft"
                                    id=""
                                    placeholder=""
                                />

                                <select className="numbercode">
                                    <option>+971</option>
                                    <option>+971</option>
                                    <option>+91</option>
                                    <option>+971</option>
                                    <option>+971</option>
                                </select>

                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="">Email id</label>
                            <input
                                type="email"
                                className="form-control"
                                id=""
                                aria-describedby="emailHelp"
                                placeholder=""
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="">State</label>
                            <select className="form-control" id="">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="">Country</label>
                            <select className="form-control" id="">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Package</label>
                            <input
                                type="text"
                                className="form-control"
                                id=""
                                aria-describedby=""
                                placeholder="Business pack"
                            />
                        </div>

                        <div className="form-group mt-3">
                            <ReCAPTCHA
                                sitekey="Your client site key"
                                onChange={onChange}
                            />
                        </div>

                        <div className='Submit_btn'>
                            <button type="submit" className="btn" onClick={() => handleClick()}>
                                Submit
                            </button>
                        </div>
                    </form>

                </div>
            </section>
        </>
    )
}

export default JoinNowModal