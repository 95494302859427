import React from 'react'
import "../PeopleAccelerator/PeopleAccelerator.css"
import PeopleAcceleratorBanner from "../PeopleAccelerator/PeopleAcceleratorBanner";
import PeopleAcceleratorProgram from './PeopleAcceleratorProgram';

const Index = () => {
    return (
        <>
            <div className='PeopleAccelerator_Program'>

                <PeopleAcceleratorBanner />
                <PeopleAcceleratorProgram />
                
            </div>
        </>
    )
}

export default Index