import React from "react";
import "../VenderDashboard/VenderDashboard.css";
import DashboardStatus from "./DashboardStatus";
import MyOfferVender from "./MyOfferVender";
import VenderDetails from "./VenderDetails";
import OfferstatsVender from "./OfferstatsVender";
import RedeemedStats from "./RedeemedStats";


function Index() {
  

  return (
    <>
      <div className="venderdashboardhome_page">
        <div className="cust_container">
          <DashboardStatus />
          <MyOfferVender />
          <VenderDetails />
          <OfferstatsVender />
          <RedeemedStats />
        </div>
      </div>
    </>
  );
}

export default Index;
