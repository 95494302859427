import React, { useEffect, useState } from "react";
import cntnctimg from "../../Images/cntnctimg.png";
import cntnctimg1 from "../../Images/cntnctimg1.png";
import cntnctimg2 from "../../Images/cntnctimg2.png";
import cntnctimg3 from "../../Images/cntnctimg3.png";
import whatsapp from "../../Images/whatsapp.png";
import verified from "../../Images/verified.png";
import profileimg from "../../Images/profileimg.png";
import peopleadd from "../../Images/peopleadd.png";
import emojiwavinghandsign from "../../Images/emojiwavinghandsign.png";
import { Link } from "react-router-dom";
import ContactlistModal from "../../Component/ContactlistModal";
import verifiedres from "../../Images/Verify responsive.png";
import HttpClient from "../../utils/HttpClient";

const Owncontactlist = ({ TypeOFSavedContacts }) => {
  const [showmodal, setShowmodal] = useState(false);
  const [savedContacts, setsavedContacts] = useState([]);
  const [NoContact, setNocontact] = useState("");

  console.log(savedContacts, "resDatas");
  const handClick = () => {
    setShowmodal(!showmodal);
  };

  const savedContactList = async () => {
    const data = {
      key: TypeOFSavedContacts,
    };
    const res = await HttpClient.requestData("get-userby-key", "POST", data);
    console.log(res, "resData");
    if (res && res?.status) {
      setsavedContacts(res?.data);
    } else {
      setNocontact("No Contact Saved !");
    }
  };
  useEffect(() => {
    savedContactList();
  }, [TypeOFSavedContacts]);

  return (
    <>
      <div className="contactlistmainpage">
        <div className="cust_container">
          {/* <div className='bg-wht'>
                        <section className='mainbgsavecontact'>
                            <div className='mainsavecontact'>
                                <div className='deartxtmain'>
                                    <div className='emojuwavetxt'>
                                        <img src={emojiwavinghandsign} alt="..." />
                                        <span className='jsmrtxt'>Dear Jasim Noor,</span>
                                    </div>
                                    <div className='txtdearmain'>
                                        <p className='txtdear'>Welcome to Neoo Card Business Directory, you have new contact in your directory</p>
                                    </div>
                                </div>
                                <div className='savebtn'>
                                    <button className='savecontactbtn'>
                                        Save contact
                                        <span className='cntntimg'><img src={peopleadd} alt="..." /></span>
                                    </button>
                                </div>
                            </div>
                        </section>
                    </div> */}

          <div className="contactbg">
            {savedContacts
              ? savedContacts?.map((item, index) => {
                return (
                  <div className="brdrblckdiv" key={index}>
                    <div className="cntntflx">
                      <Link to="/">
                        <div className="cntcnmdtlsmai">
                          {/* <div className='prflimgbrdrdiv'> */}
                          <div className="imgvrtdiv">
                            <img
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png"
                              alt="..."
                            />
                          </div>
                          {/* </div> */}
                          <div className="dtghhnjkd">

                            <div className="responResFig">
                              <span className="prdltxtflx">
                                <p className="jhntxtlws">
                                  {item?.nonmemberDetails?.personName}
                                </p>
                                <p className="mrttxtt">
                                  {item?.nonmemberDetails?.designation}
                                </p>
                              </span>

                              <figure className="contResFig">
                                <img src={verifiedres} alt="img" />
                              </figure>
                            </div>


                            <span className="prdltxtflx">
                              <p className="cmpntyxt">
                                {item?.nonmemberDetails?.companyName}
                              </p>
                              <p className="dghttxt">Digital marketing</p>
                            </span>
                            <span className="prdltxtflx">
                              <p className="mbltxtop">
                                <span>Mobile:</span>{" "}
                                {item?.nonmemberDetails?.mobileNo}
                              </p>
                              {/* <p className="mbltxtop">
                              <span> {item?.nonmemberDetails?.country}</span>{" "}
                              {item?.nonmemberDetails?.emirate}
                            </p> */}
                              {/* <p className="mbltxtop">
                              <span>Emirate:</span> {item?.nonmemberDetails?.emirate}
                            </p> */}
                              <p className="mbbjlisown">
                                <span>
                                  {" "}
                                  {item?.nonmemberDetails?.country}
                                </span>{" "}
                                {item?.nonmemberDetails?.emirate}
                              </p>
                            </span>
                            <div className="lksflwresrt">
                              <div className="txtljklppp">
                                <span>Likes:</span> 151
                              </div>
                              <div className="txtljklppp">
                                <span>Followers:</span> 123
                              </div>
                              <div className="">
                                <i className="fa-regular fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <div className="upbtndivmainlop">
                        <div className="iommdot" onClick={handClick}>
                          <i className="fa-solid fa-ellipsis"></i>
                        </div>
                        <div className="whtsappimg">
                          <img src={whatsapp} alt="..." />
                        </div>
                        <div className="vrdfbrdr">
                          <span className="">Verified</span>
                          <img src={verified} alt="..." />
                        </div>
                      </div>
                    </div>
                    {showmodal && <ContactlistModal />}
                  </div>
                );
              })
              : { NoContact }}
          </div>
        </div>
      </div>
    </>
  );
};

export default Owncontactlist;
