import React from 'react'
import uploadimg from "../../src/Images/uploadimg.png"
const Editbannermodalbml = ({ closemodal }) => {
    return (
        <div className='uploabannermodalbgshadow' >
            <div className='uploabannermodalbg'>
                <div className='crossdivicon' onClick={() => closemodal()}>
                    <i className="fa-solid fa-xmark"></i>
                </div>
                <div className='upldbantrtupldbannerdiv'>
                    <h4 className='upldbannertrtxtpp'>Upload banner image</h4>
                    <p className='selletxtcbabbtxttpp'>
                        Select and upload the files of your choice
                    </p>
                </div>

                <div className='uploadaboxdeshhbdivbrdrmain'>
                    <div className='uploadaboxdeshhbdiv'>
                        <div className='filedibbhsjj'>
                            <input type='file' className='filedibbhsjjdiflediv' />
                        </div>
                        <div className=''>
                            <div className='uploadimgdibbavimg'>
                                <img src={uploadimg} alt='...' />
                            </div>
                            <h4 className='choosttflttxtpp'>Choose a file upload it here</h4>
                            <p className='jjshpsttsrrtxtpp'>JPEG, PNG formats, up to 1 MB</p>
                            <div className='bttserfilelytxypbtmmain'>
                                <button className='bttserfilelytxyp'>Browse File</button> </div>
                        </div>
                    </div>
                    <div className='svavtatvvbtnnmdivmaokin'>
                        <button className='svavtatvvbtnnm'>
                            Save
                        </button>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Editbannermodalbml
