
import { Html5QrcodeScanner } from 'html5-qrcode';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

export const QrCameraPartnerVoucher = ({ setCamera }) => {

    const [scanResult, setscanResult] = useState(null)
    const navigate = useNavigate()

    const voucherCodeNavigate = (data) => {
        navigate("/user-voucher-code", {
            state: data,
        })
    }

    useEffect(() => {
        const scaner = new Html5QrcodeScanner('reader', {
            qrbox: {
                width: 250,
                height: 250
            },
            fps: 5,
        })

        scaner.render(success, error);

        function success(result) {
            scaner.clear();
            setscanResult(result)
        }
        function error(err) {
            console.warn(err)
        }
    }, [])



    return (
        <div className="QRCodeScanner_modal">
            <div className='QRCodeScannermodal_body'>
                <button className='close_btn' onClick={() => setCamera(false)}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
                <div className='content'>
                    <h4 className='heading'>QR Code Scanner</h4>

                    {
                        scanResult ? <div className='Scannerbox_wrap'> Offer Code :
                            <span  className='mx-1 text-dark-emphasis '>{scanResult}</span>
                            <button onClick={() => voucherCodeNavigate(scanResult)}
                            className='px-3' style={{backgroundColor:"#67308F",color:"white",borderRadius:"25px"}}
                            >Press to Avail</button>
                        </div> :
                            <div id="reader"></div>
                    }
                </div>
            </div>
        </div>
    )
}
