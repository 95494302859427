import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import icon from "../../Images/vender/Redeemedstats.png";
import HttpClient from "../../utils/HttpClient";
import toast from "react-hot-toast";
import { useEffect } from "react";

function UserVoucherCode() {
  const [VoucherCode, setVoucherCode] = useState("");
  const location=useLocation()
  console.log(VoucherCode,"VoucherCode")
  console.log(location,"location")
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      offerCode: VoucherCode,
    };
    const res = await HttpClient.requestData(
      "redeem-status-change",
      "POST",
      data
    );
    if (res && res?.status) {
      toast.success(res.message);
      setVoucherCode("")
    } else {
      toast.error(res.message);
    }
  };
  useEffect(() => {

    if (location?.state) {
      setVoucherCode(location?.state);
    }
    
  }, [location?.state])
  
  return (
    <>
      <section className="uservoucher_code">
        <div className="cust_container">
          <div className="d-flex align-items-center justify-content-between toparea">
            <Link to="/vender-dashboard" className="backbutton_btn">
              <i className="fa-solid fa-arrow-left-long"></i>
            </Link>
            <div className="heading">
              {/* <img src={icon} className='img-fluid' alt='..' /> */}
              User Voucher code
            </div>
            <div className="blank"></div>
          </div>

          <h4 className="sub_head">Enter voucher code</h4>

          <form>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id=""
                aria-describedby=""
                placeholder="OF2SZBB2SNBE5T9T"
                value={VoucherCode}
                onChange={(e) => setVoucherCode(e.target.value)}
              />
            </div>

            <div className="Enter_btn">
              <button className="btn" onClick={(e) => handleSubmit(e)}>
                Enter
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default UserVoucherCode;
