import React from 'react'
import icon from "../../Images/Refer and Earn/Refer-friends-annu.png"
import { Link } from 'react-router-dom'

function ReferFriendsEarnCredits() {
    return (
        <>
            <section className='ReferFriendsEarn_credits'>
                <div className='ReferFriends_card'>
                    <div className='row'>
                        <div className='col-lg-2 col-md-3 col-3'>
                            <div className='iconwrap'>
                                <img src={icon} className='img-fluid' alt='...' />
                            </div>
                        </div>
                        <div className='col-lg-10 col-md-9 col-9'>
                            <h4 className='head'>Refer friends and earn credits.</h4>
                            <p className='desc'>Share your referral link with friends and earn AED 100
                                credit for each membership.</p>
                        </div>
                    </div>
                </div>

                <Link to="/generate-Link" className='GetReferralLink_btn'>Get Referral Link</Link>

            </section>
        </>
    )
}

export default ReferFriendsEarnCredits