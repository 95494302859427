import React from 'react';
import { Link } from 'react-router-dom';
import LoginHeader from '../Layout/LoginHeader';
import image from "../Images/bg.jpg";

const ForgotPassword = () => {
    return (
        <>
            <section className="authenSec">
                <div className="authenSecInn">
                    <div className="container">
                        <div className="authenSecInnDiv">
                            <div className="authenSecForm">
                                <div className="authenSecTop">
                                    <div className='forgetFig'>
                                    </div>
                                    <h4>Reset Password</h4>
                                    <p>Please enter your username or email address.
                                        A new password will be emailed to you.</p>
                                </div>

                                <div className="authenSecBtm">
                                    <form action="">
                                        <div className="form-group">
                                            <input type="email" placeholder='Email Id' />
                                        </div>
                                        <Link to='/resetPassword' className='homeloginBtn'>Send</Link>
                                    </form>
                                </div>

                            </div>
                            <div className="authenSecImg">
                                <img src={image} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ForgotPassword