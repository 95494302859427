import React, { useContext, useEffect } from 'react'
import bannerloginimage from "../../Images/bannerloginimage.png"
import { Colorcontext } from '../../Component/Colorcontext/ColorContext';
import { Link } from 'react-router-dom';
const Searchloginform = () => {
    const colordata = useContext(Colorcontext);
    useEffect(() => {

        colordata.setcolor(true);
        return () => {
            colordata.setcolor(false);
        };
    }, []);
    return (
        <>
            <section className='Searchloginformmain'>
                <div className='cust_container'>
                    <div className='Searchloginformmainbg'>
                        <div className='Searchloginformmainleft'>
                            <div className='Searchloginformmainleftimg'>
                                <img src={bannerloginimage} alt='...' />
                            </div>
                        </div>
                        <div className='Searchloginformmainright'>
                            <div className='Searchloginformmainrightbg'>
                                <p className='creattusettxpp'>Create Username and password</p>

                                <div className='usenametxttppmian'>
                                    <p className='usenametxttpp'>User name</p>
                                    <input type='text' placeholder='username@gmail.com' className='usenametxttppinput' />
                                </div>

                                <div className='usenametxttppmian'>
                                    <p className='usenametxttpp'>Password</p>
                                    <div className='usenametpasswrdifv'>
                                        <input type='password' placeholder='Password' className='usenametxttppinput' />
                                        <div className='showiconnimg'>
                                            <i class="fa-regular fa-eye"></i>
                                        </div>

                                        <div className='passowrdrestritiondiv'>
                                            <div className='passowrdrestritiondivtop'>
                                                <p className='minimumpasswodttrxpp'>
                                                    The minimum password length is 8
                                                    characters and must contain at least 1
                                                    lowercase letter, 1capital letter
                                                    1 number and 1 special character.
                                                </p>
                                            </div>
                                            <div className='passowrdrestritiondivbottom'>
                                                <div className=''>
                                                    <div className='radionattstxtdibflx'>
                                                        <input type="radio" id="" name="" defaultValue="" className='radiobacustomdesign' />
                                                        <label htmlFor="" className='radiobtntxtxtplalbel'># of characters</label>
                                                    </div>
                                                    <div className='radionattstxtdibflx'>
                                                        <input type="radio" id="" name="" defaultValue="" className='radiobacustomdesign' />
                                                        <label htmlFor="" className='radiobtntxtxtplalbel'>Lowercase letter</label>
                                                    </div>

                                                    <div className='radionattstxtdibflx'>
                                                        <input type="radio" id="" name="" defaultValue="" className='radiobacustomdesign' />
                                                        <label htmlFor="" className='radiobtntxtxtplalbel'>Capital letter</label>
                                                    </div>

                                                    
                                                    <div className='radionattstxtdibflx'> 
                                                        <input type="radio" id="" name="" defaultValue=""  className='radiobacustomdesign'/>
                                                        <label htmlFor="" className='radiobtntxtxtplalbel'>Number</label>
                                                    </div>
                                                    <div className='radionattstxtdibflx'>
                                                        <input type="radio" id="" name="" defaultValue=""  className='radiobacustomdesign'/>
                                                        <label htmlFor="" className='radiobtntxtxtplalbel'>Special character</label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='agereecheck'>
                                    <input type="checkbox" id="" name="" className='schekbdttstxttpp' />
                                    <p className='agreerxtppptt'>I agree with the <span> Terms of services </span> and <span> Privacy Policy </span> </p>
                                </div>

                                <div className='addedasldibv'>
                                    <span className='addersrquesttsxtpp'> 7+ 8 = ?</span>
                                    <input type='text' className='addesdwqtssxtoipnt' />
                                </div>
                                <div className='btnnexttdivbtnmain'>
                                    <Link className='btnnexttdivbtn' to="/ownblmprofile">Next</Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Searchloginform
