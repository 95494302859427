import React from "react";
import Backgroundimg from "../../Images/explore/6_reward 1.png";
import { Link } from "react-router-dom";

const ExploreBusinessRewards = () => {
  return (
    <>
      <section className="ExploreBusiness_Rewards">
        <div className="bgimg_wrap">
          <img src={Backgroundimg} className="img-fluid" alt=".." />
        </div>
        <div className="cust_container">
          <div className="content">
            <h2 className="head">
              Neoo search
              <br /> Business Rewards
            </h2>
            <p className="text">
              Neoo Search's innovative rewards program for our members offers a
              range of exclusive rewards and privileges designed to boost your
              business. Just tap and get these rewards the smart way.
            </p>

            <div className="OrderNow_btn" style={{ justifyContent: "start" }}>
              <Link
                to="/reward-home-page"
                className="btn"
                style={{ background: "#9E81CD" }}
              >
                <span>
                  <i className="fa-solid fa-angle-right"></i>
                </span>
                Know more
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExploreBusinessRewards;
