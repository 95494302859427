import React from 'react'
import uploadimg from "../../src/Images/uploadimg.png"
const Galleryeditmodal = ({ closemodal }) => {
    return (
        <div className='uploabannermodalbgshadow' >
            <div className='upgalerymodalbg'>
                <div className='crossdivicon' onClick={() => closemodal()}>
                    <i className="fa-solid fa-xmark"></i>
                </div>
                <div className='upldbantrtupldbannerdiv'>
                    <h4 className='upldbannertrtxtpp'>Upload gallery image</h4>
                    <p className='selletxtcbabbtxttpp'>
                        Select and upload the files of your choice
                    </p>
                </div>
                <div className='uploadaboxafgaeddpadikjnffdiv'>
                    <div className='uploadaboxdeshhbdiv'>
                        <div className='filedibbhsjj'>
                            <input type='file' className='filedibbhsjjdiflediv' />
                        </div>
                        <div className=''>
                            {/* <div className='uploadimgdibbavimg'>
                                <img src={uploadimg} alt='...' />
                            </div> */}
                              <div className='bttserfilelytxypbtmmain'>
                              <button className='bttserfilelytxyp'>Browse File</button> 
                              </div>
                            <h4 className='choosttflttxtpp'>Choose a file upload it here</h4>
                            <p className='jjshpsttsrrtxtpp'>JPEG, PNG formats, up to 1 MB</p>
                          
                        </div>
                    </div>

                    <div className='galrrttxtittxttxtppdib'>
                        <p className='galrrttxtittxttxtpp'>
                            Title
                        </p>
                        <input type='text' className='insttsbtrxrrds' />
                    </div>

                    <div className='galrrttxtittxttxtppdib'>
                        <p className='galrrttxtittxttxtpp'>
                            Short description
                        </p>
                        <textarea rows={4} cols={4} className='insttsbtrxrrdstexrare' />
                        <p className='ttsrrhhshwoolumiuitstxtt'>100 Words</p>
                    </div>
                    <div className='svavtatvvbtnnmdivmaokin'>
                        <button className='svavtatvvbtnnm'>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Galleryeditmodal
