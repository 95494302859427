import React, { useEffect } from "react";
import giftcard from "../../Images/gift-icon.png";
import { Link } from "react-router-dom";
import offImg1 from "../../Images/offerimg/Planet.png";
import offImg2 from "../../Images/offerimg/Resto.png";
import offImg3 from "../../Images/offerimg/Thumbay Hospital.png";
import offImg4 from "../../Images/offerimg/Times College.png";
import offImg5 from "../../Images/logo-w.png";
import { OurPartNerAllOffer, OwnWalletDetails } from "../../Redux/Slice/Offer";
import { useDispatch, useSelector } from "react-redux";

function OurPartners() {
  const dispatch = useDispatch();
  const { OurPartNerData, WalletData } = useSelector((state) => state.offer);
  const nonPurchaseWalletOffer = WalletData?.filter(
    (item) => !item?.voucherReedem
  );

  console.log(OurPartNerData, "OurPartNerData");

  const offersbox = [
    {
      id: 1,
      srclink: offImg1,
      bgColor: "#D6CE13",
      name: "Planet kids gallery",
      status: "Kids Activities",
      address: "Sharjah - UAE",
      redeemed: "Redeemed 123",
    },
    {
      id: 2,
      srclink: offImg2,
      bgColor: "#fff",
      name: "Resto Gym",
      status: "Fitness",
      address: "Ajman - UAE",
      redeemed: "Redeemed 123",
    },
    {
      id: 3,
      srclink: offImg3,
      bgColor: "#D0C3D2",
      name: "Thumbay hospital",
      status: "Health care",
      address: "Dubai - UAE",
      redeemed: "Redeemed 123",
    },
    {
      id: 4,
      srclink: offImg4,
      bgColor: "#D61F13",
      name: "Times college",
      status: "Education",
      address: "Sharjah - UAE",
      redeemed: "Redeemed 123",
    },
    {
      id: 5,
      srclink: offImg5,
      bgColor: "#5113D6",
      name: "Neoo Search FZE",
      status: "Digital marketing",
      address: "Sharjah - UAE",
      redeemed: "Redeemed 123",
    },
  ];

  useEffect(() => {
    // console.log(OurPartNerAllOffer, "OurPartNerAllOffer");
    dispatch(OurPartNerAllOffer());
    dispatch(OwnWalletDetails());
  }, []);
  return (
    <>
      <section className="OurPartners_page">
        <div className="cust_container">
          <div className="top_area">
            <Link className="backbtn" to="/reward">
              <i className="fa-solid fa-arrow-left-long" />
            </Link>
            <h4 className="heading">Our Partners</h4>
            <Link to="/my-wallet">
              <div className="icon">
                <img src={giftcard} className="img-fluid" alt="....." />
                {nonPurchaseWalletOffer?.length > 0 && (
                  <span className="notification_count">
                    {nonPurchaseWalletOffer?.length}
                  </span>
                )}
              </div>
            </Link>
          </div>

          {/* <section className="topredeemedOffers_sec">
            <ul>
              {offersbox.map((item, index) => (
                <li className="cardbox_box" key={index}>
                  <div className="row m-0">
                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 p-0">
                      <div
                        className="img_box"
                        style={{ backgroundColor: item.bgColor }}
                      >
                        <img
                          src={item.srclink}
                          className="img-fluid"
                          alt="..."
                        />
                      </div>
                    </div>
                    <div className="col-xl-10 col-lg-10 col-md-9 col-sm-8 col-8 pr-0">
                      <div className="content">
                        <h4 className="name">{item.name}</h4>
                        <h6 className="status">{item.status}</h6>
                        <div className="flex_wrap">
                          <p className="text">{item.address}</p>
                          <p className="text">{item.redeemed}</p>
                        </div>

                        <div className="flex_wrap">
                          <p className="point">
                            <span>2,3345</span>Redeeming points
                          </p>
                          <Link
                            to="/offers-categories-details"
                            className="Offerdetails_btn"
                          >
                            Offer details
                            <i className="fa-regular fa-circle-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </section> */}
          <section className="topredeemedOffers_sec">
            <ul>
              {OurPartNerData.map((item, index) => (
                <li className="cardbox_box" key={index}>
                  <div className="row m-0">
                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 p-0">
                      <div
                        className="img_box"
                        style={{ backgroundColor: item.bgColor }}
                      >
                        <img
                          src={item?.partnerLogo}
                          className="img-fluid"
                          alt="..."
                        />
                      </div>
                    </div>
                    <div className="col-xl-10 col-lg-10 col-md-9 col-sm-8 col-8 pr-0">
                      <div className="content">
                        <h4 className="name">{item.companyName}</h4>
                        <h6 className="status">{item.offerType}</h6>
                        <div className="flex_wrap">
                          <p className="text">
                            {item.partnerEmirate} - {item.partnerCountry}
                          </p>
                        </div>

                        <div className="flex_wrap">
                          <p className="point">
                            <span>{item?.reddemingPoints}</span>Redeeming points
                          </p>
                          {item?.purchasedStatus ? (
                            <div
                              // to={"/offers-categories-details/" + item?._id}
                              className="OfferdetailsRedeemed_btn"
                            >
                              Already Redeemed
                            </div>
                          ) : item?.voucherCount >= item?.voucherNo ? (
                            <div
                              // to={"/offers-categories-details/" + item?._id}
                              className="OfferdetailsRedeemed_btn"
                            >
                              Expired
                            </div>
                          ) : (
                            <Link
                              to={
                                "/offers-categories-details/" +
                                item?.offerCategory?.toLowerCase() +
                                "/" +
                                item?._id
                              }
                              className="Offerdetails_btn"
                            >
                              Offer details
                              <i className="fa-regular fa-circle-right"></i>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </section>
        </div>
      </section>
    </>
  );
}

export default OurPartners;
