import React, { useState } from "react";
import Frame from "../../Images/Frame.png";
import framelike from "../../Images/framelike.png";
import profileimg from "../../Images/profileimg.png";
import whatsapp from "../../Images/whatsapp.png";
import "./Memberownprofile.css";
import { Link, useNavigate } from "react-router-dom";
import Rectangle from "../../Images/Rectangle.png";
import blankpro from "../../Images/blank.jpg";
import prdct from "../../Images/prdct.png";
import pdf from "../../Images/pdf.png";
import coverpic from "../../Images/cover img.jpg";
import ProductModal from "../../Modal/ProductModal";
import QRshareModal from "../../Modal/QRshareModal";
import ProfileShare from "../../Modal/ProfileShare";
import HttpClient from "../../utils/HttpClient";
import { useEffect } from "react";
import CustomLoader from "../../CustomComponents/loader/CustomLoader";
import ReactPlayer from "react-player";
// import { collection, query, where, getDocs } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import {
  ShareModalonOff,
  UserLocation,
  getOwnProfile,
  getPosts,
} from "../../Redux/Slice/PostSlice";
import verified from "../../Images/Verify responsive.png";
import StarRatings from "react-star-ratings";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  onDisconnect,
  ref,
  serverTimestamp,
  set,
  update,
  query,
  get,
  orderByChild,
  equalTo,
} from "firebase/database";
import { db } from "../../Firebase/FirebaseConfig";
import { OnetoOneChatIdname } from "../../Redux/Slice/Chat";
import moment from "moment";
import Messageicon from "../../Images/Message-icon.png";

const Memberownprofile = () => {
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [UserMemberData, setUserMemberData] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [contactdetail, setContactdetail] = useState(true);
  const [aboutdetail, setAboutdetail] = useState(true);
  const [servicedetail, setServicedetail] = useState(true);
  const [productdetail, setProductdetail] = useState(true);
  const [servicepdfdetail, setServicepdfdetail] = useState(true);
  const [modala, setModala] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  const [sharemodal, setSharemodal] = useState(false);
  const [ProductItem, setProductItem] = useState("");
  const [savedContacts, setsavedContacts] = useState([]);
  const [countDown, setCountDown] = useState();

  const {
    ProfileOwnContactCount,
    SingleUserData,
    ShareModalOpenClose,
    UserProfileID,
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(UserMemberData, "UserMemberData");

  const handledetailClick = () => {
    setContactdetail(!contactdetail);
  };
  const handleaboutClick = () => {
    setAboutdetail(!aboutdetail);
  };
  const handleserviceClick = () => {
    setServicedetail(!servicedetail);
  };
  const handleproductClick = () => {
    setProductdetail(!productdetail);
  };
  const handleservicepdfClick = () => {
    setServicepdfdetail(!servicepdfdetail);
  };
  const handlemodalClick = (item) => {
    setModala(!modala);
    setProductItem(item);
  };
  const modhandleclick = () => {
    setShowmodal(!showmodal);
  };
  const shareClick = () => {
    setSharemodal(!sharemodal);
    dispatch(ShareModalonOff(true));
  };

  const getUserProfile = async () => {
    setisLoading(true);
    const res = await HttpClient.requestData("get-profile", "GET", {});
    console.log(res?.data, "userprofile");
    if (res && res?.status) {
      setisLoading(false);
      setUserMemberData(res?.data);
      dispatch(UserLocation(res?.data));
      // dispatch(UserProfileIDget(res?.data?._id));
    } else {
      setisLoading(false);
    }
  };

  const chkChatlistAndCreate = () => {
    dispatch(OnetoOneChatIdname(SingleUserData?.[0]?._id));
    navigate("/chat");
  };

  //Saved contactlist
  const savedContactList = async () => {
    const res = await HttpClient.requestData("member-contacts", "GET", {});
    console.log(res, "resDataMember");
    if (res && res?.status) {
      setsavedContacts(res?.data);
    }
  };

  useEffect(() => {
    const checkForUnreadMessages = async () => {
      try {
        if (!db || typeof db !== "object") {
          throw new Error(
            "Realtime Database instance is not properly initialized"
          );
        }

        const chatListRef = ref(db, `chatList/${UserProfileID?._id}`);
        console.log("Chatlist : ", chatListRef);

        get(chatListRef)
          .then((snapshot) => {
            let countData = Object.values(snapshot.val()).reduce(
              (prev, current) => {
                let count = current.seenCount ?? 0;
                return prev + count;
              },
              0
            );
            setCountDown(countData);
            console.log("countData", countData);
          })
          .catch((error) => {
            console.log("error", error);
          });
        // const q = query(
        //   chatListRef,
        //   orderByChild("remoteId"),
        //   equalTo(UserProfileID?._id)
        // );

        // const querySnapshot = await get(q);
        // let unreadCount = 0;

        // querySnapshot.forEach((doc) => {
        //   const chatData = doc.data();
        //   if (chatData.seenCount === 0 && !chatData.isArchived) {
        //     unreadCount += 1;
        //   }
        // });

        // setHasUnreadMessages(unreadCount > 0);
      } catch (error) {
        console.error("Error checking for unread messages:", error);
      }
    };

    if (UserProfileID?._id) {
      checkForUnreadMessages();
    }
  }, [UserProfileID, db]);

  useEffect(() => {
    savedContactList();
    getUserProfile();
  }, []);

  useEffect(() => {
    dispatch(getPosts());
    // to remove the reciever id for chat purpose
    dispatch(OnetoOneChatIdname(""));
    dispatch(getOwnProfile());
  }, []);

  // firebase account creation
  useEffect(() => {
    if (UserProfileID) {
      const userId = UserProfileID?._id;

      const userData = {
        personName: UserProfileID?.personName,
        username: UserProfileID?.userName,
        email: UserProfileID?.emailId,
        profile_picture: UserProfileID?.profilePhoto,
        status: "online",
        blockedUsers: [],
      };

      if (userId) {
        const userRef = ref(db, "users/" + userId);
        const userStatusRef = ref(db, "status/" + userId);

        // Set user data in the database
        set(userRef, userData)
          .then(() => console.log("User data set successfully"))
          .catch((error) => console.log(error));

        // Handle online/offline status
        const isOfflineForDatabase = {
          state: "offline",
          last_changed: moment(Date.now()).format("MMMM DD, YYYY"),
          last_seen_time: moment(Date.now()).format("HH:mm"),
        };
        const isOnlineForDatabase = {
          state: "online",
          last_changed: moment(Date.now()).format("MMMM DD, YYYY"),
          last_seen_time: moment(Date.now()).format("HH:mm"),
        };

        // Set online status
        set(userStatusRef, isOnlineForDatabase);

        // Set up onDisconnect to set offline status
        onDisconnect(userStatusRef).set(isOfflineForDatabase);

        // Update last seen time when the user goes offline
        window.addEventListener("beforeunload", () => {
          console.log("Before unload event triggered");
          update(userStatusRef, isOfflineForDatabase)
            .then(() => console.log("Status updated to offline"))
            .catch((error) => console.error("Error updating status:", error));
        });

        console.log("Firebase set for user with _id:", userId);
      } else {
        console.log("UserProfileID does not have _id property");
      }
    } else {
      console.log("UserProfileID is undefined");
    }
  }, [UserProfileID]);
  // useEffect(() => {
  //   // Reload the page after landing on it
  //   // window.location.reload();
  // }, []);

  return (
    <>
      <div className="memberownprfl">
        {/* <CustomLoader loading={isLoading} /> */}
        {/* <SkeletonTheme
          baseColor="#5294e0"
          highlightColor="#96c7ff"
          borderRadius="0.5rem"
          duration={2}
        >
          <p>
            <Skeleton count={3} />
          </p>
        </SkeletonTheme> */}
        <SkeletonTheme
          // highlightColor="#67308F"
          duration={1}
        >
          <div className="cust_container">
            {isLoading ? (
              // skeleten start  here
              <div>
                <Skeleton height={200} />
                <div className="col d-flex">
                  <div className="col-2 col-sm-2 ">
                    <Skeleton circle height={100} width={100} />
                  </div>

                  <div className=" col-10 col-sm-10">
                    <Skeleton height={100} style={{ width: "100%" }} />
                  </div>
                </div>
                <Skeleton height={25} count={5} className="col" />
                <Skeleton height={100} />
              </div> // skeleten end  here
            ) : (
              <div className="bg-wht">
                <div className="bggradient">
                  <img src={UserMemberData?.banner} alt="img" />
                </div>
                <div className="persondetailsdiv">
                  <div className="img_profileflx">
                    <div className="imgbrdr">
                      <img
                        src={UserMemberData?.profilePhoto || blankpro}
                        alt="img"
                      />
                    </div>
                    <div className="dtlsprflnmmph">
                      <div className="left">
                        <p className="profiledtlstxt">
                          {/* {UserMemberData?.gender} */}
                          {UserMemberData?.personName || <Skeleton />}
                        </p>
                        <p className="prodtlmarkettxt">
                          {UserMemberData?.designation || <Skeleton />}
                        </p>
                        <div className="likeCount_all">
                          <p className="text">
                            <strong>
                              {UserMemberData?.likeCount < 0 ? (
                                <Skeleton />
                              ) : (
                                UserMemberData?.likeCount
                              )}
                            </strong>
                            <span>Likes</span>
                          </p>
                          <p className="text">
                            <strong>{savedContacts?.length}</strong>
                            <span>Connections</span>
                          </p>
                        </div>
                      </div>
                      <div className="Verified">
                        <img
                          src={verified || <Skeleton />}
                          className="img-fluid"
                          alt="icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="likespinerdivflx mx-3">
                    {UserProfileID?.memberStatus ? (
                      <>
                        <button
                          type="button"
                          className="Messagebtn_"
                          onClick={chkChatlistAndCreate}
                          style={{ position: "relative" }}
                        >
                          <img
                            src={Messageicon}
                            className="img-fluid"
                            alt="Message icon"
                          />
                          Message
                          {hasUnreadMessages && (
                            <span className="green-dot-unread-message"></span>
                          )}
                          {countDown > 0 && (
                            <div className="countdown-circle">
                              <p>{countDown}</p>
                            </div>
                          )}
                        </button>
                      </>
                    ) : null}

                    <Link className="shredivflx" onClick={shareClick}>
                      <span className="">Share</span>
                      <i className="fa-solid fa-share-from-square"></i>
                    </Link>
                    {/* <Link className="likespiner">
                  <img src={framelike} alt="..." />
                  <span className="">{UserMemberData?.likeCount} Liked</span>
                </Link> */}

                    {/* <Link to="/Owncontactlist" className="likespiner">
                  <img src={Frame} alt="..." />
                  <span className="">{savedContacts?.length}</span>
                </Link> */}

                    {/* <Link to={"/"} className="whatsppimg">
                  <img src={whatsapp} alt="..." />
                </Link> */}
                  </div>
                </div>
                <div className="infolabbrdr">
                  <div className="infoflx justify-content-between">
                    <div
                      className="d-flex align-items-center"
                      style={{ width: "100%" }}
                    >
                      <div className="rctdivimg" onClick={modhandleclick}>
                        {UserMemberData?.qrCode && !isLoading && (
                          <img src={UserMemberData?.qrCode} alt="QR" />
                        )}
                      </div>
                      <div className="groupdiv">
                        <div>
                          <h4 className="ocentxt">
                            {UserMemberData?.companyName}
                          </h4>
                          <p className="pcndgtxt">
                            {UserMemberData?.TypeOfCompany}
                          </p>
                          <div className=" d-flex">
                            <p className="dbytxtp mr-1">
                              {UserMemberData?.emirate}
                            </p>
                            <p className="dbytxtp">{UserMemberData?.country}</p>
                          </div>
                        </div>

                        <div className="rating_box">
                          {/* <i class="fa-solid fa-star active"></i>
                      <i class="fa-solid fa-star active"></i>
                      <i class="fa-solid fa-star active"></i>
                      <i class="fa-solid fa-star "></i>
                      <i class="fa-solid fa-star "></i> */}
                          <StarRatings
                            rating={UserMemberData?.avgRating}
                            starRatedColor="#F3660B"
                            starEmptyColor="grey"
                            numberOfStars={5}
                            name="UserProfileRating" // Set to a generic name for product ratings
                            starDimension="20px"
                            starSpacing="5px"
                            className="fa-solid fa-star "
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="d-flex" style={{ gap: '12px' }}>
                                    <Link to='/Invitation' className='invitebtn'>Invite Member</Link>
                                    <Link to='/Memberinvitation' className='invitebtn'>Invite Non-member</Link>
                                </div> */}
                  </div>
                </div>

                <div className="socialdivflx">
                  <a
                    href={UserMemberData?.facebookLink}
                    target="_blank"
                    className="bgsocial"
                  >
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                  <a
                    href={UserMemberData?.instaLink}
                    target="_blank"
                    className="bgsocial"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                  <a
                    href={UserMemberData?.linkedInLink}
                    target="_blank"
                    className="bgsocial"
                  >
                    <i className="fa-brands fa-linkedin-in"></i>
                  </a>

                  <a
                    href={UserMemberData?.youtubeLink}
                    target="_blank"
                    className="bgsocial"
                  >
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                  {/* <a
                href={UserMemberData?.websiteLink}
                target="_blank"
                className="bgsocial"
              >
                <i className="fa-solid fa-basketball"></i>
              </a> */}
                  <a
                    href={UserMemberData?.dribbbleLink}
                    target="_blank"
                    className="bgsocial"
                  >
                    <i class="fa-brands fa-x-twitter"></i>
                  </a>
                  <a
                    href={UserMemberData?.websiteLink}
                    target="_blank"
                    className="bgsocial"
                  >
                    <i class="fa-solid fa-globe"></i>
                  </a>
                </div>

                <div className="cntntbrdr">
                  <div className="flxcntnt">
                    <p className="cntncttxt">Contact Details</p>
                    <div className="ArrwiconDiv" onClick={handledetailClick}>
                      <span
                        className={
                          contactdetail
                            ? "fa-solid fa-chevron-up"
                            : "fa-solid fa-chevron-down"
                        }
                      ></span>
                    </div>
                  </div>
                  {contactdetail && (
                    <div className="">
                      <div className="divcnctflx">
                        <div className="brdrdivdtls">
                          <span className="txtdtlmn">
                            <i className="fa-solid fa-phone"></i>
                            {UserMemberData?.telephoneNo}
                          </span>
                        </div>
                        <div className="brdrdivdtls">
                          <span className="txtdtlmn">
                            <i className="fa-solid fa-mobile-screen-button"></i>
                            {UserMemberData?.phone_code}&nbsp;
                            {UserMemberData?.mobileNo}
                          </span>
                        </div>
                        <div className="brdrdivdtls">
                          <span className="txtdtlmn">
                            <i className="fa-brands fa-whatsapp"></i>
                            {UserMemberData?.whats_code}&nbsp;
                            {UserMemberData?.whatsappNo}
                          </span>
                        </div>
                      </div>
                      <div className="dibmailflx">
                        <div className="envelopdiv">
                          <span className="txtdtlmn">
                            <i className="fa-solid fa-envelope"></i>
                            {UserMemberData?.emailId}
                          </span>
                        </div>
                        <div className="envelopdiv">
                          <span className="txtdtlmn">
                            <i className="fa-solid fa-globe"></i>
                            {UserMemberData?.websiteLink}
                          </span>
                        </div>
                      </div>
                      <div className="divloctin">
                        <span className="txtdtlmn">
                          <i className="fa-solid fa-location-dot"></i>
                          {UserMemberData?.address}
                          {UserMemberData?.location}
                        </span>
                      </div>

                      {/* <div className='mapwidth'>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14728.727405628732!2d88.33364660000001!3d22.64700745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1706076991023!5m2!1sen!2sin"
                                        // width={900}
                                        heigbwFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    />

                                </div> */}

                      <div className="getdrctnBtnDiv d-flex justify-content-between divloctin">
                        <p className="txtdtlmn">
                          {UserMemberData?.emirate},{UserMemberData?.country}
                        </p>
                        <a
                          href={UserMemberData?.locationLink}
                          className="getdirectionbtn"
                          target="_blank"
                        >
                          Get Direction
                          <i class="fa-solid fa-diamond-turn-right"></i>
                        </a>
                      </div>
                    </div>
                  )}
                </div>

                <div className="abtbrsdr">
                  <div className="flxcntnt">
                    <p className="cntncttxt">About Us</p>
                    <div className="ArrwiconDiv" onClick={handleaboutClick}>
                      <span
                        className={
                          aboutdetail
                            ? "fa-solid fa-chevron-up"
                            : "fa-solid fa-chevron-down"
                        }
                      ></span>
                    </div>
                  </div>
                  {aboutdetail && (
                    <div className="">
                      <p className="txtabtmain">
                        {UserMemberData?.companyDescription}
                      </p>
                    </div>
                  )}
                </div>

                {UserMemberData?.service?.length > 0 && (
                  <div className="servcbrdr">
                    <div className="flxcntnt">
                      <p className="cntncttxt">Services</p>
                      <div className="ArrwiconDiv" onClick={handleserviceClick}>
                        <span
                          className={
                            servicedetail
                              ? "fa-solid fa-chevron-up"
                              : "fa-solid fa-chevron-down"
                          }
                        ></span>
                      </div>
                    </div>
                    {servicedetail && (
                      <div className="srvcboxbrdr">
                        {UserMemberData?.service?.map((item, i) => {
                          return (
                            <>
                              <div className="brdrmaindesign" key={i}>
                                {" "}
                                {item}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}

                {UserMemberData?.productData?.length > 0 && (
                  <div className="prdrtbrdr">
                    <div className="flxcntnt">
                      <p className="cntncttxt">Products</p>
                      <div className="ArrwiconDiv" onClick={handleproductClick}>
                        <span
                          className={
                            productdetail
                              ? "fa-solid fa-chevron-up"
                              : "fa-solid fa-chevron-down"
                          }
                        ></span>
                      </div>
                    </div>
                    {productdetail && (
                      <div className="">
                        <div className="prdrtflxdivmain">
                          {UserMemberData?.productData?.map((item, index) => {
                            return (
                              <div
                                className="brdrprdctdiv"
                                key={index}
                                onClick={() => handlemodalClick(item)}
                              >
                                <div className="prdcgimg">
                                  <img src={item?.image} alt="..." />
                                </div>
                                <div className="textparamain">
                                  <p className="txtdiv">{item?.name}</p>
                                  <p className="txtpara">{item?.description}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {/* <div className='vdoighh'>
                                    <iframe
                                        width={560}
                                        height={315}
                                        src="https://www.youtube-nocookie.com/embed/My0jYiN5JDU"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    />
                                </div> */}
                      </div>
                    )}
                  </div>
                )}
                <div className="brdrsrvc">
                  <div className="flxcntnt">
                    <p className="cntncttxt">Profile</p>
                    <div
                      className="ArrwiconDiv"
                      onClick={handleservicepdfClick}
                    >
                      <span
                        className={
                          servicepdfdetail
                            ? "fa-solid fa-chevron-up"
                            : "fa-solid fa-chevron-down"
                        }
                      ></span>
                    </div>
                  </div>
                  {servicepdfdetail && (
                    <div>
                      {UserMemberData?.videoUrl && (
                        <div className="vdoighh">
                          {/* <iframe
                      width={560}
                      height={315}
                      src="https://www.youtube.com/embed/WAyzEOeeBZw"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    /> */}
                          <ReactPlayer
                            // width={}
                            // height={415}
                            // width={100}
                            // height={415}
                            title="YouTube video player"
                            className="ReactPlayerdiv"
                            url={UserMemberData?.videoUrl}
                          />
                        </div>
                      )}
                      {UserMemberData?.doc?.length > 0 && (
                        <div className="pdrmainflx">
                          {UserMemberData?.doc?.map((item, i) => {
                            return (
                              <div className="pdfflxDiv">
                                <div className="pdfimg">
                                  <img src={pdf} alt="..." />
                                  <div className="inpuduv">
                                    {/* <input type="file" id="myfile" name="myfile" /> */}
                                    <a href={item?.doc}></a>
                                  </div>
                                </div>
                                <div className="prflmainflx">
                                  <p className="prfltxtprdct">
                                    {item?.docName
                                      ? item.docName
                                      : `Document${i + 1}`}
                                  </p>
                                  <a
                                    href={item?.pdf}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download=""
                                    className=" text-primary mt-2"
                                  >
                                    Open PDF{" "}
                                    <i class="fa-solid fa-download"></i>
                                  </a>
                                  {/* <p className="prfldtlsprdct">
                              Lorem Ipsum has been the industry's standard
                            </p> */}
                                </div>
                              </div>
                            );
                          })}
                          {/* <div className="pdfflxDiv">
                      <div className="pdfimg">
                        <img src={pdf} alt="..." />
                        <div className="inpuduv">
                          <input type="file" id="myfile" name="myfile" />
                        </div>
                      </div>
                      <div className="prflmainflx">
                        <p className="prfltxtprdct">Profile Name</p>
                        <p className="prfldtlsprdct">
                          Lorem Ipsum has been the industry's standard
                        </p>
                      </div>
                    </div>
                    <div className="pdfflxDiv">
                      <div className="pdfimg">
                        <img src={pdf} alt="..." />
                        <div className="inpuduv">
                          <input type="file" id="myfile" name="myfile" />
                        </div>
                      </div>
                      <div className="prflmainflx">
                        <p className="prfltxtprdct">Profile Name</p>
                        <p className="prfldtlsprdct">
                          Lorem Ipsum has been the industry's standard
                        </p>
                      </div>
                    </div> */}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </SkeletonTheme>
        {modala && (
          <ProductModal closemodal={setModala} ProductItem={ProductItem} />
        )}
        {showmodal && (
          <QRshareModal
            closeModal={setShowmodal}
            Qrimage={UserMemberData?.qrCode}
          />
        )}
        {ShareModalOpenClose && (
          <ProfileShare
            whatsApp={UserMemberData?.whatsappNo}
            linkedInLink={UserMemberData?.linkedInLink}
            facebookLink={UserMemberData?.facebookLink}
            userLink={UserMemberData?.userLink}
            instaLink={UserMemberData?.instaLink}
            emailId={UserMemberData?.emailId}
            // closeModal={setSharemodal}
          />
        )}
      </div>
    </>
  );
};

export default Memberownprofile;
