import React, { useState } from 'react'
import crosssimgiocn from "../../src/Images/crosssimgiocn.png";
import vectorarrow from "../../src/Images/vectorarrow.png"
const Updatepostmoda = ({ closemodal }) => {
    const [drowdowsncustom, setDrowdowsncustom] = useState(false)
    const drowdowncustclick = () => {
        setDrowdowsncustom(!drowdowsncustom)
    }
    return (
        <div className='uploabannermodalbgshadow'>
            <div className='enquerysmokdnndbndbg'>
                <div className="threedorttsdcoroodivflx">
                    <div className="imnmnprhhhsbdijnvvgbsj" onClick={() => closemodal()}>
                        <img src={crosssimgiocn} alt="..." />
                    </div>
                </div>

                <div class="psendwendijndwjjwndiv">
                    <p class="breyuuayproosttxppp">Post your updates</p>

                    <div className='poysttdiobnnnmmmnjm'>
                        <p className='conattaindptxtppnewallasa'>Subject <span> </span></p>
                        <input type="text" placeholder="" className="inpustxocntbusuitxtppnewallattyy" />
                    </div>


                    <div className='poysttdiobnnnmmmnjm'>
                        <p className='conattaindptxtppnewallasa'>Description <span> </span></p>
                        <textarea rows={4} cols={4} className='ennsjjuwtysttsxttaytredg' />
                    </div>

                    <div className='poysttdiobnnnmmmnjm'>
                        <p className='conattaindptxtppnewallasa'>Attachment <span>  </span></p>
                        <div className='chossefilkklkebdtrfdiv'>
                            <div className='enuqteyschosefllfbrdttdivdkdlnb'>


                                <button className='enuqteyschosefllfbrdtt'>
                                    Choose file
                                </button>
                                <p>No file chosen</p>
                            </div>
                        </div>


                    </div>

                    <div className='poysttdiobnnnmmmnjm'>
                        <p className='conattaindptxtppnewallasa'>Target audience <span>  </span></p>
                        <div className='compamyupdatepoksttmodaldiv'>

                            <div className='compamydinfodivmdiobbdgppshgsadd'>

                                <div className='dropwdowndivfrmbusineenewwlas'>
                                    <p className='vectoraarowdivomgulmaili'>
                                        Advertising
                                    </p>
                                    <div className='vectoraarowdivomgnenwlal' onClick={drowdowncustclick}>
                                        <img src={vectorarrow} alt='...' />
                                    </div>
                                    {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                        <ul className='vectoraarowdivomgulmaiul'>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Advertising
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Advertising
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Advertising
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Advertising
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Advertising
                                            </li>
                                        </ul>
                                    </div>}
                                </div>
                            </div>

                            <div className='compamydinfodivmdiobbdgppshgsadd'>

                                <div className='dropwdowndivfrmbusineenewwlas'>
                                    <p className='vectoraarowdivomgulmaili'>
                                        Digital marketing
                                    </p>
                                    <div className='vectoraarowdivomgnenwlal' onClick={drowdowncustclick}>
                                        <img src={vectorarrow} alt='...' />
                                    </div>
                                    {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                        <ul className='vectoraarowdivomgulmaiul'>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                        </ul>
                                    </div>}
                                </div>
                            </div>

                            <div className='compamydinfodivmdiobbdgppshgsadd'>

                                <div className='dropwdowndivfrmbusineenewwlas'>
                                    <p className='vectoraarowdivomgulmaili'>
                                        Digital marketing
                                    </p>
                                    <div className='vectoraarowdivomgnenwlal' onClick={drowdowncustclick}>
                                        <img src={vectorarrow} alt='...' />
                                    </div>
                                    {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                        <ul className='vectoraarowdivomgulmaiul'>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                        </ul>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='poysttdiobnnnmmmnjm'>
                        <p className='conattaindptxtppnewallasa'>Search keywords <span>  </span></p>
                        <div className='compamyupdatepoksttmodaldiv'>
                            <div className='compamydinfodivmdiobbdgppshgsadd'>

                                <input type="text" placeholder="Advertising" className="inpustxocntbusuitxtppnewallattyy" />
                            </div>
                            <div className='compamydinfodivmdiobbdgppshgsadd'>

                                <input type="text" placeholder="Advertising" className="inpustxocntbusuitxtppnewallattyy" />
                            </div>
                            <div className='compamydinfodivmdiobbdgppshgsadd'>

                                <input type="text" placeholder="Digital marketing" className="inpustxocntbusuitxtppnewallattyy" />
                            </div>


                        </div>
                    </div>

                    <div className='poysttdiobnnnmmmnjm'>
                        <div className='poysttdiobnnnmmmnjmdivflxs'>
                            <div className='compamydinfodivmdiobbdgppshg'>
                                <p className='conattaindptxtppnewallasa'>State <span> * </span></p>
                                <div className='dropwdowndivfrmbusineenewwlas'>
                                    <p className='vectoraarowdivomgulmaili'>
                                        Dubai
                                    </p>
                                    <div className='vectoraarowdivomgnenwlal' onClick={drowdowncustclick}>
                                        <img src={vectorarrow} alt='...' />
                                    </div>
                                    {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                        <ul className='vectoraarowdivomgulmaiul'>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Dubai
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Dubai
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Dubai
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Dubai
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Dubai
                                            </li>
                                        </ul>
                                    </div>}
                                </div>
                            </div>

                            <div className='compamydinfodivmdiobbdgppshg'>
                                <p className='conattaindptxtppnewallasa'>Country <span> * </span></p>
                                <div className='dropwdowndivfrmbusineenewwlas'>
                                    <p className='vectoraarowdivomgulmaili'>
                                        United arab emirate
                                    </p>
                                    <div className='vectoraarowdivomgnenwlal' onClick={drowdowncustclick}>
                                        <img src={vectorarrow} alt='...' />
                                    </div>
                                    {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                        <ul className='vectoraarowdivomgulmaiul'>
                                            <li className='vectoraarowdivomgulmaili'>
                                                United arab emirate
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                United arab emirate
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                United arab emirate
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                United arab emirate
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Dubai
                                            </li>
                                        </ul>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="svavtatvvbtnnmdivmaokin">
                        <button className="svavtatvvbtnnmsendferdgf">Post</button>
                    </div>
                </div>






            </div>
        </div>
    )
}

export default Updatepostmoda
