import React from 'react'
import { Link } from 'react-router-dom';

const EarnedBalanceTransfer = () => {
    const EarnedBalancebox = [
        { id: 1, bgcolor: "#B85EFB", cardhed: "Total Earned", cardamount: "$217.84", linkpage: "/total-earned", },
        { id: 2, bgcolor: "#63BEFF", cardhed: "Available Balance", cardamount: "$100.84", linkpage: "/available-balance", },
        { id: 3, bgcolor: "#63A2FF", cardhed: "Transfer now", cardamount: "$100.84", linkpage: "/transfer-now", },
    ];
    return (
        <>
            <section className='EarnedBalanceTransfer_sec'>
                {EarnedBalancebox.map((items, index) => (

                    <div className='EarnedBalanceTransfer_box' key={index} style={{ backgroundColor: items.bgcolor, }}>
                        <h6 className='cardheding'>{items.cardhed}</h6>
                        <h4 className='cardamount'>{items.cardamount}</h4>

                        <div className='downarrow_btn'>
                            <Link to={items.linkpage} className='btn'><i className="fa-solid fa-arrow-down"></i></Link>
                        </div>
                    </div>

                ))}

            </section>
        </>
    )
}

export default EarnedBalanceTransfer