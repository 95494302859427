import React, { useContext, useEffect } from 'react'
import { Colorcontext } from '../../Component/Colorcontext/ColorContext';
import Otherblmprofileedit from './Otherblmprofileedit';
import Otheruploadbannerblm from './Otheruploadbannerblm';
import Otheraboutusblmprofile from './Otheraboutusblmprofile';
import Otherservicesblmprofile from './Otherservicesblmprofile';
import Othergalleryblmprofile from './Othergalleryblmprofile';
import OtherContactdetaiilsprofile from './OtherContactdetaiilsprofile';
import Othertaptorate from './Othertaptorate';
import Sendenqueryother from './Sendenqueryother';
import Othersociallinks from './Othersociallinks';
const Otherblmprofile = () => {
    const colordata = useContext(Colorcontext);
    useEffect(() => {

        colordata.setcolor(true);
        return () => {
            colordata.setcolor(false);
        };
    }, []);
    return (
        <>
            <section className='otherblmprofiledivmaoin '>
                <div className='cust_container'>
                    <div className='reposivecideforspecial'>
                        <div className='ownblmprofiledivleft'>
                            {/* For Tablet responsive code start  */}
                            <div className='repsonsivedivcls'>
                                <div className=' oenblmsnfidivmainleft'>
                                    <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                        <Otherblmprofileedit />
                                    </div>

                                    <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                        <Sendenqueryother />
                                    </div>

                                    <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                        <Othertaptorate />
                                    </div>

                                    <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                        <Othersociallinks />
                                    </div>
                                </div>

                                <div className=' oenblmsnfidivmainright'>
                                <div className='oenblmsnfdrpbapbbtm  responsivdedivmain'>
                                        <Otheruploadbannerblm />
                                    </div>
                                    <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                        <OtherContactdetaiilsprofile />
                                    </div>
                                  
                                </div>

                            </div>
                            {/* For Tablet responsive code end */}
                            <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                <Otherblmprofileedit />
                            </div>
                            <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                <Othertaptorate />
                            </div>
                            <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                <Sendenqueryother />
                            </div>
                            <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                <OtherContactdetaiilsprofile />
                            </div>
                            <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                <Othersociallinks />
                            </div>


                        </div>
                        <div className='ownblmprofiledivright'>
                            <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain '>
                                <Otheruploadbannerblm />
                            </div>
                            <div className='oenblmsnfdrpbapbbtm'>
                                <Otheraboutusblmprofile />
                            </div>
                            <div className='oenblmsnfdrpbapbbtm'>
                                <Otherservicesblmprofile />
                            </div>
                            <div className='oenblmsnfdrpbapbbtm'>
                                <Othergalleryblmprofile />
                            </div>
                        </div>
                    </div>


                    {/* Mobile reponsive start */}

                    <div className="mobilereposiveprofile">
                        <div className='bannerprofileblmreposnive'>
                            <Otheruploadbannerblm />
                        </div>
                        <div className='mobilereposvispsicaldivflx'>
                            <div className='eidteprofileblmreposnive'>
                                <Otherblmprofileedit />
                            </div>

                            <div className='viewsprofileprofileblmreposnive'>
                                <div className=''>
                                <Sendenqueryother />
                                </div>
                                <div className='bklmlposprofileprofileblmreposniveother'>
                                <Othertaptorate />
                                </div>

                                {/* <div className=''>
                                    <Upgradeprofile />
                                </div> */}
                            </div>
                        </div>
                        <div className='contaallprofileblmreposnive'>
                        <OtherContactdetaiilsprofile />
                        </div>
                        <div className='contaallprofileblmreposnive'>
                        <Otheraboutusblmprofile />
                        </div>
                        <div className='contaallprofileblmreposnive'>
                        <Otherservicesblmprofile />
                        </div>
                        <div className='contaallprofileblmreposnive'>
                        <Othergalleryblmprofile />
                        </div>
                        <div className='contaallprofileblmreposnive'>
                        <Othersociallinks />
                        </div>
                    </div>

                    {/* Mobile reponsive End */}
                </div>
            </section>
        </>
    )
}

export default Otherblmprofile
