// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: "AIzaSyBV7mavlO2G8tpOKsrJHMuDcU-gQP0NKwk",
    authDomain: "neoosearch-chat.firebaseapp.com",
    databaseURL: "https://neoosearch-chat-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "neoosearch-chat",
    storageBucket: "neoosearch-chat.appspot.com",
    messagingSenderId: "743213921057",
    appId: "1:743213921057:web:50861e80750af5eda60460"
};


// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const storage = getStorage(app);