import React from 'react';
import { Link } from 'react-router-dom';
import LoginHeader from '../Layout/LoginHeader';
import image from "../Images/bg.jpg";

const ResetPassword = () => {
    return (
        <>
            <section className="authenSec">
                <div className="authenSecInn">
                    <div className="container">
                        <div className="authenSecInnDiv">
                            <div className="authenSecForm">
                                <div className="authenSecTop">
                                    <p className='resetmsg'><i class="fa-solid fa-triangle-exclamation"></i>Check your email for the confirmation link</p>
                                    <div className='forgetFig'>
                                    </div>
                                    <h4>Reset Password</h4>
                                    <p>Please enter your username or email address. A new password has been mailed to you.</p>
                                </div>

                                <div className="authenSecBtm">
                                    <form action="">
                                        <div className="form-group">
                                            <input type="email" placeholder='Email Id' />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" placeholder='Password' />
                                        </div>
                                        <Link to='/Memberownprofile' className='homeloginBtn'>Submit</Link>
                                    </form>
                                </div>

                            </div>
                            <div className="authenSecImg">
                                <img src={image} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ResetPassword