import React from 'react'
import cardimg from "../../Images/reward_cardall.png"

const RewardsDigitalcards = () => {
    return (
        <>
            <section className='RewardsDigitalcards_sec'>
                <div className='mainwrap'>
                    <div className='row'>
                        <div className='col-md-5 col-12'>
                            <div className='img_wrap'>
                                <img src={cardimg} className='img-fluid' alt='..' />
                            </div>
                        </div>
                        <div className='col-md-7 col-12'>
                            <div className='rightcontent'>
                                <h4 className='heading'>Neoo Search rewards Digital cards</h4>
                                <p className='text'>Take advantage of exclusive benefits when you become a member</p>
                            
                            
                                <div className='cardbox_wrap'>
                                    <div className='cardbox'>
                                        <div className='colorbox' style={{backgroundColor: "#ABABAB"}}></div>
                                        <p className='name'>Silver Card </p>
                                        <p className='points'>1500 points</p>
                                    </div>
                                    <div className='cardbox'>
                                        <div className='colorbox' style={{backgroundColor: "#C3922E"}}></div>
                                        <p className='name'>Gold Card</p>
                                        <p className='points'>3000 points</p>
                                    </div>
                                    <div className='cardbox'>
                                        <div className='colorbox' style={{backgroundColor: "#1B48BC"}}></div>
                                        <p className='name'>Platinum Card</p>
                                        <p className='points'>5000 points</p>
                                    </div>
                                </div>
                            
                            
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RewardsDigitalcards