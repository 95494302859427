import React from 'react'
import editicon from "../../Images/editicon.png";
const Servicesblmprofile = () => {

    const servicedata = [
        {
            servicatdatatxt: "Advertising"
        },
        {
            servicatdatatxt: "Content Strategy"
        },
        {
            servicatdatatxt: "Event Marketing"
        },
        {
            servicatdatatxt: "Brand Marketing"
        },
        {
            servicatdatatxt: " Advertising"
        },
        {
            servicatdatatxt: "Content Strategy"
        },
        {
            servicatdatatxt: "Event Marketing"
        },
        {
            servicatdatatxt: "Brand Marketing"
        },
    ]
    return (
        <div className='uploadbannerblmdiv'>
            <div className='uploadbannerdivnewmain'>
                <div className="consttactdetatlsarroedyrdiv">
                    <p className="cinnattddttdtsttspo">Services</p>
                    <div className="editeiconimg">
                        <img
                            src={editicon}
                            alt="..."
                        />
                    </div>
                </div>
                <div className='adversytfservicexedib'>
                    {servicedata.map((item,index) => {
                        return (
                            <p className='servicdesdetatcxttxpp'>
                                {item.servicatdatatxt}
                            </p>
                        )
                    })}

                {/* //    <span className='servicdesdetatcxttxpp'>
                //         Content Strategy
                //     </span>
                //     <span className='servicdesdetatcxttxpp'>
                //         Event Marketing
                //     </span>
                //     <span className='servicdesdetatcxttxpp'>
                //         Brand Marketing
                //     </span>
                //     <span className='servicdesdetatcxttxpp'>
                //         Advertising
                //     </span>  */}
                </div>
            </div>
        </div>
    )
}

export default Servicesblmprofile
