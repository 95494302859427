import React from "react";
import comlogo from "../Images/offerimg/Planet.png";
import barcode from "../Images/qr-code.jpg";

function GiftvoucherModal({ closemodal, QRModalImage }) {
  return (
    <>
      <section className="giftvoucher_modal">
        <div className="giftvouchermodal_body">
          <div className="leftstyle"></div>
          <div className="rightstyle"></div>

          <div className="close_btn">
            <button className="btn" onClick={() => closemodal(false)}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>

          <h4 className="heding">Gift voucher</h4>
          <div className="company_logo">
            <img src={QRModalImage?.Offer?.partnerLogo} className="img-fkuid" alt="..." />
          </div>
          <h6 className="entryticket">{QRModalImage?.Offer?.offerCatData?.categoryName}</h6>
          <p className="Valid">Valid for {QRModalImage?.Offer?.voucherValidity} Hrs</p>

          <div className="border"></div>

          <div className="barcode_wrap">
            <img src={QRModalImage?.voucherqrCode} className="img-fluid" alt=".." />
          </div>

          <p className="code">{QRModalImage?.offerCode} </p>
          <p className="text">
            This voucher must be presented before you can redeem it
          </p>
        </div>
      </section>
    </>
  );
}

export default GiftvoucherModal;
