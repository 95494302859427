import React from 'react'
import reficon from "../../Images/Refer and Earn/Refer.png"
import { Link } from 'react-router-dom'

const ReferEarnHeader = (props) => {
    const { headname, backbtn = false, monthdrowpdown = false, } = props;
    return (
        <>
            <section className='ReferEarnHeader_sec'>
                <div className='leftpart'>
                    {backbtn &&
                        <Link to="/refer-earn-dashboard" className='back_btn'>
                            <i className="fa-solid fa-arrow-left-long"></i>
                        </Link>
                    }
                    <div className='d-flex align-items-center'>
                        <img src={reficon} className='img-fluid' alt='...' />
                        <p className='head'>{headname}</p>
                    </div>

                    {monthdrowpdown &&
                        <div className="ReferEarndrowpdown_wrap">
                            <div className="form-group">
                                <select className="form-control">
                                    <option>Current month</option>
                                    <option>Prev month </option>
                                    <option>Next Month</option>
                                </select>
                            </div>
                        </div>
                    }

                </div>
                <div className='rightpart'>
                    <p className='amout'>Total: $217.84</p>
                    <Link to="/withdraw" className='withdraw_btn'>withdraw</Link>
                </div>


            </section>
        </>
    )
}

export default ReferEarnHeader