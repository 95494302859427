import React, { useState } from 'react'
import editicon from "../../Images/editicon.png";
import cameralogo from "../../Images/cameralogo.png";
import likec from "../../Images/like/like-c.png";
import { Rating } from 'react-simple-star-rating';
import logoownblm from "../../Images/logoownblm.png"
import Uploadlogomodal from '../../Modal/Uploadlogomodal';
import Editlogogetmodal from '../../Modal/Editlogogetmodal';
const EditProfileblm = () => {
    const [rating, setRating] = useState(0)
    const [editlogomodal, seteditlogomodal] = useState(false);
    const [editlogomaodalaa, setEditlogomaodalaa] = useState(false)
    // Catch Rating value
    const handleRating = () => {
        setRating()
    }

    const editlogomodalclick = () => {
        seteditlogomodal(!editlogomodal)
    }

    const editlogoclickspcl = () => {
        setEditlogomaodalaa(!editlogomaodalaa)
    }


    return (
        <>
            <div className='editprofileblm ' >
                <div className='editeiconimg' onClick={editlogoclickspcl}>
                    <img src={editicon} alt='...' />
                </div>
                <div className='editprofile' onClick={editlogomodalclick}>
                    <div className='cameralogodivimgabslt'>

                        <div className='cameralogodivimg'>
                            <img src={cameralogo} alt='...' />
                        </div>
                        <p className='uplsdttxttppshji'>Upload Logo</p>
                        <p className='pixelsttxttppio'>(Pixel Size : 000 x 000 PNG/JPEG )</p>
                    </div>

                    <div className='profileimhaggemaindib'>
                        <img src={logoownblm} alt='...' />
                    </div>
                </div>
                <div className=''>
                    <p className='ocensttxttpppp'>Ocean infolab FZE</p>
                    <p className='adverttstyusuppp'>Advertising & Marketing</p>
                </div>

                <div className='likesfolwoerdivfxls'>
                    <span className='likstrtxttpp'>10 Likes</span>

                    <span className='likstrtxttpp'> 20 followers</span>
                </div>

                <div className='likedfollowdivflx'>
                    <button className='likedfollowlike'>
                        <img src={likec} alt='...' className='img-fluid' />
                        Like
                    </button>
                    <button className='foolowndnbyhbnbtn'>
                        Follow
                    </button>
                </div>

                {/* For Mobile responsive design Start */}

                <div className='likesfolwoermobilrres'>
                    <button className='likedfollowlike'>
                        <img src={likec} alt='...' className='img-fluid' />
                        Like
                    </button>
                    <span className='likstrtxttpp'>10 Likes</span>
                </div>

                <div className='likesfolwoermobilrres'>
                    <button className='foolowndnbyhbnbtn'>
                        Follow
                    </button>
                    <span className='likstrtxttpp'> 20 followers</span>
                </div>

                {/* For Mobile responsive design End */}


                <div className='starrating'>
                    {/* set initial value */}
                    <Rating onClick={handleRating} initialValue={rating} size={30} />
                </div>
            </div>

            {editlogomodal && <Uploadlogomodal closemodal={seteditlogomodal} />}

            {editlogomaodalaa && <Editlogogetmodal closemodal={setEditlogomaodalaa} />}

        </>
    )
}

export default EditProfileblm
