import React, { useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";
import icon from "../../Images/vender/Redeemedstats.png";
import { useDispatch, useSelector } from "react-redux";
import {
  RedeemStatsMonthly,
  RedeemStatsWeekly,
  progressbar,
} from "../../Redux/Slice/Offer";

function RedeemedStatsInnerPage() {
  const { RedeemStatsWeeklyData, RedeemStatsMonthlyData, progressbarData } =
    useSelector((state) => state.offer);
  const dispatch = useDispatch();
  //   total voucher count from different state

  const totalVoucherCount = progressbarData?.reduce(
    (accumulator, currentValue) => accumulator + currentValue.TotalvoucherCount,
    0
  );
  //   total redeem count from diferent state
  const RedeemComplete = progressbarData?.reduce(
    (accumulator, currentValue) => accumulator + currentValue.RedeemComplete,
    0
  );

  console.log(progressbarData, "RedeemStatsWeeklyData");
  console.log(RedeemComplete, "RedeemComplete");
  //   CircularProgressbar for weekly and monthly stats
  const chatarea = [
    {
      id: 1,
      percentage: RedeemStatsWeeklyData?.redemptionPercentage,
      Week: "Current Week",
      totalwork:
        RedeemStatsWeeklyData?.redeemCount +
        "/" +
        RedeemStatsWeeklyData?.vocherTotal,
      text: "Remaining on target",
      days:
        RedeemStatsWeeklyData?.remainingpercentage +
        "% " +
        "/" +
        RedeemStatsWeeklyData?.remainingDays +
        " days",
    },
    {
      id: 1,
      percentage: RedeemStatsMonthlyData?.redemptionPercentage,
      Week: "Current Month",
      totalwork:
        RedeemStatsMonthlyData?.redeemCount +
        "/" +
        RedeemStatsMonthlyData?.vocherTotal,
      text: "Remaining on target",
      days:
        RedeemStatsMonthlyData?.remainingpercentage +
        "% " +
        "/" +
        RedeemStatsMonthlyData?.remainingDays +
        " days",
    },
  ];

  const ProgressBar = [
    {
      id: 1,
      name: "Abudhabi",
      parsent: "%82",
      width: "82%",
      valuen: 82,
      amount: "820",
    },
    {
      id: 2,
      name: "Dubai",
      parsent: "%42",
      width: "42%",
      valuen: 42,
      amount: "420",
    },
    {
      id: 3,
      name: "Sharjah",
      parsent: "%65",
      width: "65%",
      valuen: 65,
      amount: "65",
    },
    {
      id: 4,
      name: "Ajman",
      parsent: "%50",
      width: "50%",
      valuen: 50,
      amount: "50",
    },
    {
      id: 5,
      name: "Umm Al Quwain",
      parsent: "%65",
      width: "65%",
      valuen: 65,
      amount: "200",
    },
    {
      id: 6,
      name: "Ras Al-Khaimah",
      parsent: "%65",
      width: "65%",
      valuen: 65,
      amount: "140",
    },
    {
      id: 7,
      name: "Fujairah",
      parsent: "%55",
      width: "55%",
      valuen: 55,
      amount: "120",
    },
  ];

  useEffect(() => {
    dispatch(RedeemStatsWeekly());
    dispatch(RedeemStatsMonthly());
    dispatch(progressbar());
  }, []);
  return (
    <section className="redeemedstatsinner_page">
      <div className="cust_container">
        <div className="d-flex align-items-center justify-content-between toparea">
          <Link to="/vender-dashboard" className="backbutton_btn">
            <i className="fa-solid fa-arrow-left-long"></i>
          </Link>
          <div className="heading">
            <img src={icon} className="img-fluid" alt=".." />
            Redeemed stats
          </div>
          <div className="blank"></div>
        </div>

        <ul className="redeemedStats_chathistory">
          {chatarea.map((item, index) => {
            return (
              <li className="boxtec" key={index}>
                <div className="left">
                  <CircularProgressbar
                    value={item.percentage}
                    text={`${item.percentage}%`}
                  />
                </div>
                <div className="right">
                  <h6 className="week">{item.Week}</h6>
                  <h4 className="totalwork">{item.totalwork}</h4>
                  <p className="text">{item.text}</p>
                  <h5 className="days">{item.days}</h5>
                </div>
              </li>
            );
          })}
        </ul>

        <div className="redeemedStats_redeemed">
          <h4 className="head">
            Total redeemed <span>800/1000</span>
          </h4>

          <ul className="list">
            {progressbarData?.map((item, index) => {
              return (
                <li className="boxwrap" key={index}>
                    <div className="bar">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <p className="name">{item?._id}</p>
                        <p className="parsentage">
                          %
                          {(item?.RedeemComplete / item?.TotalvoucherCount) *
                            100}
                        </p>
                      </div>

                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width:
                              (item?.RedeemComplete / item?.TotalvoucherCount) *
                                100 +
                              "%",
                          }}
                          aria-valuenow={item.valuen}
                          aria-valuemin={0}
                          aria-valuemax={item?.TotalvoucherCount}
                        />
                      </div>
                    </div>
                    <div className="amount">
                      <span>{item?.TotalvoucherCount}</span>
                    </div>
                  </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default RedeemedStatsInnerPage;
