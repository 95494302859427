import React from 'react'
import { Link } from 'react-router-dom'

const AvailableTransferBalance = () => {
  return (
    <>
       <section className='AvailableTransfer_Balance'>
             <table>
                <tr>
                    <td className='left'>Available Balance</td>
                    <td className='right'>$100.84</td>
                </tr>
                <tr>
                    <td className='left'>Vat 5%</td>
                    <td className='right'>$5.042</td>
                </tr>
                <tr>
                    <td className='left' style={{fontWeight: "700", color: "#000", }}>Total</td>
                    <td className='right' style={{fontWeight: "700",  color: "#000", }}>$95.798</td>
                </tr>
             </table>
             <div className='TransferNow_btn'>
                    <Link to="/success" className='btn'>Transfer Now</Link>
             </div>
       </section>
    </>
  )
}

export default AvailableTransferBalance