import React, { useEffect, useState } from "react";
import icon from "../../Images/vender/MyOffer.png";
import voucher from "../../Images/vender/Entervoucher.png";
import Scan from "../../Images/vender/Scanvoucher.png";
import logocom from "../../Images/vender/My Offer.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PartnersOwnOffer } from "../../Redux/Slice/Offer";
import { QrCameraPartnerVoucher } from "../QRCamera/QrCameraPartnerVoucher";

function MyOfferVender() {
  const [camera, setCamera] = useState(false);
  const dispatch = useDispatch();
  const { PartnersOwnOfferData } = useSelector((state) => state.offer);
  console.log(PartnersOwnOfferData, "eww");

  useEffect(() => {
    dispatch(PartnersOwnOffer());
  }, []);
  return (
    <>
      <section className="MyOfferVender_sec">
        <div className="top_area">
          <div className="heading">
            <img src={icon} className="img-fluid" alt=".." />
            My Offer
          </div>

          <div className="d-flex align-items-center">
            <button className="scanvoucher_btn"   onClick={() => setCamera(true)}>
              <img src={Scan} className="img-fluid" alt=".." />
              <span>Scan voucher</span>
            </button>

            <Link to="/user-voucher-code" className="entervoucher_btn">
              <img src={voucher} className="img-fluid" alt=".." />
              <span>Enter voucher number</span>
            </Link>
          </div>
        </div>

        <div className="myofferimg_wrap">
          <div className="myoffer_img">
            <img
              src={PartnersOwnOfferData?.[0]?.offerCoverImage}
              className="img-fluid"
              alt="..."
            />
          </div>
        </div>
        {camera && <QrCameraPartnerVoucher setCamera={setCamera} />}
      </section>
    </>
  );
}

export default MyOfferVender;
