import React, { useEffect, useRef, useState } from "react";
import profiledp from "../../Images/profileimg.png";
import { useDispatch, useSelector } from "react-redux";
import {
  OnetoOneChatIdname,
  SingleChat,
  chatList,
  setChatThreads,
} from "../../Redux/Slice/Chat";
import {
  getOwnProfile,
  getSingleUserProfile,
} from "../../Redux/Slice/PostSlice";
import { app, db } from "../../Firebase/FirebaseConfig";
import toast from "react-hot-toast";
import videoRenderIcon from "../../Images/youtube.png";
import fileRenderIcon from "../../Images/fileRenderIcon.png";
import {
  child,
  equalTo,
  get,
  increment,
  limitToLast,
  off,
  onValue,
  orderByChild,
  push,
  query,
  ref,
  set,
  update,
} from "firebase/database";
import moment from "moment";
import NoChat from "./NoChat";
import ChatHeader from "../../Component/ChatComponent/ChatHeader";
import ChatBody from "../../Component/ChatComponent/ChatBody";
import ChatFooter from "../../Component/ChatComponent/ChatFooter";
import Forwardmessagemodal from "../../Modal/Forwardmessagemodal";
import {
  getDownloadURL,
  getStorage,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { initializeApp } from "firebase/app";
import Chatcontentbigmodalown from "../../Modal/Chatcontentbigmodalown";

const SingleChatArea = () => {
  const modalRef = useRef(null);
  const storage = getStorage(app);
  const [isTyping, setIsTyping] = useState(false);
  const [InputMsg, setInputMsg] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [typingStatus, setTypingstatus] = useState([]);
  const [statusData, setStatusData] = useState(null); //status for offline and online
  const [RecieverDetails, setRecieverDetails] = useState(null); //status for offline and online
  const [receiverStatus, setReceiverStatus] = useState({});
  const [isBlocked, setIsBlocked] = useState(false);
  const [whoBlocked, setWhoBlocked] = useState(null);
  const [whoIs, setWhoIs] = useState(null);
  const [receiverTyping, setReceiverTyping] = useState(false);
  const [forwqardektemodal, setForwqardektemodal] = useState(false);
  const [forwardmodal, setForwardmodal] = useState(false);
  const [openModalIndex, setOpenModalIndex] = useState(null);
  const [forwardMessage, setForwardMessage] = useState(null);
  const [previousReceiverId, setPreviousReceiverId] = useState(null);
  const [previewMediaModal, setPreviewMediaModal] = useState({
    link: "",
    type: "",
  });
  // >>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  const [chatThread, setchatThread] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileUpload, setShowFileUpload] = useState(false);
  // >>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const [filePreview, setFilePreview] = useState(null);
  const [chatcontentbigmodal, setchatcontentbigmodal] = useState(false);
  const [chatList, setChatList] = useState(null);
  const lastMessageRef = useRef(null);
  // console.log("filePreviewdfg", filePreview);
  const todaysDate = moment(Date.now()).format("MMMM DD, YYYY");

  const dispatch = useDispatch();
  const { chatListData, singleChatData, RecieverID, chatThreads } = useSelector(
    (state) => state?.chat
  );
  const { UserProfileID, SingleUserData } = useSelector((state) => state?.app);
  const OwnId = UserProfileID?._id;
  const roomId = [OwnId, RecieverID].sort().join("-");
  const RecieverTypingStatus = typingStatus.filter(
    (item) => item?.id === RecieverID
  );

  useEffect(() => {
    const chatListRef = ref(db, `chatList/${OwnId}/${RecieverID}`);
    const unsubscribe = onValue(chatListRef, (snapshot) => {
      if (snapshot.exists()) {
        const chatData = snapshot.val();
        setIsBlocked(chatData.isBlocked); // Set the block status
        setWhoBlocked(chatData.whoBlocked);
        setWhoIs(chatData?.userName);
      }
    });

    return () => unsubscribe();
  }, [OwnId, RecieverID]);

  useEffect(() => {
    const chatListRef = ref(db, `chatList/${RecieverID}/${OwnId}`);
    const unsubscribe = onValue(chatListRef, (snapshot) => {
      if (snapshot.exists()) {
        const chatData = snapshot.val();
        setIsBlocked(chatData.isBlocked); // Set the block status
        setWhoBlocked(chatData.whoBlocked);
      }
    });

    return () => unsubscribe();
  }, [OwnId, RecieverID]);

  useEffect(() => {
    const unsubscribe = (snapshot) => {
      if (snapshot.exists()) {
        if (snapshot.val().seenCount != 0) {
          update(ref(db, `chatList/${OwnId}/${RecieverID}`), {
            seenCount: 0,
          });
        }
      }
    };
    if (previousReceiverId == RecieverID) {
      onValue(ref(db, `chatList/${OwnId}/${RecieverID}`), unsubscribe);
    } else {
      if (RecieverID) {
        setPreviousReceiverId(RecieverID);
        off(ref(db, `chatList/${OwnId}/${previousReceiverId}`), "value");
      }
    }

    // return () =>
  }, [RecieverID, previousReceiverId]);

  // recievers details
  useEffect(() => {
    dispatch(getSingleUserProfile(RecieverID));
  }, [RecieverID]);

  // deletechat for current user
  const deleteChatForUser = () => {
    if (!OwnId || !RecieverID) return;

    const chatroomID = [OwnId, RecieverID].sort().join("-");
    const chatroomRef = ref(db, "chatrooms/" + chatroomID);
    const messagesRef = child(chatroomRef, "messages");

    get(messagesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const messages = snapshot.val();
          Object.keys(messages).forEach((key) => {
            const messageRef = child(messagesRef, key);
            update(messageRef, {
              deletedFor: [...(messages[key].deletedFor || []), OwnId],
            });
          });
        }
      })
      .catch((error) => {
        console.error("Error deleting messages: ", error);
      });
  };

  const deleteMessage = (messageToDelete) => {
    const chatroomID = [OwnId, RecieverID].sort().join("-");
    const chatroomRef = ref(db, `chatrooms/${chatroomID}`);
    const messageRef = child(chatroomRef, `messages/${messageToDelete.id}`);

    update(messageRef, {
      deletedFor: [...(messageToDelete.deletedFor || []), OwnId],
    })
      .then(() => {
        console.log("Message deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting message: ", error);
      });
  };
  const chatcontentclickmodal = (link, type) => {
    setchatcontentbigmodal(!chatcontentbigmodal);
    setPreviewMediaModal((prev) => ({
      ...prev,
      link: link,
      type: type,
    }));
  };
  const sendMessage = () => {
    if (!InputMsg.trim()) return toast.error("Write some message");

    const chatroomID = [OwnId, RecieverID].sort().join("-");
    const chatroomRef = ref(db, "chatrooms/" + chatroomID);
    const messagesRef = child(chatroomRef, "messages");

    const msgData = {
      content: InputMsg,
      senderID: OwnId,
      receiverID: RecieverID,
      timestamp: moment(Date.now()).format("MMMM DD, YYYY"),
      time: moment(Date.now()).format("HH:mm"),
      dateTime: moment().format(),
      seen: false,
      type: "text", // Added this line
    };

    const newMessageRef = push(messagesRef);
    set(newMessageRef, msgData)
      .then(() => {
        chkChatlistAndCreate();
        setInputMsg("");
      })
      .catch((error) => {
        console.error("Error sending message: ", error);
        toast.error("Error sending message");
      });

    // Update chat list logic here...
    const updates = {};
    updates[`chatList/${OwnId}/${RecieverID}/lastMessage`] = msgData.content;
    updates[`chatList/${OwnId}/${RecieverID}/lastMessageTimestamp`] =
      msgData.dateTime;
    updates[`chatList/${OwnId}/${RecieverID}/lastMessaged`] = OwnId;
    updates[`chatList/${OwnId}/${RecieverID}/type`] = msgData?.type;
    updates[`chatList/${OwnId}/${RecieverID}/seenCount`] = 0;

    updates[`chatList/${RecieverID}/${OwnId}/lastMessage`] = msgData.content;
    updates[`chatList/${RecieverID}/${OwnId}/lastMessageTimestamp`] =
      msgData.dateTime;
    updates[`chatList/${RecieverID}/${OwnId}/lastMessaged`] = OwnId;
    updates[`chatList/${RecieverID}/${OwnId}/type`] = msgData?.type;
    updates[`chatList/${RecieverID}/${OwnId}/seenCount`] = increment(1);

    update(ref(db), updates);
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<<<
  // >>>>Handle File Upload Modal<<<<
  const handlePaperclipClick = () => {
    setShowFileUpload((prev) => !prev);
    if (showFileUpload) {
      setSelectedFile(null);
      setFilePreview(null);
    }
  };

  // >>>>handle File Upload Change<<<<
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      console.log("FileFile : ", file);

      // Generate a preview if it's an image
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFilePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  const sendFile = async (file) => {
    if (!file) return;

    const chatroomID = [OwnId, RecieverID].sort().join("-");
    const chatroomRef = ref(db, "chatrooms/" + chatroomID);
    const messagesRef = child(chatroomRef, "messages");

    try {
      const fileStorageRef = storageRef(
        storage,
        `uploads/${OwnId}/${file.name}`
      );

      const snapshot = await uploadBytes(fileStorageRef, file, {
        contentType: "application/octet-stream",
      });

      const downloadURL = await getDownloadURL(fileStorageRef);

      const fileData = {
        content: file.name,
        senderID: OwnId,
        receiverID: RecieverID,
        timestamp: moment(Date.now()).format("MMMM DD, YYYY"),
        time: moment(Date.now()).format("HH:mm"),
        dateTime: moment().format(),
        type: file.type.startsWith("image/")
          ? "image"
          : file.type.startsWith("video/")
          ? "video"
          : "file",
        fileUrl: downloadURL,
      };

      const newMessageRef = push(messagesRef);
      await set(newMessageRef, fileData);

      setSelectedFile(null);
      setFilePreview(null);
      setShowFileUpload(false);

      // Update chat list data for both sender and receiver
      const updates = {};
      updates[`chatList/${OwnId}/${RecieverID}/lastMessage`] =
        fileData?.content;
      updates[`chatList/${OwnId}/${RecieverID}/type`] = fileData?.type;
      updates[`chatList/${OwnId}/${RecieverID}/lastMessageTimestamp`] =
        fileData.dateTime;
      updates[`chatList/${OwnId}/${RecieverID}/lastMessaged`] = OwnId;
      updates[`chatList/${OwnId}/${RecieverID}/seenCount`] = 0;

      updates[`chatList/${RecieverID}/${OwnId}/lastMessage`] =
        fileData?.content;
      updates[`chatList/${RecieverID}/${OwnId}/type`] = fileData?.type;
      updates[`chatList/${RecieverID}/${OwnId}/lastMessageTimestamp`] =
        fileData.dateTime;
      updates[`chatList/${RecieverID}/${OwnId}/lastMessaged`] = OwnId;
      updates[`chatList/${RecieverID}/${OwnId}/seenCount`] = increment(1);

      await update(ref(db), updates);
    } catch (error) {
      console.error("Error sending file: ", error);
    }
  };

  const forwrdclickmodal = (text) => {
    setForwardmodal(!forwardmodal);
    setForwardMessage(text);
  };
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const mesggaedeletforewardclick = (index) => {
    if (openModalIndex === index) {
      setOpenModalIndex(null); // Close the modal if it's already open
    } else {
      setOpenModalIndex(index); // Open the modal for the specific message
    }
  };

  useEffect(() => {
    // Function to handle clicks outside the modal
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenModalIndex(null);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const triggerDownload = async (url, filename) => {
    try {
      // const response = await fetch(url, {
      //   method: 'GET',
      //   headers: {
      //     'Content-Type': 'application/octet-stream',
      //   },
      // });
      // const blob = await response.blob();

      const link = document.createElement("a");
      // const fileUrl = window.URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", filename);
      // link.setAttribute('target', '_blank')
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };

  const renderMessage = (message, index) => {
    switch (message.type) {
      case "text":
        return (
          <div className="messageanddeletforwraddivflx">
            {/* <a target="_blank" rel="noopener noreferrer" download={true} href="https://firebasestorage.googleapis.com/v0/b/neoosearch-chat.appspot.com/o/uploads%2F6686604ddbe4ec9ee8d82454%2F5thSeptCase.jpg?alt=media&token=b0a27ee2-b0d6-4421-a001-96030d9d928d">Download</a> */}
            {message.content}
            <div
              className="dropdowmmdkiv"
              onClick={() => mesggaedeletforewardclick(index)}
            >
              <i className="fa-solid fa-caret-down"></i>
              {openModalIndex === index && (
                <div ref={modalRef} className="dropdowmmdkivdivmaoin">
                  <button
                    onClick={() => deleteMessage(message)}
                    className="delkteeooforwtsd"
                  >
                    Delete
                  </button>
                  <button
                    className="delkteeooforwtsd"
                    onClick={() => forwrdclickmodal(message)}
                  >
                    Forward
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      case "image":
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              className="dropdowmmdkiv"
              style={{ alignSelf: "flex-end" }}
              onClick={() => mesggaedeletforewardclick(index)}
            >
              <i className="fa-solid fa-caret-down"></i>
              {openModalIndex === index && (
                <div ref={modalRef} className="dropdowmmdkivdivmaoin">
                  <button
                    onClick={() => deleteMessage(message)}
                    className="delkteeooforwtsd"
                  >
                    Delete
                  </button>
                  <button
                    className="delkteeooforwtsd"
                    onClick={() =>
                      triggerDownload(message.fileUrl, message.content)
                    }
                  >
                    Download
                  </button>
                  <button
                    className="delkteeooforwtsd"
                    onClick={() => forwrdclickmodal(message)}
                  >
                    Forward
                  </button>
                </div>
              )}
            </div>

            <div
              className=""
              onClick={() => chatcontentclickmodal(message?.fileUrl, "image")}
            >
              <img
                src={message.fileUrl}
                alt={message.content}
                style={{ maxWidth: "200px", borderRadius: "10px" }}
              />
            </div>
          </div>
        );
      case "file":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "150px",
            }}
          >
            <div
              className="dropdowmmdkiv"
              style={{ alignSelf: "flex-end" }}
              onClick={() => mesggaedeletforewardclick(index)}
            >
              <i className="fa-solid fa-caret-down"></i>
              {openModalIndex === index && (
                <div ref={modalRef} className="dropdowmmdkivdivmaoin">
                  <button
                    onClick={() => deleteMessage(message)}
                    className="delkteeooforwtsd"
                  >
                    Delete
                  </button>
                  <button
                    className="delkteeooforwtsd"
                    onClick={() =>
                      triggerDownload(message.fileUrl, message.content)
                    }
                  >
                    Download
                  </button>
                  <button
                    className="delkteeooforwtsd"
                    onClick={() => forwrdclickmodal(message)}
                  >
                    Forward
                  </button>
                </div>
              )}
            </div>

            <div
              className=""
              onClick={() => triggerDownload(message.fileUrl, message.content)}
            >
              <img
                src={fileRenderIcon}
                alt="file"
                style={{ maxWidth: "80px", maxHeight: "80px" }}
              />
            </div>
            <a href={message.fileUrl} download>
              {message.content}
            </a>
          </div>
        );
      case "video":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "150px",
            }}
          >
            <div
              className="dropdowmmdkiv"
              style={{ alignSelf: "flex-end" }}
              onClick={() => mesggaedeletforewardclick(index)}
            >
              <i className="fa-solid fa-caret-down"></i>
              {openModalIndex === index && (
                <div ref={modalRef} className="dropdowmmdkivdivmaoin">
                  <button
                    onClick={() => deleteMessage(message)}
                    className="delkteeooforwtsd"
                  >
                    Delete
                  </button>
                  <button
                    className="delkteeooforwtsd"
                    onClick={() =>
                      triggerDownload(message.fileUrl, message.content)
                    }
                  >
                    Download
                  </button>
                  <button
                    className="delkteeooforwtsd"
                    onClick={() => forwrdclickmodal(message)}
                  >
                    Forward
                  </button>
                </div>
              )}
            </div>
            <div
              className=""
              onClick={() => chatcontentclickmodal(message.fileUrl, "video")}
            >
              <img
                src={videoRenderIcon}
                alt="video"
                style={{ maxWidth: "80px", maxHeight: "80px" }}
              />
            </div>
            {/* <a href={message.fileUrl} download>
              {message.content}
            </a> */}
          </div>
        );
      default:
        return null;
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  console.log("UserProfileID._id: ", UserProfileID._id);
  console.log("SingleUserData?.[0]?._id: ", SingleUserData?.[0]?._id);
  const chkChatlistAndCreate = () => {
    const roomId = [UserProfileID._id, SingleUserData?.[0]?._id]
      .sort()
      .join("-");

    console.log("weawawsdweafef", UserProfileID, SingleUserData?.[0]);
    get(
      ref(db, `chatList/${UserProfileID._id}/${SingleUserData?.[0]?._id}`)
    ).then((snapshot) => {
      if (snapshot.exists()) {
        console.log("sdwedw");
        dispatch(OnetoOneChatIdname(SingleUserData?.[0]?._id));
        // navigate("/chat");
        const updates = {};
        updates[
          `chatList/${UserProfileID._id}/${SingleUserData?.[0]?._id}/userName`
        ] = SingleUserData?.[0]?.personName;
        updates[
          `chatList/${UserProfileID._id}/${SingleUserData?.[0]?._id}/userProfilePicture`
        ] = SingleUserData?.[0]?.profilePhoto;
        updates[
          `chatList/${SingleUserData?.[0]?._id}/${UserProfileID._id}/userName`
        ] = UserProfileID.personName;
        updates[
          `chatList/${SingleUserData?.[0]?._id}/${UserProfileID._id}/userProfilePicture`
        ] = UserProfileID.profilePhoto;
        updates[
          `chatList/${UserProfileID._id}/${SingleUserData?.[0]?._id}/remoteId`
        ] = SingleUserData?.[0]?._id;
        updates[
          `chatList/${SingleUserData?.[0]?._id}/${UserProfileID._id}/remoteId`
        ] = UserProfileID._id;
        update(ref(db), updates);
        updates[
          `chatList/${UserProfileID._id}/${SingleUserData?.[0]?._id}/chatroomID`
        ] = roomId;
        updates[
          `chatList/${SingleUserData?.[0]?._id}/${UserProfileID._id}/chatroomID`
        ] = roomId;
        update(ref(db), updates);
      } else {
        let remoteData = {
          chatroomID: roomId,
          lastMessage: "",
          lastMessageTimestamp: "",
          lastMessaged: "",
          remoteId: SingleUserData?.[0]?._id,
          userName: SingleUserData?.[0]?.personName,
          userProfilePicture: SingleUserData?.[0]?.profilePhoto,
          seenCount: 0,
        };
        console.log(remoteData, "remoteData");
        set(
          ref(db, `chatList/${UserProfileID._id}/${SingleUserData?.[0]?._id}`),
          remoteData
        ).then((result) => {
          let myData = {
            chatroomID: roomId,
            lastMessage: "",
            lastMessageTimestamp: "",
            lastMessaged: "",
            remoteId: UserProfileID._id,
            userName: UserProfileID.personName,
            userProfilePicture: UserProfileID.profilePhoto,
            seenCount: 0,
          };
          console.log(myData, "remoteData");
          set(
            ref(
              db,
              `chatList/${SingleUserData?.[0]?._id}/${UserProfileID._id}`
            ),
            myData
          ).then(() => {
            dispatch(OnetoOneChatIdname(SingleUserData?.[0]?._id));
            // navigate("/chat");
          });
        });
      }
    });
  };

  // message seen update
  const markMessagesAsSeen = () => {
    if (!OwnId || !RecieverID) return; // Ensure OwnId and RecieverID are valid

    const chatroomID = [OwnId, RecieverID].sort().join("-");
    const chatroomRef = ref(db, "chatrooms/" + chatroomID);
    const messagesRef = child(chatroomRef, "messages");

    get(messagesRef).then((snapshot) => {
      if (snapshot.exists()) {
        const messages = snapshot.val();
        Object.keys(messages).forEach((key) => {
          if (messages[key].receiverID === OwnId && !messages[key].seen) {
            const messageRef = child(messagesRef, key);
            update(messageRef, { seen: true });
          }
        });
      }
    });
  };

  // message istyping update
  const handleTyping = () => {
    if (!isTyping) {
      setIsTyping(true);

      // Update typing status in Firebase
      updateTypingStatus(roomId, OwnId, true);

      // Remove typing status after a delay
      setTimeout(() => {
        setIsTyping(false);
        updateTypingStatus(roomId, OwnId, false);
      }, 3000); // 3 seconds delay
    }
  };

  // Function to update typing status in Firebase
  const updateTypingStatus = (roomId, userId, isTyping) => {
    const typingRef = ref(db, `chatrooms/${roomId}/TypingStatus/${userId}`);
    set(typingRef, { isTyping })
      .then(() => console.log("Typing status updated successfully"))
      .catch((error) => console.error("Error updating typing status:", error));
  };

  // Recivers status typing or online offile status
  // Fetch typing status for the receiver
  const fetchTypingStatus = () => {
    const typingRef = ref(db, `chatrooms/${roomId}/TypingStatus/${RecieverID}`);
    onValue(typingRef, (snapshot) => {
      const typingStatus = snapshot.val();
      // Update receiver's typing status in state
      setReceiverTyping(typingStatus ? typingStatus.isTyping : false);
    });
  };

  // get single User
  useEffect(() => {
    const fetchReceiverTypingStatus = () => {
      const typingRef = ref(
        db,
        `chatrooms/${roomId}/TypingStatus/${RecieverID}`
      );
      onValue(typingRef, (snapshot) => {
        const typingStatus = snapshot.val();
        setReceiverTyping(typingStatus ? typingStatus.isTyping : false);
      });
    };

    if (RecieverID) {
      fetchReceiverTypingStatus();
    }
  }, [roomId, RecieverID]);
  useEffect(() => {
    dispatch(getOwnProfile());
  }, []);

  // chat history
  useEffect(() => {
    if (OwnId && RecieverID) {
      console.log("data123 : ", OwnId, RecieverID);
      const chatroomID = [OwnId, RecieverID].sort().join("-");
      console.log("data12 : ", chatroomID);
      const chatroomRef = ref(db, "chatrooms/" + chatroomID);
      const messagesRef = child(chatroomRef, "messages");
      const unsubscribe = onValue(messagesRef, (snapshot) => {
        // console.log("data12 : ", JSON.stringify(snapshot));
        if (snapshot.exists() && RecieverID) {
          const messages = snapshot.val();
          const messageList = Object.keys(messages)
            .map((key) => ({
              id: key,
              ...messages[key],
            }))
            .filter((message) => !message.deletedFor?.includes(OwnId));
          setChatHistory(messageList);
          markMessagesAsSeen();
        } else {
          setChatHistory([]);
        }
      });

      return () => unsubscribe();
    }
  }, [OwnId, RecieverID]);
  // reciever typing status
  useEffect(() => {
    if (OwnId && RecieverID) {
      const chatroomID = [OwnId, RecieverID].sort().join("-");
      const chatroomRef = ref(db, "chatrooms/" + chatroomID);
      const messagesRef = child(chatroomRef, "TypingStatus");

      const unsubscribe = onValue(messagesRef, (snapshot) => {
        if (snapshot.exists() && RecieverID) {
          const messages = snapshot.val();
          const messageList = Object.keys(messages).map((key) => ({
            id: key,
            ...messages[key],
          }));
          setTypingstatus(messageList);
          markMessagesAsSeen();
        } else {
          setTypingstatus([]);
        }
      });

      return () => unsubscribe();
    }
  }, [OwnId, RecieverID]);
  // reciever status or last seen
  useEffect(() => {
    if (RecieverID) {
      // Reference to the user's status data
      const statusRef = ref(db, `status/${RecieverID}`);

      // Set up a listener for changes to the status data
      const unsubscribe = onValue(statusRef, (snapshot) => {
        if (snapshot.exists()) {
          // If data exists, set it to the state
          setStatusData(snapshot.val());
        } else {
          // Handle case where no data exists
          setStatusData(null);
        }
      });

      // Cleanup the listener on unmount
      return () => unsubscribe();
    }
  }, [RecieverID]);

  //recievers profile picture and other details
  useEffect(() => {
    if (RecieverID) {
      // Reference to the user's status data
      const statusRef = ref(db, `users/${RecieverID}`);

      // Set up a listener for changes to the status data
      const unsubscribe = onValue(statusRef, (snapshot) => {
        if (snapshot.exists()) {
          // If data exists, set it to the state
          setRecieverDetails(snapshot.val());
        } else {
          // Handle case where no data exists
          setRecieverDetails(null);
        }
      });

      // Cleanup the listener on unmount
      return () => unsubscribe();
    }
  }, [RecieverID]);

  // Function to scroll the last message into view
  const scrollToLastMessage = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToLastMessage();
  }, [chatHistory]);

  return (
    <>
      <div className="right-container">
        {/*header */}
        <ChatHeader
          RecieverDetails={RecieverDetails}
          RecieverTypingStatus={RecieverTypingStatus}
          statusData={statusData}
          deleteChatForUser={deleteChatForUser}
          OwnId={OwnId}
          RecieverID={RecieverID}
          isBlocked={isBlocked}
          whoBlocked={whoBlocked}
          whoIs={whoIs}
        />
        {/*chat-container */}

        <ChatBody
          chatHistory={chatHistory}
          OwnId={OwnId}
          renderMessage={renderMessage}
          deleteMessage={deleteMessage}
        />

        {/* // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}
        {isBlocked ? (
          <div className="blocked-chat">
            <h2>Blocked by the user</h2>
          </div>
        ) : (
          <ChatFooter
            handlePaperclipClick={handlePaperclipClick}
            InputMsg={InputMsg}
            setInputMsg={setInputMsg}
            handleTyping={handleTyping}
            sendMessage={sendMessage}
            showFileUpload={showFileUpload}
            handleFileChange={handleFileChange}
            filePreview={filePreview}
            sendFile={sendFile}
            setSelectedFile={setSelectedFile}
            whoIs={whoIs}
          />
        )}
        {/* // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}
      </div>

      {forwardmodal && (
        <Forwardmessagemodal
          closemodal={() => setForwardmodal(false)}
          OwnId={OwnId}
          forwardMessage={forwardMessage}
          setForwardMessage={setForwardMessage}
        />
      )}

      {chatcontentbigmodal && (
        <Chatcontentbigmodalown
          closemodal={setchatcontentbigmodal}
          previewMediaModal={previewMediaModal}
        />
      )}
    </>
  );
};

export default SingleChatArea;
