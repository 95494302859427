import React from 'react';
import LoginHeader from "../Layout/LoginHeader";
import logo from "../Images/Neoo search logo purple.png";

const Loader = () => {
    return (
        <>
            <section className="loaderSec">
                <LoginHeader />

                <div className="loaderInn">
                    <div className="loadermid">
                        <div className="loaderFig">
                            <img src={logo} alt="img" />
                        </div>
                        
                        <div className="progress">
                            <div className="progress-value"></div>
                        </div>

                    </div>
                    <div className="loaderbtm">
                        <p className='loadersmlltxt'>Powered by</p>
                        <p className='loaderBigtxt'>Neoo Search FZE</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Loader