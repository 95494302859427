import React from 'react'
import "../../View/RewardsHomePage/RewardHomePage.css"
import RewardHomeBanner from './RewardHomeBanner'
import WelcomeToworld from './WelcomeToworld'
import RewardsDigitalcards from './RewardsDigitalcards'
import WhatNeooSearchbusiness from './WhatNeooSearchbusiness'

const RewardHomePage = () => {
  return (
    <>
       <div className='RewardHomePage'>
            <div className='cust_container'>
                <RewardHomeBanner />
                <WelcomeToworld />
                <RewardsDigitalcards />
                <WhatNeooSearchbusiness />
            </div>
       </div>
    </>
  )
}

export default RewardHomePage