import React, { useState } from 'react'
import msgiocn from "../../Images/msgiocn.png"
import Enqueymodal from '../../Modal/Enqueymodal';
const Sendenqueryother = () => {
    const [sendjqenquetmodal, setSendjqenquetmodal] = useState(false);

    const sendenquerytyclick = () => {
        setSendjqenquetmodal(!sendjqenquetmodal);

    }
    return (
        <>

            <div className='sendenquesrtydivmain'>
                <div className='sendwieidjnndnmainbbth'>
                    <button className='enquersrtsimgagmmubnnmgbtn' onClick={sendenquerytyclick}>
                        <div className='enquersrtsimgagmmub'>
                            <img src={msgiocn} alt='...' />
                        </div>
                        Send Equiry
                    </button>
                </div>
            </div>

             {sendjqenquetmodal && <Enqueymodal closemodal={setSendjqenquetmodal}/>}
        </>
    )
}

export default Sendenqueryother
