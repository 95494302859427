import React from 'react'
import { Link } from 'react-router-dom';

function AllEarningsBox() {
    const EarningsBox = [
        { id: 1, name: "Today Earnings", amount: "$108.92", bgcolor: "#67FFED", pagelink: '/today-earnings', },
        { id: 2, name: "Monthly Earnings", amount: "$217.84", bgcolor: "#BEFC8E", pagelink: '/monthly-earnings', },
        { id: 3, name: "Joined Members", amount: "200", bgcolor: "#D0B4FF", pagelink: '/joined-members', },
        { id: 4, name: "Joined Non - Members", amount: "500", bgcolor: "#6CD1FC", pagelink: '/joined-non-members', },
    ];
    return (
        <>
            <section className='AllEarningsBox_sec'>

                {EarningsBox.map((items, index) => (
                    <Link to={items.pagelink} className='EarningsBox_wrap' key={index} style={{ backgroundColor: items.bgcolor }}>
                        <p className='name'>{items.name}</p>
                        <p className='amount'>{items.amount}</p>
                    </Link>
                ))}

            </section>

            <Link className='SmartBusinesspack_btn'>Smart Business pack <i className="fa-solid fa-arrow-right"></i></Link>
        </>
    )
}

export default AllEarningsBox