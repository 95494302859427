import React from 'react'
import "./Searchhome.css";
import neofrontlogo from "../../Images/neofrontlogo.png";
import searchicon from "../../Images/searchicon.png"
import { useNavigate } from 'react-router-dom';
const Searchhome = () => {
     const navigate=useNavigate()
    const searchresultclick =()=>{
        navigate("/blmamberlist")
    }
  return (
     <>
        <section className='searchhomemain'>
                <div className='cust_container'>
                   
                        <div className='midownseacrhmaindivnew'>
                            <div className='logedserchfrminptnew'>
                                <input type='text' placeholder='Neoo search dubai' className='logedserchfrminptinptnew' />
                                <div className='searchiconimgnew' onClick={searchresultclick}>
                                    <img src={searchicon} alt='...' />
                                </div>
                            </div>
                        </div>
                  
                </div>
            </section>
     </>
  )
}

export default Searchhome
