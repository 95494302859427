import React, { useState } from 'react'
import editicon from "../../Images/editicon.png";
import cameralogo from "../../Images/cameralogo.png";
import blmbennerimg from "../../Images/blmbennerimg.png"
import EditProfileblm from './EditProfileblm';
import Editbannermodalbml from '../../Modal/Editbannermodalbml';
import Editbannerimagemodal from '../../Modal/Editbannerimagemodal';
const Uploadbannerblm = () => {
    const [bannermodalaedi,setBannermodalaedi]=useState(false);
    const [editbannermoadal,setEditbannermoadal]=useState(false)
    const editbannermodalclick=()=>{
        setBannermodalaedi(!bannermodalaedi)
    }
    const editbannerclcik =()=>{
        setEditbannermoadal(!editbannermoadal)
    }
    return (
        <>
      
        <div className='uploadbannerblmdiv '>
            <div className='uploadbannerdivbg'>
                <div className='editeiconimgspclnmew' onClick={editbannerclcik} >
                    <img src={editicon} alt='...' />
                </div>
                
                <div className='cameralogodivimgabslt' onClick={editbannermodalclick}>
                    {/* <div className='filedibbhsjj'>
                            <input type='file' className='filedibbhsjjdiflediv'/>
                        </div> */}
                    <div className='cameralogodivimg'>
                        <img src={cameralogo} alt='...' />
                    </div>
                    <p className='uplsdttxttppshji'>Upload Banner</p>
                    <p className='pixelsttxttppio'>(Pixel Size : 000 x 000 PNG/JPEG )</p>

                 
                </div>

                <div className="upoloafgsbabberfibommjge">
                    <img src={blmbennerimg} alt='...' />
                </div>
            </div>
        </div>
        {bannermodalaedi && <Editbannermodalbml closemodal={setBannermodalaedi}/>}
        {editbannermoadal && <Editbannerimagemodal closemodal={setEditbannermoadal}/>}
        </>
    )
}

export default Uploadbannerblm
