import React, { useEffect, useState } from "react";
import directory from "../../Images/Frame.png";
import like from "../../Images/framelike.png";
import whatsapp from "../../Images/whatsapp.png";
import call from "../../Images/Call.png";
import { Link, useLocation } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import CustomLoader from "../../CustomComponents/loader/CustomLoader";
import { HitLike, getSingleUserProfile } from "../../Redux/Slice/PostSlice";
import { useDispatch, useSelector } from "react-redux";
import likec from "../../Images/like/like-c.png";
import likew from "../../Images/like/like-w.png";
import toast from "react-hot-toast";

const NonMemberuserSavedProfile = () => {
  const [contactdetail, setContactdetail] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [UserMemberData, setUserMemberData] = useState({});
  const location = useLocation();
  const dispatch = useDispatch();
  const { SingleUserData } = useSelector((state) => state.app);
  // variable for points for api start
  const whatsappPoint = {
    profileId: SingleUserData?.[0]?._id,
    type: "whatsapp",
  };
  const callPoint = {
    profileId: SingleUserData?.[0]?._id,
    type: "call",
  };

  const whatsappNumber =
    SingleUserData?.[0]?.whats_code + SingleUserData?.[0]?.whatsappNo;
  const message = "Hi, I am Using Neoo Search Platform."; // Replace with your desired message
  // Encode the message and WhatsApp number for URL
  const encodedMessage = encodeURIComponent(message);
  const encodedWhatsAppNumber = encodeURIComponent(whatsappNumber);
  // Construct the WhatsApp URL with phone number and message
  const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodedWhatsAppNumber}&text=${encodedMessage}`;

  console.log(SingleUserData, "SingleUserData");
  const handledetailClick = () => {
    setContactdetail(!contactdetail);
  };

  // whatsApp Point
  const handleWhatsappClick = async () => {
    if (SingleUserData?.[0]?._id) {
      const res = await HttpClient.requestData(
        "getting-rewards",
        "POST",
        whatsappPoint
      );
      if (res && res?.status) {
        toast.success("Redirecting to the WhatsApp App...");
      } else {
        console.log("Cant add points");
      }

      window.open(whatsappUrl, "_blank");
    }
  };
  // call button
  const handleButtonClick = async () => {
    if (SingleUserData?.[0]?._id) {
      const res = await HttpClient.requestData(
        "getting-rewards",
        "POST",
        callPoint
      );
      if (res && res?.status) {
        toast.success("Calling the user...");
      } else {
        console.log("Cant add points");
      }
      window.location.href = `tel:${SingleUserData?.[0]?.phone_code}${SingleUserData?.[0]?.mobileNo}`;
    }
  };
  const getUserProfile = async () => {
    setisLoading(true);
    const res = await HttpClient.requestData("get-profile", "GET", {});
    console.log(res, "use profiler");
    if (res && res?.status) {
      setisLoading(false);
      setUserMemberData(res?.data);
    } else {
      setisLoading(false);
    }
  };
  //Saved contactlist
  const savedContactList = async () => {
    const res = await HttpClient.requestData("member-contacts", "GET", {});
    console.log(res, "resData");
    if (res && res?.status) {
      //   setsavedContacts(res?.data);
    }
  };

  // give likes
  const hitLike = async (id) => {
    const data = {
      id: SingleUserData?.[0]?._id,
    };
    // Dispatch HitLike action to update like count
    await dispatch(HitLike(data));

    // Dispatch action to fetch single user profile
    dispatch(getSingleUserProfile(location?.state?._id));
  };
  useEffect(() => {
    savedContactList();
  }, []);
  useEffect(() => {
    getUserProfile();
  }, []);
  useEffect(() => {
    dispatch(getSingleUserProfile(location?.state?._id));
  }, [location?.state?._id]);
  return (
    <section className="memberuserprofile">
      <CustomLoader loading={isLoading} />
      <div className="cust_container">
        <div className="contactbg">
          <div className="contctbrdr">
            <div className="cntntflx">
              <div className="mbrtusrprflflx">
                <div className="imgbrdrmbrt">
                  <img
                    src={
                      "https://st.depositphotos.com/2934765/53192/v/450/depositphotos_531920820-stock-illustration-photo-available-vector-icon-default.jpg"
                    }
                    className="img-fluid"
                    alt="..."
                  />
                </div>
                <div className="jhjmbmrtdiv">
                  <div className="d-flex align-items-center ">
                    <p className="jhjtcfggytxt">
                      {SingleUserData?.[0]?.gender}{" "}
                      {SingleUserData?.[0]?.personName}
                    </p>
                    <p className="mghtxtpp">
                      {SingleUserData?.[0]?.designation}
                    </p>
                  </div>
                  <h5 className="companyname">
                    {SingleUserData?.[0]?.companyName}
                  </h5>
                  <h6 className="digitalmarketing">
                    {SingleUserData?.[0]?.TypeOfCompany}
                  </h6>
                  <p className="tel_no">
                    Mobile:{" "}
                    <span>
                      {" "}
                      {SingleUserData?.[0]?.phone_code}{" "}
                      {SingleUserData?.[0]?.mobileNo}
                    </span>{" "}
                    / <span>l {SingleUserData?.[0]?.emirate}</span>
                  </p>
                  <div className="review_wrap">
                    <p className="like mr-2">
                      Likes: {SingleUserData?.[0]?.likeCount}
                    </p>
                    <p className="follower mr-2">Followers: 000</p>
                    <div className="star">
                      {/* <i className="fa-solid fa-star active"></i>
                      <i className="fa-solid fa-star active"></i> */}
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="upbtndivmain">
                {/* <button className="upbtndiv">Upgrade</button> */}
              </div>
            </div>
          </div>
        </div>

        <div className="contactbg">
          <div className="drctryDiv">
            <div className="drctryDivLeft">
              <div className="d-flex align-items-center ">
                <figure className="drctryDivFig" style={{ cursor: "pointer" }}>
                  {SingleUserData?.[0]?.mylikesStatus ? (
                    <img
                      src={likec}
                      className="img-fluid"
                      onClick={() => hitLike(SingleUserData?.[0]?._id)}
                      alt=".."
                    />
                  ) : (
                    <img
                      src={likew}
                      className="img-fluid"
                      onClick={() => hitLike(SingleUserData?.[0]?._id)}
                      alt=".."
                    />
                  )}
                </figure>

                <p>{SingleUserData?.[0]?.likeCount}</p>
                {/* {SingleUserData?.[0]?.mylikesStatus ? (
                    <span className="ml-1">Liked</span>
                  ) : (
                    <span className="ml-1">Like</span>
                  )} */}
              </div>

              <Link to="/Owncontactlist">
                <div className="d-flex align-items-center">
                  <figure className="drctryDivFig">
                    <img src={directory} alt="img" />
                  </figure>
                  <p>{}</p>
                </div>
              </Link>
            </div>

            <div className="drctryDivRght">
              <div onClick={handleWhatsappClick} style={{ cursor: "pointer" }}>
                <figure className="drctrCntctIcon">
                  <img src={whatsapp} alt="img" />
                </figure>
              </div>

              <div
                className="callButton"
                onClick={handleButtonClick}
                style={{ cursor: "pointer" }}
              >
                <figure className="drctrCntctIcon responsiveshow">
                  <img src={call} alt="img" />
                </figure>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="contactbg">
          <div className="cntntbrdr">
            <div className="flxcntnt">
              <p className="cntncttxt">Contact Details</p>
              <div className="ArrwiconDiv" onClick={handledetailClick}>
                <span
                  className={
                    contactdetail
                      ? "fa-solid fa-chevron-up"
                      : "fa-solid fa-chevron-down"
                  }
                ></span>
              </div>
            </div>
            {contactdetail && (
              <div className="">
                <div className="mt-3">
                  <p className="egltxtdiv">
                    {SingleUserData?.[0]?.companyName}
                  </p>
                  <p className="mdlfrntr">
                    {SingleUserData?.[0]?.TypeOfCompany}
                  </p>
                  <p className="ajmtxt">
                    {SingleUserData?.[0]?.emirate} |{" "}
                    {SingleUserData?.[0]?.country}
                  </p>
                </div>
                <div className="divcnctflx non_member">
                  <div className="brdrdivdtls">
                    <span className="txtdtlmn">
                      <i className="fa-solid fa-phone"></i>
                      +971
                    </span>
                  </div>

                  <div className="brdrdivdtls">
                    <span className="txtdtlmn">
                      <i className="fa-solid fa-mobile-screen-button"></i>
                      {SingleUserData?.[0]?.phone_code}{" "}
                      {SingleUserData?.[0]?.mobileNo}
                    </span>
                  </div>

                  <div className="brdrdivdtls">
                    <span className="txtdtlmn">
                      <i className="fa-brands fa-whatsapp"></i>
                      {SingleUserData?.[0]?.whats_code}{" "}
                      {SingleUserData?.[0]?.whatsappNo}
                    </span>
                  </div>

                  <div className="brdrdivdtls">
                    <span className="txtdtlmn">
                      <i className="fa-solid fa-envelope"></i>
                      {SingleUserData?.[0]?.emailId}
                    </span>
                  </div>

                  <div className="brdrdivdtls">
                    <span className="txtdtlmn">
                      <i className="fa-solid fa-globe"></i>
                      www.demo.com
                    </span>
                  </div>

                  <div className="brdrdivdtls">
                    <span className="txtdtlmn">
                      <i className="fa-solid fa-location-dot"></i>
                      Dubai. United Arab Emirates
                    </span>
                  </div>
                </div>
                <div className="dibmailflx">
                  {/* <div className="envelopdiv">
                  <span className="txtdtlmn">
                    <i className="fa-solid fa-envelope"></i>
                    {SingleUserData?.[0]?.emailId}
                  </span>
                </div> */}
                  {/* <div className="envelopdiv">
                  <span className="txtdtlmn">
                    <i className="fa-solid fa-globe"></i>
                    www.neoocard.com
                  </span>
                </div> */}
                </div>
                {/* <div className="divloctin">
                <span className="txtdtlmn">
                  <i className="fa-solid fa-location-dot"></i>
                  Business Center, Sharjah Publishing City Free Zone Dubai.
                  United Arab Emirates
                </span>
              </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NonMemberuserSavedProfile;
