import React from 'react'
import { Link } from 'react-router-dom'

const Chooseplan = () => {
   return (
      <>
         <section className='choseplan'>
            <div className='choosplantxdivmain'>
               <h4 className='choosplantxttpp'>Choose Your Plan</h4>
               <p className='fromstartuptxttp'>From Startup to Enterprise</p>
               <p className='pisckktxtpp'>Pick an account plan that fits your marketing</p>
            </div>

            <div className='chooseplandivmaon'>
               <div className='chooseplandivmaondivflx'>
                  <div className='chooseplandivmaondivbrdr'>
                     <div className='chooseplandivmaondivbrdrmain'>
                        <button className='basiuicvaytbtu'>Basic</button>
                        <div className=''>
                           <p className='freesttxppp'>Free</p>
                           <p className='auuhshtxttpp'>1 year</p>
                        </div>

                        <div className='baysyysttxtpppdsidnbmai'>
                           <p className='baysyysttxtppp'>Basic listing</p>
                           <ul className='busuyyasbasytxtxtppul'>
                              <li className='busuyyasbasytxtxtppli'>
                                 Business address
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                                 Business description
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                                 4 Product/Service categories
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                                 4 Keywords
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                                 4 Photos
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                                 4 Social media links
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                                 Manage your page
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                                 Monitor your performance
                              </li>
                           </ul>
                        </div>

                        <Link className='choosttappanbtn' to="/listbusiness">Choose Plan</Link>
                        <div className='Viewbtnmain'>
                           <Link to="/" className="vieiimitrrebtn">View more</Link>
                        </div>
                     </div>


                  </div>

                  <div className='chooseplandivmaondivbrdr'>
                     <div className='chooseplandivmaondivbrdrmain'>
                        <button className='basiuicvaytbtu'>Silver</button>
                        <div className=''>
                           <p className='freesttxppp'>AED 250</p>
                           <p className='auuhshtxttpp'>For life</p>
                        </div>

                        <div className='baysyysttxtpppdsidnbmai'>
                           <p className='baysyysttxtppp'>Standard listing</p>
                           <ul className='busuyyasbasytxtxtppul'>
                              <li className='busuyyasbasytxtxtppli'>
                                 Business address
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                                 Business description
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                                 4 Product/Service categories
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                                 4 Keywords
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                                 4 Photos
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                                 4 Social media links
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                                 Manage your page
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                                 Monitor your performance
                              </li>

                              <li className='busuyyasbasytxtxtppli'>
                              1 NFC Digital business card
                              </li>

                              <li className='busuyyasbasytxtxtppli'>
                              Business reward program
                              </li>

                              <li className='busuyyasbasytxtxtppli'>
                              Business page for individual
                              </li>

                              <li className='busuyyasbasytxtxtppli'>
                              Dedicated URL
                              </li>

                              <li className='busuyyasbasytxtxtppli'>
                              QR code
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                              Tap & Scan 
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                              Sent and receive unlimited contacts.
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                              Contact directory
                              </li>
                              <li className='busuyyasbasytxtxtppli'>
                              Creative support
                              </li>
                           </ul>
                        </div>

                  
                        <Link className='choosttappanbtn' to="/listbusiness">Choose Plan</Link>
                        <div className='Viewbtnmain'>
                           <Link to="/" className="vieiimitrrebtn">View more</Link>
                        </div>
                     </div>


                  </div>

                  <div className='chooseplandivmaondivbrdr'>
                     <div className='chooseplandivmaondivbrdrmain' style={{backgroundColor:"#662D91"}}>
                        <button className='basiuicvaytbtu' style={{color:"#fff" , borderColor:"#fff"}}>Gold</button>
                        <div className=''>
                           <p className='freesttxppp' style={{color:"#fff"}}>AED 500</p>
                           <p className='auuhshtxttpp' style={{color:"#fff"}}>Multiple accounts For life</p>
                        </div>

                        <div className='baysyysttxtpppdsidnbmai'>
                           <p className='baysyysttxtppp' style={{color:"#fff"}}>Premium listing</p>
                           <ul className='busuyyasbasytxtxtppul'>
                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                                 Business address
                              </li>
                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                                 Business description
                              </li>
                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                                 4 Product/Service categories
                              </li>
                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                                 4 Keywords
                              </li>
                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                                 4 Photos
                              </li>
                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                                 4 Social media links
                              </li>
                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                                 Manage your page
                              </li>
                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                                 Monitor your performance
                              </li>

                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                              1 NFC Digital business card
                              </li>

                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                              Business reward program
                              </li>

                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                              Business page for individual
                              </li>

                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                              Dedicated URL
                              </li>

                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                              QR code
                              </li>
                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                              Tap & Scan 
                              </li>
                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                              Sent and receive unlimited contacts.
                              </li>
                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                              Contact directory
                              </li>
                              <li className='busuyyasbasytxtxtppli' style={{color:"#fff"}}>
                              Creative support
                              </li>
                           </ul>
                        </div>

          
                        <Link className='choosttappanbtn' to="/listbusiness" style={{backgroundColor:"#fff"}}>Choose Plan</Link>
                        <div className='Viewbtnmain'>
                           <Link to="/" className="vieiimitrrebtn"  style={{color:"#fff"}}>View more</Link>
                        </div>
                     </div>


                  </div>
               </div>
            </div>
         </section>
      </>
   )
}

export default Chooseplan
