import React, { useEffect } from "react";
import icon from "../../Images/vender/Offer stats.png";
import { Link } from "react-router-dom";
import ico from "../../Images/vender/views-w.png";
import ico2 from "../../Images/vender/issued.png";
import ico3 from "../../Images/vender/Redeemed.png";
import {
  OfferViewDetails,
  ReddemVoucherList,
  VoucherIssuedList,
} from "../../Redux/Slice/Offer";
import { useDispatch, useSelector } from "react-redux";

function OfferstatsVender() {
  const dispatch = useDispatch();
  const { VoucherIssuesDataButNotRedeemed, VoucherIsRedeemed, OfferViewData } =
    useSelector((state) => state.offer);

  console.log(VoucherIsRedeemed, "VoucherRedeemedList");

  useEffect(() => {
    dispatch(VoucherIssuedList());
    dispatch(ReddemVoucherList());
    dispatch(OfferViewDetails());
  }, []);
  const boxcard = [
    { id: 1, src: ico, head: "Offer views", number: "100" },
    { id: 2, src: ico2, head: "Voucher issued", number: "200" },
    { id: 3, src: ico3, head: "Redeemed", number: "100" },
  ];
  return (
    <>
      <section className="OfferstatsVender_sec">
        {/* <Link to="/offer-stats-inner" className="heading">
          <img src={icon} className="img-fluid" alt=".." />
          Offer stats
        </Link> */}
        <Link to="/offer-view" className="heading">
          <img src={icon} className="img-fluid" alt=".." />
          Offer stats
        </Link>
        <ul className="list">
          <li>
            <Link to="/offer-view">
              <div className="box_wrap active">
                <div className="icon">
                  <img src={boxcard?.[0]?.src} className="img-fluid" alt=".." />
                </div>
                <div className="content">
                  <h6 className="head">{boxcard?.[0]?.head}</h6>
                  <p className="number">
                    <i class="fa-regular fa-eye"></i>
                    {OfferViewData?.length}
                  </p>
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/offer-voucher-issued">
              <div className="box_wrap active">
                <div className="icon">
                  <img src={boxcard?.[1]?.src} className="img-fluid" alt=".." />
                </div>
                <div className="content">
                  <h6 className="head">{boxcard?.[1]?.head}</h6>
                  <p className="number">
                    <i class="fa-regular fa-eye"></i>
                    {VoucherIssuesDataButNotRedeemed?.length}
                  </p>
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/offer-voucher-redeemed">
              <div className="box_wrap active">
                <div className="icon">
                  <img src={boxcard?.[2]?.src} className="img-fluid" alt=".." />
                </div>
                <div className="content">
                  <h6 className="head">{boxcard?.[2]?.head}</h6>
                  <p className="number">
                    <i class="fa-regular fa-eye"></i>
                    {VoucherIsRedeemed?.length}
                  </p>
                </div>
              </div>
            </Link>
          </li>
        </ul>
      </section>
    </>
  );
}

export default OfferstatsVender;
